import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Code from "qrcode.react";

export default function AlertDialog(props: any) {

  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">

        <DialogTitle id="alert-dialog-title">{"Scan this QR code in the Capture mobile app"}</DialogTitle>
        <DialogContent>
        <Code
              style={{ width: "500px", height: "500px" }}
              size={500}
              value={props.data}
            />
        </DialogContent>
        <DialogActions>          
          <Button onClick={props.closeModal} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}