class MapsConfigHelper {
    saveMapsCountry(countryCode: string) {
        localStorage.setItem('MapsCountry', countryCode);
    }

    getMapsCountry(): string {
        return localStorage.getItem('MapsCountry')??'';
    }
}

export default new MapsConfigHelper();