import React, { useEffect } from "react";
import { Routes } from "config/routes";
import theme from "config/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { StoreContext, initStore } from "store";
import { BrowserRouter, Switch } from "react-router-dom";
import NewUser from "containers/NewUser";
import AppLayout from "components/AppLayout";
import StatusSnackbar from "components/StatusSnackbar";
import MobileView from "containers/Mobile";
import { isMobile } from "is-mobile";
import { windowNotTablet } from "utils";
import { LoadingAnimation } from "./assets/animation";
import { useAuth, hasAuthParams } from "react-oidc-context";

// init mobx Store once
const rootStore = initStore();
const { authStore } = rootStore;

function App() {
	const auth = useAuth();
	authStore.setAuth(auth);

	// automatically sign-in
	useEffect(() => {
		if (
			!hasAuthParams() &&
			!auth.isAuthenticated &&
			!auth.activeNavigator &&
			!auth.isLoading
		) {
			console.log("signin redirect");
			auth.signinRedirect();
		}

		if (auth.isAuthenticated && auth.user) {
			console.log("user is authenticated");
			authStore.updateBearerToken(auth.user.access_token);
		}
	}, [
		auth.isAuthenticated,
		auth.activeNavigator,
		auth.isLoading,
		auth.signinRedirect,
		auth,
	]);

	if (auth.activeNavigator) {
		return (
			<ThemeProvider theme={theme}>
				<LoadingAnimation />
			</ThemeProvider>
		);
	}

	if (!auth.isAuthenticated) {
		return <div className="text-3xl upppecase">Unable to log in</div>;
	}

	if (isMobile({ tablet: true, featureDetect: true }) || windowNotTablet()) {
		return <MobileView />;
	}

	return (
		<StoreContext.Provider value={rootStore}>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Switch>
						<AppLayout>
							<NewUser>
								<Routes />
							</NewUser>
						</AppLayout>
					</Switch>
				</BrowserRouter>
				<StatusSnackbar />
			</ThemeProvider>
		</StoreContext.Provider>
	);
}

export default App;
