import { observable, action } from "mobx";
import {
	NoteClient,
	NoteDTO,
	SearchFilter,
	SearchType,
} from "services";
import { RootStore } from "store";
import BaseStore from "./baseStore";
import { reduceNotesToAttachments } from "utils";
import FileSaver from "file-saver";

export enum ExportMode {
	Standard,
	Attachment,
}

export class ExportStore extends BaseStore {
	private _noteClient: NoteClient;
	@observable selectedNotes: string[] = [];
	@observable isExporting: boolean = false;
	@observable isAwaitingExportReason: boolean = false;
	@observable exportPurpose = "";
	@observable searchExport: boolean = false;
	@observable exportMode: ExportMode = ExportMode.Standard;

	constructor(root: RootStore) {
		super(root);
		this._noteClient = new NoteClient(this.API_URL);
	}

	GetAllSelected(notes?: NoteDTO[]): boolean {
		if (!notes) {
			return false;
		}

		if (this.exportMode === ExportMode.Standard) {
			return this.selectedNotes.length === notes.length;
		} else {
			return (
				reduceNotesToAttachments(notes).length ===
				this.selectedNotes.length
			);
		}
	}

	@action ToggleExportMode() {
		this.selectedNotes = [];
		if (this.exportMode === ExportMode.Standard) {
			this.exportMode = ExportMode.Attachment;
		} else {
			this.exportMode = ExportMode.Standard;
		}
	}

	@action async ExportMedia() {
		this.isAwaitingExportReason = false;

		// TODO: ADD FUNC FOR EXPORTING FROM SEARCH
		const exportMediaFunc = (otp: string) => {
			this.loading = true;
			this.AuthorisedRequest(this._noteClient, async () => {
				const {
					searchStore,
					noteStore: { sessionDate },
				} = this._rootStore;

				const filters = this.searchExport
					? (({
							...searchStore.sanitisedSearchInput,
							attachments: this.selectedNotes,
					  } as unknown) as SearchFilter)
					: ({
							attachments: this.selectedNotes,
							searchStartDate: sessionDate?.toDate(),
							searchEndDate: sessionDate?.toDate(),
							searchType: SearchType.Standard,
					  } as SearchFilter);

				this.success = "Exporting";
				this._noteClient
					.media(otp, this.exportPurpose, filters)
					.then(async (e) => {
						if (this.loading) {
							this.success = "Export Success";
							const blob = await this._rootStore.fileStore.fetchZipfile(e);
							FileSaver.saveAs(blob as Blob, e);
							this.loading = false;
							this.exportPurpose = "";
						}
					})
					.catch((e) => {
						if (this.loading) {
							this._rootStore.submitExceptionToAppInsight(e);
							this.error = "Export Failed";
							this.loading = false;
							this.exportPurpose = "";
						}
						console.log("fail", e);
					});
				setTimeout(() => {
					if (this.loading) {
						this.loading = false;
						this.exportPurpose = "";
						this.success = "Export is in progress. You will get an email once export is completed";
					}
				}, 200000)
			});
		};
		this._rootStore.otpStore.TriggerOTP(exportMediaFunc);
	}

	@action ExportFiles(exportAll: boolean) {
		if (this.exportMode === ExportMode.Attachment) {
			this.ExportMedia();
		} else {
			this.ExportNote(exportAll);
		}
	}

	@action async ExportNote(exportAll: boolean) {
		this.isAwaitingExportReason = false;
		const ids = this.selectedNotes.map((e) => Number(e));
		const exportFunc = (otp: string) => {
			this.loading = true;
			this.AuthorisedRequest(this._noteClient, async () => {
				const {
					searchStore,
					noteStore: { sessionDate },
				} = this._rootStore;

				let filters: Partial<SearchFilter> = {
					...searchStore.sanitisedSearchInput,
					relevantIds: ids,
					now: new Date(),
					offset: 0,
				};

				if (this.searchExport) {
					// if user export all then we set pageSize to -1, backend will search for all matching notes (potentially thousands of matched notes)
					// otherwise we need to search up to the current page
					filters = {
						...filters,
						pageSize: exportAll
							? -1
							// include up to the current page Size (e.g. 40)
							: this._rootStore.searchStore.searchInput.pageSize
					};
				} else {
					// export from home page
					// make sure we don't send the search filters
					filters = {
						offset: 0,
						relevantIds: filters.relevantIds,
						now: filters.now,
						exportTypes: filters.exportTypes,
						searchStartDate: sessionDate?.toDate(),
						searchEndDate: sessionDate?.toDate(),
						pageSize: -1,
						searchType: SearchType.Standard
					};
				}

				this.success = "Exporting";
				this._noteClient
					.export(
						otp,
						this.exportPurpose,
						this.searchExport,
						filters as any
					)
					.then(async (e) => {
						if (this.loading) {
							this.success = "Export Success";
							const blob = await this._rootStore.fileStore.fetchZipfile(e);
							FileSaver.saveAs(blob as Blob, e);
							this.loading = false;
							this.exportPurpose = "";
						}
					})
					.catch((e) => {
						if (this.loading) {
							this._rootStore.submitExceptionToAppInsight(e);
							this.loading = false;
							this.exportPurpose = "";
							this.error = "Export Failed";
						}
						console.log("fail", e);
					});
				setTimeout(() => {
					if (this.loading) {
						this.loading = false;
						this.exportPurpose = "";
						this.success = "Export is in progress. You will get an email once export is completed";
					}
				}, 200000)
			});
		};
		this._rootStore.otpStore.TriggerOTP(exportFunc);
	}

	@action ToggleExportModal(search: boolean) {
		this.searchExport = search;
		this.isAwaitingExportReason = !this.isAwaitingExportReason;
	}

	@action UpdateExportPurpose(purpose: string) {
		this.exportPurpose = purpose;
	}

	@action ToggleIsExporting() {
		this.isExporting = !this.isExporting;
		this.selectedNotes = [];
	}

	@action CancelExport() {
		this.isExporting = false;
		this.selectedNotes = [];
	}

	@action SelectAll(notes: NoteDTO[] | undefined) {
		if (this.exportMode === ExportMode.Standard) {
			if (notes) {
				if (this.selectedNotes.length === 0) {
					const ids: string[] = [];
					notes.forEach((n) => {
						if (n.id) {
							ids.push(`${n.id}`);
						}
					});
					this.selectedNotes = ids;
				} else {
					this.selectedNotes = [];
				}
			}
		} else {
			const attachments = reduceNotesToAttachments(notes);
			if (this.selectedNotes.length === 0) {
				const ids: string[] = [];
				attachments.forEach((n) => {
					ids.push(`${n.attachmentGuid}`);
				});
				this.selectedNotes = ids;
			} else {
				this.selectedNotes = [];
			}
		}
	}

	@action ToggleNoteSelection(id: string) {
		if (id) {
			if (this.selectedNotes.includes(id)) {
				this.selectedNotes = this.selectedNotes.filter((n) => n !== id);
			} else {
				this.selectedNotes = [...this.selectedNotes, id];
			}
		}
	}
}

export default ExportStore;
