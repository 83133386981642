import { IMapsService } from "./IMapsService";
import { ConfigurationClient } from "../Verinote-api";
import { API_URL } from "config/API_URL";
import MapsConfigHelper from "services/maps-service/maps-config-helper";

export class AzureMapsService implements IMapsService {
	AZURE_MAPS_KEY = "";

	COUNTRY_CODE = "";

	configClient = new ConfigurationClient(API_URL);

	mapsHelper = MapsConfigHelper;

	initialise(key: string, countryCode: string) {
		this.AZURE_MAPS_KEY = key;
		this.COUNTRY_CODE = countryCode;
	}

	getState = (subdivision: string) => {
		switch (subdivision) {
			case "New South Wales":
				return "NSW";
			case "Queensland":
				return "QLD";
			case "Victoria":
				return "VIC";
			case "Northern Territory":
				return "NT";
			case "Western Australia":
				return "WA";
			case "Australian Capital Territory":
				return "ACT";
			case "Tasmania":
				return "TAS";
			case "South Australia":
				return "SA";
			default:
				return subdivision ?? "";
		}
	};

	getAddressSummary = (a: any, type: any, poi: any) => {
		const {
			streetNumber,
			streetName,
			municipality,
			municipalitySubdivision,
			countrySubdivision,
			postalCode,
		} = a;

		const state = this.getState(countrySubdivision);
		const numberNameSegment = `${streetNumber ?? ""} ${
			streetName ? `${streetName},` : ""
		}`;
		const municipalitySegment = !municipalitySubdivision
			? `${municipality}`
			: `${municipalitySubdivision},`;

		return type === "POI"
			? `${poi.name}, ${numberNameSegment} ${municipalitySegment} ${state} ${postalCode}`
			: `${numberNameSegment} ${municipalitySegment} ${state}, ${postalCode}`;
	};

	SearchAddress = async (term: string, lat?: number, lon?: number) => {
		//https://atlas.microsoft.com/search/fuzzy/json?api-version=1.0&subscription-key=itsasecret&query=Sydney Airport&countrySet=AU&limit=3
		if (term && term.length > 3) {
			try {
				const url = `https://atlas.microsoft.com/search/fuzzy/json?api-version=1.0&subscription-key=${
					this.AZURE_MAPS_KEY
				}&query=${term}&countrySet=${this.COUNTRY_CODE}${
					lat && lon ? `&lat=${lat}&lon=${lon}` : ""
				}&typeahead=true&limit=10`;
				const response = await fetch(url);
				const json = await response.json();
				return json.results.map((r: any) => ({
					name: this.getAddressSummary(r.address, r.type, r.poi),
					position: r.position,
				}));
			} catch (e) {
				return [""];
			}
		}
		return [];
	};

	ReverseAddressSearch = async (
		lat: number,
		lon: number
	): Promise<string> => {
		const url = `https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=${this.AZURE_MAPS_KEY}&query=${lat},${lon}`;
		try {
			const response = await fetch(url);
			const json = await response.json();
			return json.addresses.map((r: any) =>
				this.getAddressSummary(r.address, null, null)
			)[0];
		} catch (e) {
			return "";
		}
	};
}
