import React from "react";
import { Form } from "formik";
import { Box, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { renderComponentXTimes } from 'utils';

export const EditNoteLod = () => {
  // TODO: remove magic strings
  return (
    <>
      <Form style={{ padding: "48px" }}>
        <div className="form-editnote">
          <Box>
            <Box display="flex" justifyContent="space-between" paddingTop="4px">
              <Box display="flex" minWidth={"100%"}>
                <Typography variant="h5"></Typography>
              </Box>
            </Box>
            <Box paddingTop={"16px"}>
              {renderComponentXTimes(Skeleton, 25, { width: '100%', animation:'wave' })}
            </Box>
          </Box>
        </div>
      </Form>
    </>
  );
};
