import { action, computed, observable } from "mobx";
import { DecodedJWT } from "models";
import decode from "jwt-decode";
import BaseStore from "./baseStore";
import { RootStore } from "store";
import {eraseCookie} from "../utils";

export class AuthStore extends BaseStore {
	@observable bearerToken: string | null = null;
	@observable authenticating: boolean = true;
	@observable authenticationFailed: boolean = false;
	@observable signingOut: boolean = false;
	@observable auth: any;

	private readonly LOGOUT_TIMEOUT = 800;

	constructor(root: RootStore) {
		super(root);
	}

	@action async setAuth(auth: any) {
		this.auth = auth;
	}

	@action logOut = () => {
		this.signingOut = true;
		this.success = "Signing You Out";
		window.setTimeout(() => {
			this.auth.signoutRedirect();
			eraseCookie("otp");
		}, this.LOGOUT_TIMEOUT); // Timeout for
	};

	@action authenticate = async () => {
		this.auth.signinRedirect();
	};

	@computed get decodedToken(): DecodedJWT {
		if (this.bearerToken) {
			return decode(this.bearerToken);
		}
		return {} as DecodedJWT;
	}

	@action updateBearerToken = async (token: string) => {
		this.bearerToken = token;
		this.authenticating = false;
		this.loading = false;
		await this._rootStore.userStore.getCurrentUser();
	};
}

export default AuthStore;
