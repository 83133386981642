import React, { useEffect, useRef } from "react";
import { NoteDTO } from "services";
import { NoteCard } from "./NoteCard";
import { Box, Checkbox } from "@material-ui/core";
import { VerinoteBlueDark } from "config/theme";
import makeStyles from "@material-ui/styles/makeStyles";
import { APPBAR_HEIGHT, NOTE_TAB, TOOLBAR_HEIGHT } from "utils";
import InfiniteScroll from "react-infinite-scroller";
import classNames from "classnames";
import { useStore } from "store";

const useStyles = makeStyles({
	notesList: {
		"&::-webkit-scrollbar": {
			width: "0.4em",
			transition: "all 0.2 ease",
		},
		"&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
			webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: VerinoteBlueDark,
			outline: `1px solid white`,
		},
	},
	noScroll: {
		overflowY: "hidden",
	},
	scroll: {
		overflowY: "auto",
	},
});

interface NoteListProps {
	notes?: NoteDTO[];
	onActivateNote: (index: number) => void;
	active: NoteDTO | null;
	canSelect: boolean;
	hasMore: boolean;
	scrollable: boolean;
	selected: string[];
	onSelectNote: (id: number) => void;
	onLoadMoreNote: () => void;
	canActivate: boolean;
}

export const NoteList = (props: NoteListProps): JSX.Element => {
	const styles = useStyles();
	const { notes, canSelect, active, selected, canActivate, hasMore } = props;
	const {
		searchStore: {
			searchInput: { offset },
		},
	} = useStore();
	const list = useRef(null);

	useEffect(() => {
		if (offset === 0 && list.current) {
			(list.current as any).scrollTo(0, 0);
		}
	}, [offset]);
	
	const NoteRow = ({ index }: { index: number }) => {
		if (!notes) {
			return <></>;
		}
		const n = notes[index];
		if (!n) {
			return <></>;
		}
		const isActive = active ? active.id === n.id : false;
		const isSelected = selected.includes(`${n.id}` || `${0}`);
		let note = isActive && active ? active : n;

		if (n.id === -1) {
			return (
				<Box key={n.id} display="flex" justifyContent="flex-start">
					<Box
						className="slide-in-right-anim"
						key={n.id}
						flexGrow={1}
					>
						{/* <Skeleton variant="rect" width={210} height={118} />; */}
					</Box>
				</Box>
			);
		}
		return (
			<Box key={n.id} display="flex" justifyContent="flex-start">
				{canSelect && (
					<Checkbox
						className="scale-in-center-anim"
						color="primary"
						checked={isSelected}
						onClick={() => {
							if (n.id) {
								props.onSelectNote(n.id);
							}
						}}
					/>
				)}
				<Box
					// className={canSelect ? "slide-in-left-anim" : "slide-in-right-anim"}
					key={n.id}
					flexGrow={1}
				>
					<NoteCard
						disabled={!canActivate}
						note={note ?? n}
						selected={isActive}
						onSelectNote={() =>
							canActivate && props.onActivateNote(index)
						}
					/>
				</Box>
			</Box>
		);
	};

	return (
		<div
			ref={list}
			className={classNames({
				[styles.notesList]: true,
				[styles.scroll]: props.scrollable,
				[styles.noScroll]: !props.scrollable,
			})}
			style={{
				maxHeight: `calc(100vh - ${
					APPBAR_HEIGHT + TOOLBAR_HEIGHT + NOTE_TAB
				}px`,
			}}
		>
			<InfiniteScroll
				loadMore={props.onLoadMoreNote}
				hasMore={hasMore}
				useWindow={false}
				threshold={800}
				loader={
					<div className="loader" key={0}>
						Loading ...
					</div>
				}
			>
				{notes?.map((x: NoteDTO, index: number) => (
					<NoteRow index={index} key={x.id} />
				))}
			</InfiniteScroll>
		</div>
	);
};
