import React from 'react';
import { ErrorMessage } from 'formik';
import {
	TextField,
	Typography,
	StandardTextFieldProps,
	FilledTextFieldProps,
	OutlinedTextFieldProps,
} from '@material-ui/core';
import { FieldTitle } from 'components';

export interface InputProps<T> {
	id: string;
	value?: T;
	formikEvents: Record<string, any>;
	type: 'text' | 'email' | 'number' | 'password';
	placeHolder?: string;
	rows?: number;
	default?: string;
	title?: string;
	disabled?: boolean;
	width?: string;
	error?: boolean;
	inputProps?: any;
	inputRef?: any;
}

interface TextInputProps extends InputProps<string> {
	variant:
		| StandardTextFieldProps['variant']
		| FilledTextFieldProps['variant']
		| OutlinedTextFieldProps['variant'];
}
export const MuiTextInput = (props: TextInputProps): JSX.Element => {
	const {
		id,
		default: defaultValue,
		value,
		formikEvents: { handleChange, handleBlur },
		placeHolder,
		title,
		variant,
		disabled,
		inputProps,
		inputRef,
		...formikProps
	} = props;

	const rows = props.rows ? props.rows : 1;

	const Error = () => {
		return (
			<ErrorMessage
				name={id}
				render={(error) => (
					<Typography variant="subtitle2">{error}</Typography>
				)}
			/>
		);
	};

	return (
		<>
			{
				<FieldTitle
					color={disabled ? 'textPrimary' : 'primary'}
					title={title}
				/>
			}
			<TextField
				inputRef={inputRef}
				name={id}
				id={id}
				placeholder={placeHolder}
				defaultValue={defaultValue !== '' ? defaultValue : value}
				multiline={rows > 1}
				rows={props.rows}
				error={props.error}
				fullWidth
				InputProps={inputProps}
				onChange={(e) => handleChange(e)}
				onBlur={(e) => handleBlur(e)}
				variant={variant ?? ('outlined' as any)}
				disabled={disabled === null ? false : disabled}
				{...formikProps}
			/>
			<Error />
		</>
	);
};
