import { AccessControlGroup } from "services";

export const APPBAR_HEIGHT = 66;
export const TOOLBAR_HEIGHT = 74;
export const NOTE_TAB = 48;
export const MEDIA_TYPE_PREVIEW = ["video", "image", "audio"]
export const RBAC_GLOBAL_SEARCH_NAMING: Record<AccessControlGroup, string> = {
	[AccessControlGroup.Baseline]: "Global Search",
	[AccessControlGroup.Admin]: "",
	[AccessControlGroup.CEO_Delegate]: "Agency Discovery",
	[AccessControlGroup.Integrity]: "Integrity Search",
	[AccessControlGroup.Intelligence]: "Intelligence Search",
	[AccessControlGroup.OrganisationLawyer]: "Agency Discovery",
	[AccessControlGroup.Supervisor]: "Work Unit Search",
	[AccessControlGroup.Integration]: "Integration Service",
	[AccessControlGroup.Restricted]: "",
};

export const twColors = [
	"#EF4444",
	"#F59E0B",
	"#10B981",
	"#3B82F6",
	"#EC4899",
	"#84CC16",
	"#B91C1C",
	"#B45309",
	"#047857",
	"#1D4ED8",
	"#BE185D",
	"#65A30D"
];
