import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

interface SwitchValueProps {
	onChange: (e: any) => any;
	editing: boolean;
	canEdit: boolean;
	label: string;
	id: string;
	value?: boolean;
	yesIcon: JSX.Element;
	noIcon: JSX.Element;
}

export const MuiSwitch = ({
	editing,
	label,
	id,
	value,
	noIcon,
	canEdit,
	yesIcon,
	onChange,
}: SwitchValueProps) => {
	return (
		<FormControlLabel
			name={id}
			control={
				editing ? (
					<Switch
						icon={noIcon}
						checkedIcon={yesIcon}
						color="primary"
						name={id}
						disabled={!editing || !canEdit}
						onChange={onChange}
						checked={value}
					/>
				) : (
					<div style={{ marginLeft: '16px' }}>
						{value === true ? yesIcon : noIcon}
					</div>
				)
			}
			label={label}
		/>
	);
};
