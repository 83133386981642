import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
	ConfigurationClient,
	MobileConfigViewModel,
} from "./services/Verinote-api";
import { API_URL } from "./config/API_URL";
import MsalConfigHelper from "./services/msal-config-helper";
import { isMobile } from "is-mobile";
import { windowNotTablet } from "utils";
import MobileView from "containers/Mobile";
import mapsConfigHelper from "services/maps-service/maps-config-helper";
import { AuthProvider } from "react-oidc-context";

const configClient = new ConfigurationClient(API_URL);
configClient
	.get()
	.then((config: MobileConfigViewModel) => {
		const oidcConfig = {
			authority: config.authority,
			client_id: config.appId,
			redirect_uri: window.location.origin + "/",
			response_type: "code",
			automaticSilentRenew: true,
			scope: "openid profile email offline_access",
		};

		if (window.location.search.includes("?downloadFile=")) {
			localStorage.setItem("DownloadFile", window.location.search.substring(14))
		}

		// todo: find better way
		localStorage.setItem("TelemetryKey", config.appInsightKey || "");
		localStorage.setItem("Version", config.version || "");

		if (
			isMobile({ tablet: true, featureDetect: true }) ||
			windowNotTablet()
		) {
			ReactDOM.render(<MobileView />, document.getElementById("root"));
		} else {
			mapsConfigHelper.saveMapsCountry(config.mapsCountryCode ?? "");
			MsalConfigHelper.saveMsalConfig(
				MsalConfigHelper.parseMobileConfigViewModel(config)
			);

			ReactDOM.render(
				<AuthProvider {...oidcConfig}>
					<App />
				</AuthProvider>,
				document.getElementById("root")
			);
		}
	})
	.catch((err) => {
		console.log("Error getting config", err);
	});
