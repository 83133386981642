import { ipcRenderer } from 'electron';
class ElectronService {
  ipcRenderer?: typeof ipcRenderer;

  isElectron(): boolean {
    return !!(window && window.process && window.process.type);
  }

  constructor() {
    // Conditional imports
    if (this.isElectron()) {
      this.ipcRenderer = window.require('electron').ipcRenderer;
    }
  }

  switchTenant(): void {
    if (this.isElectron() && this.ipcRenderer) {
      const ipc = this.ipcRenderer;
      ipc.send('fromApp_switchTenant');
    }
  }
}

const electronService = new ElectronService();

export default electronService;
