import React, { useState, useEffect, useCallback } from "react";
import { RecordingAnimation } from "assets/animation";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton, makeStyles } from "@material-ui/core";
import StopIcon from "@material-ui/icons/Stop";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DoneIcon from "@material-ui/icons/Done";
interface VoiceRecorderProps {
	save: (blob: Blob) => void;
	discard: () => void;
}

const useStyles = makeStyles({
	saveButtons: {
		marginLeft: "1rem",
		marginRight: "1rem",
	},
});

export const VoiceRecorder = (props: VoiceRecorderProps) => {
	// polyfill required for recording on safari and for recording in .wav on chrome
	//this polyfill creates WAV files which are support on chrome and safari
	// NOTE: chrome's native recording is much better quality
	window.MediaRecorder = require("audio-recorder-polyfill");

	const [recorder, setRecorder] = useState<MediaRecorder>();
	const [recording, setRecording] = useState<boolean>(false);
	const [blobUrl, setBlobUrl] = useState<string>();
	const [blob, setBlob] = useState<Blob>();
	const [permissions, setPermissionsAllowed] = useState<boolean>(false);
	const classes = useStyles();

	const init = () => {
		navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
			setRecorder(new MediaRecorder(stream));
		});
	};

	useEffect(() => {
		(async () => {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					audio: true,
				});
				if (stream) {
					setPermissionsAllowed(true);
					init();
				}
			} catch (err) {
				setPermissionsAllowed(false);
			}
		})();
	}, []);

	useEffect(() => {
		if (blob)
			// just save directly
			props.save(blob);
	}, [blob]);

	const start = useCallback(() => {
		if (permissions && !recording && recorder) {
			recorder.start();
			setRecording(true);

			recorder.addEventListener("dataavailable", (event: any) => {
				const audioBlob = new Blob([event.data], {
					type: event.data.type,
				});
				const audioUrl = URL.createObjectURL(audioBlob);
				setBlobUrl(audioUrl);
				setBlob(audioBlob);
			});

			recorder.addEventListener("stop", () => {
				setRecording(false);
			});
		}
		if (!recorder) {
			init();
		}
	}, [permissions, recording, recorder]);

	useEffect(() => {
		if (recorder && !recording && permissions && !blobUrl) {
			start();
		}
	}, [recording, blobUrl, recorder, permissions, start]);

	const stop = () => {
		if (recorder && recording) {
			recorder.stop();
			// https://stackoverflow.com/questions/44274410/mediarecorder-stop-doesnt-clear-the-recording-icon-in-the-tab
			setTimeout(async () => {
				const stream = await navigator.mediaDevices.getUserMedia({
					audio: true,
				});
				stream.getTracks().forEach((track) => track.stop());
			}, 1000);
		}
	};

	const getListener = (url: string) => (
		<div>
			<audio
				onContextMenu={(e) => e.preventDefault()}
				key={url}
				controls
				controlsList="nodownload"
			>
				<source src={url} type="audio/wav" />
			</audio>
		</div>
	);

	return (
		<>
			<div
				style={{
					minWidth: "300px",
					height: "100px",
					display: "flex",
					justifyContent: "center",
					marginTop: "50px",
				}}
			>
				{recording && <RecordingAnimation />}
				{blobUrl && !recording && getListener(blobUrl)}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginBottom: "50px",
				}}
			>
				{!recording && (
					<IconButton
						type="button"
						className={classes.saveButtons}
						onClick={start}
						disabled={recording}
						size="small"
					>
						<FiberManualRecordIcon
							color={recording ? "secondary" : "error"}
						/>
						Rerecord
					</IconButton>
				)}

				{recording && (
					<IconButton
						type="button"
						onClick={stop}
						size="small"
						className={classes.saveButtons}
					>
						<StopIcon color="primary" />
						Stop Recording
					</IconButton>
				)}

				{!recording && blob && (
					<>
						<IconButton
							type="button"
							size="small"
							className={classes.saveButtons}
							onClick={() => props.save(blob)}
						>
							<DoneIcon
								color={recording ? "secondary" : "primary"}
							/>
							Save
						</IconButton>
						<IconButton
							type="button"
							size="small"
							className={classes.saveButtons}
							onClick={props.discard}
						>
							<DeleteIcon color="secondary" />
							Discard
						</IconButton>
					</>
				)}
			</div>
		</>
	);
};
