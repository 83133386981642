import { Container, Typography } from "@material-ui/core";
import { WelcomeLogo } from "assets/logos";
import { NoteView } from "components";
import { homeStyles } from "config/styles";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "store";
import { EditNoteInput } from "store/noteStore";

const Search = () => {
	const { noteStore, searchStore, exportStore } = useStore();
	const { selectedNotes, isExporting } = exportStore;
	const { activeNote, newNote } = noteStore;

	const handleActivateNote = (index: number) => {
		if (searchStore.activeIndex !== index) {
			searchStore.SetActiveIndex(index);
		}
	};

	const handleSelectNote = (id: number | undefined) => {
		if (id) {
			exportStore.ToggleNoteSelection(`${id}`);
		}
	};

	const searchMore = () => {
		// when getting next page, do not ask for Global Search reason anymore
		searchStore.SearchNotes(true);
	};
	const classes = homeStyles();

	const handleCreateNote = async (): Promise<EditNoteInput> => {
		return await noteStore.CreateNote();
	};

	const Onboarding = () => {
		return (
			<Container className={classes.welcome}>
				<Typography variant="h5" align="center">
					SEARCH
				</Typography>
				<Typography variant="h1" align="center">
					<WelcomeLogo />
				</Typography>
				<Typography variant="body1" align="center">
					Type Something into the search field
				</Typography>
				<Typography variant="body1" align="center">
					Or select a date range and/or classification
				</Typography>
			</Container>
		);
	};

	return (
		<NoteView
			onCreateNote={handleCreateNote}
			sessionDate={noteStore.sessionDate}
			selected={selectedNotes}
			hasMore={searchStore.hasMore}
			loadMore={searchMore}
			activeNote={newNote ? noteStore.activeNote : searchStore.activeNote}
			notes={searchStore.results}
			onSelectNote={handleSelectNote}
			onActivateNote={handleActivateNote}
			onSaveNote={(v) => noteStore.SaveNote(v)}
			noteFormData={noteStore.PopulateNoteForm(searchStore.activeNote)}
			newNote={newNote}
			getAutoCompleteOptions={(v) => noteStore.GetAddress(v)}
			canSelect={isExporting}
			onboarding={<Onboarding />}
		/>
	);
};

export default observer(Search);
