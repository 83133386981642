/* eslint-disable no-console */
//import { observable, computed, action, runInAction } from "mobx";
import { observable, action, computed } from "mobx";
import {
	LogClient,
	NoteAuditDTO,
	ExportAuditDTO,
	SearchAuditDTO,
	UserClient,
	UserDTO,
	UpdateUserCommand,
	IUpdateUserCommand,
	AccessControlGroup,
} from "services";
import { RootStore } from "store";
import BaseStore from "./baseStore";
import { GetDateInTimezone } from "utils";

export class AdminStore extends BaseStore {
	private _logClient: LogClient;
	private _userClient: UserClient;
	@observable noteAudits: NoteAuditDTO[] | null = null;
	@observable exportAudits: ExportAuditDTO[] | null = null;
	@observable searchAudits: SearchAuditDTO[] | null = null;
	@observable users: UserDTO[] = [];
	@observable workunits: string[] = [];

	constructor(root: RootStore) {
		super(root);
		this._logClient = new LogClient(this.API_URL);
		this._userClient = new UserClient(this.API_URL);
	}

	@computed get AccessActionRequiredCount(): number {
		if (this.users) {
			return this.users.filter(
				(u) => u.requestedAccessControlGroup !== u.accessControlGroup
			).length;
		}
		return 0;
	}

	@action async GetUsers() {
		this.loading = true;
		this.AuthorisedRequest(this._userClient, async () => {
			try {
				const response = await this._userClient.getUsers();
				if (response.users) {
					this.loading = false;

					this.users = response.users;
				}
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.loading = false;

				this.error = "Could not fetch Users";
			}
		});
	}

	@action async GetWorkUnits() {
		this.loading = true;
		this.AuthorisedRequest(this._userClient, async () => {
			try {
				const response = await this._userClient.getWorkUnits();
				if (response) {
					this.loading = false;
					this.workunits = response;
				}
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.loading = false;
				this.error = "Could not fetch Work Units";
			}
		});
}

	@action async UpdateUser(user: UserDTO) {
		const update = (otp: string) => {
			this.AuthorisedRequest(this._userClient, async () => {
				try {
					const command = new UpdateUserCommand({
						...user,
						otp: otp,
					} as IUpdateUserCommand);
					this.loading = true;
					const response = await this._userClient.update(command);
					if (response) {
						this.loading = false;
						this.success = "User Updated";
						if (
							this._rootStore.userStore.currentUser
								?.accessControlGroup ===
							AccessControlGroup.Admin
						) {
							this.GetUsers();
						}

						await this._rootStore.userStore.getCurrentUser();
					}
				} catch (e) {
					this._rootStore.submitExceptionToAppInsight(e);
					this.loading = false;

					if (e.status === 400) {
						var response = JSON.parse(e.response);
						var errors = response["errors"];

						if (errors) {
							var error = `${response.title} `;
							for (const field in errors) {
								for (const message of errors[field]) {
									error += ` ${field}: ${message} `;
								}
							}

							this.error = error;
							return;
						}
					}

					this.error =
						"Could not update profile. An unexpected error occurred.";
				}
			});
		};

		// change mobile number require OTP
		if (user.phone !== this._rootStore.userStore.currentUser?.phone) {
			this._rootStore.otpStore.TriggerOTPEmail(update);
		} else {
			update("");
		}
	}

	@action async GetNoteAudit() {
		this.AuthorisedRequest(this._logClient, async () => {
			try {
				const noteAudits = await this._logClient.getNoteAudits();
				if (noteAudits && noteAudits.noteAudits) {
					this.noteAudits = noteAudits.noteAudits;
				}
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.error = "Could not get note logs";
			}
		});
	}

	@action async GetExportAudit() {
		this.AuthorisedRequest(this._logClient, async () => {
			try {
				const exportAudits = await this._logClient.getExportAudits();
				if (exportAudits && exportAudits.exportAudits) {
					const tz = this._rootStore.userStore.currentUser
						?.interfaceTimeZone;

					const audits: ExportAuditDTO[] = exportAudits.exportAudits;
					this.exportAudits = audits.map((a: ExportAuditDTO) => {
						if (a.timestamp) {
							const newDate = GetDateInTimezone(a.timestamp, tz);
							a.timestamp = newDate.toDate();
						}
						return a;
					});
				}
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.error = "Could not get export logs";
			}
		});
	}
	@action async GetSearchAudit() {
		this.AuthorisedRequest(this._logClient, async () => {
			try {
				const searchAudits = await this._logClient.getSearchAudits();
				if (searchAudits && searchAudits.searchAudits) {
					this.searchAudits = searchAudits.searchAudits;
				}
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.error = "Could not get search logs";
			}
		});
	}
}

export default AdminStore;
