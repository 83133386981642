import React, { useState } from "react";
import HistoryIcon from "@material-ui/icons/History";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputProps } from "./MuiTextInput";
import { useDebounce } from "utils";
import {
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@material-ui/core";
import { FieldTitle } from "components";

export interface Option {
	name?: string;
	position?: { lat?: number; lon?: number };
}

interface AutoCompleteProps extends InputProps<Option> {
	intialValues?: Option[];
	getOptions: (value: string) => Promise<Option[]>;
}

export const FormikAutoComplete = (props: AutoCompleteProps) => {
	const [open, setOpen] = useState(false);
	const [typed, setTyped] = useState<string>();
	const { id, value, title, formikEvents, disabled } = props;

	const options = useDebounce<Option[]>(props.getOptions, typed, 400);

	const showOptions = options?.length ? options : props.intialValues;
	const lastAddress = props.intialValues?.length
		? props.intialValues[0]
		: null;

	return (
		<>
			<Typography
				variant="body1"
				align="left"
				color={disabled ? "textSecondary" : "primary"}
			>
				{title}
				{lastAddress && (
					<a
						style={{
							cursor: "pointer",
							marginLeft: "10px",
							textDecoration: "underline",
						}}
						onClick={() =>
							formikEvents.handleChange({
								target: {
									value: lastAddress,
									id,
									name: id,
								},
							})
						}
					>
						(Use Last)
					</a>
				)}
			</Typography>
			<Autocomplete
				value={value}
				placeholder="No Location"
				filterSelectedOptions={true}
				disabled={disabled}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={(e) => {
					setOpen(false);
				}}
				autoComplete
				getOptionLabel={(option: Option) =>
					option && option.name ? option.name : ""
				}
				onChange={(e, v) =>
					formikEvents.handleChange({
						...e,
						target: { value: v ? v : {}, id, name: id },
					})
				}
				onBlur={(e) => formikEvents.handleBlur(e)}
				autoHighlight
				options={showOptions}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						onChange={(e) => {
							formikEvents.handleChange({
								target: {
									value: { name: e.target.value },
									id,
									name: id,
								},
							});
							setTyped(e.target.value);
						}}
						variant="standard"
						error={props.error}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
		</>
	);
};
