import React, {useEffect, useState} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { Button, CircularProgress } from '@material-ui/core';
import FileSaver from "file-saver";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			width: 400,
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			top: '50%',
			left: '50%',
			position: 'absolute',
			transform: `translate(-50%, -50%)`,
		},
		headerText: {
			marginBottom: 0,
			fontSize: '1rem',
		},
		fileNameText: {
			textAlign: 'center',
			fontSize: '1rem',
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		downloadButton: {
			display: 'flex',
			gap: '10px',
			marginTop: 30
		}
	})
);

const DownloadFileModal = () => {
	const classes = useStyles();
	const {fileStore} = useStore();
	const [filename, setFilename] = useState<string | undefined>();
	const [isOpen, setOpen] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		const downloadFile = localStorage.getItem("DownloadFile");
		if (downloadFile) {
			localStorage.removeItem("DownloadFile");
			setFilename(downloadFile);
			setOpen(true)
		}
	}, [])

	const onDownload = async () => {
		if (!isLoading) {
			try {
				setLoading(true);
				const blob = await fileStore.fetchZipfile(filename);
				FileSaver.saveAs(blob as Blob, filename);
			} catch (e) {
				console.error(e);
			} finally {
				setOpen(false);
				setLoading(false);
			}
		}
	}
	
	const onCancel = () => {
		setOpen(false)
	}

	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={isOpen}
		>
			<div className={classes.paper}>
				<div className={classes.wrapper}>
					<p className={classes.headerText}>
						Are you sure you want to download
					</p>
					<p className={classes.fileNameText}>
						{filename}?
					</p>
					
					<div className={classes.downloadButton}>
						<Button
							color="primary"
							variant="contained"
							size="large"
							startIcon={isLoading ? <CircularProgress color="secondary" size={20}/> : undefined}
							onClick={onDownload}
						>
							Download
						</Button>
						<Button
							color="secondary"
							variant="contained"
							size="large"
							onClick={onCancel}
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(DownloadFileModal);
