import React, { useState, useEffect, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { Button, TextField, Box, Typography } from "@material-ui/core";
import { useStyles } from "./";

const OtpModal = () => {
	const classes = useStyles();
	const { otpStore } = useStore();
	const { sentViaEmail } = otpStore;
	const [otp, setOtp] = useState("");
	const ref = useRef<HTMLInputElement>();

	useEffect(() => {
		window.setTimeout(() => {
			const node = ref.current;
			node?.focus();
		}, 200);
	}, []);

	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={true}
			onClose={() => otpStore.cancelOtp()}
		>
			<div className={classes.paper}>
				<Box flexDirection="row">
					<Box>
						<Typography>
							You have attempted to perform a restricted action.
						</Typography>
					</Box>
					<Box>
						{sentViaEmail ? (
							<Typography>
								We've sent an OTP to your email. Please verify
								it below
							</Typography>
						) : (
							<Typography>
								We've sent an OTP to your mobile. Please verify
								it below
							</Typography>
						)}
					</Box>
					<Box>
						<TextField
							fullWidth
							inputRef={ref}
							onKeyPress={(ev) => {
								if (ev.key === "Enter") {
									ev.preventDefault();
									otpStore.SendOtp(otp);
								}
							}}
							onChange={(e) => setOtp(e.target.value)}
						/>
					</Box>
					<Box>
						<Button onClick={() => otpStore.cancelOtp()}>
							Cancel
						</Button>
						<Button
							color="primary"
							onClick={() => otpStore.SendOtp(otp)}
						>
							Verify
						</Button>
					</Box>
				</Box>
			</div>
		</Modal>
	);
};

export default observer(OtpModal);
