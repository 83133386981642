import React, { useEffect, useRef } from 'react';
import Modal from '@material-ui/core/Modal';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { Box, Button, TextField, Typography, } from '@material-ui/core';
import { useStyles } from './index';

const GlobalSearchModal = () => {
	const classes = useStyles();
	const { searchStore } = useStore();
	const { isAwaitingGlobalSearchReason } = searchStore;
	const ref = useRef<HTMLInputElement>();

	const focusField = () => {
		const node = ref.current;
		node?.focus();
	};

	useEffect(() => {
		window.setTimeout(() => {
			focusField();
		}, 200);
	}, [isAwaitingGlobalSearchReason]);

	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={isAwaitingGlobalSearchReason}
			onClose={() => searchStore.CloseGlobalSearchModal()}
		>
			<div className={classes.paper}>
				<Box flexDirection="column">
					<Box>
						<Typography>
							Why are you conducting this search?
						</Typography>
					</Box>
					<Box>
						<Typography variant='caption'>
							This justification will be recorded and auditable
						</Typography>
					</Box>
					<Box>
						<TextField
							inputRef={ref}
							fullWidth
							onKeyPress={(ev) => {
								if (ev.key === 'Enter') {
									ev.preventDefault();
									searchStore.SaveGlobalSearchPurpose();
								}
							}}
							onChange={(e) => searchStore.UpdateGlobalSearchPurpose(e.target.value) }
						/>
					</Box>
					<Box>
						<Button
							onClick={() => searchStore.CloseGlobalSearchModal()}
						>
							Cancel
						</Button>
						<Button
							disabled={searchStore.globalSearchPurpose.length < 1}
							color="primary"
							onClick={() => searchStore.SaveGlobalSearchPurpose()}
						>
							Proceed
						</Button>
					</Box>
				</Box>
			</div>
		</Modal>
	);
};

export default observer(GlobalSearchModal);
