import React, { useState } from "react";
import { FieldArray } from "formik";
import {
	Chip,
	TextField,
	IconButton,
	InputAdornment,
	Box,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { FieldTitle } from "components";
import { KeyEventIsEnter } from "utils";

interface KeywordFieldArrayProps {
	name: string;
	values?: string[];
	editing?: boolean;
	canEdit: boolean;
	title: string;
	placeholder?: string;
}
export const KeywordFieldArray = ({
	values,
	name,
	title,
	editing,
	placeholder,
	canEdit,
}: KeywordFieldArrayProps) => {
	const [newKeyword, setNewKeyword] = useState<string>("");
	return (
		<>
			<Box display="flex" width={1}>
				<FieldTitle color="primary" title={title} />
			</Box>
			<FieldArray
				name={name}
				render={(arrayHelpers) => {
					const addKeyword = () => {
						setNewKeyword("");
						const lc = values?.map((e) => e.toLowerCase());
						if (
							newKeyword &&
							!lc?.includes(newKeyword.toLowerCase())
						) {
							arrayHelpers.push(newKeyword);
						}
					};
					return (
						<>
							<Box display="flex" width={1}>
								{editing && canEdit && (
									<TextField
										fullWidth
										onKeyDown={(e) => {
											if (KeyEventIsEnter(e)) {
												addKeyword();
											}
										}}
										placeholder={placeholder || "Add Tag"}
										value={newKeyword}
										onChange={(e) =>
											setNewKeyword(e.target.value)
										}
										InputProps={{
											endAdornment: (
												<>
													{newKeyword &&
														newKeyword?.length >
															0 && (
															<InputAdornment position="end">
																<IconButton
																	onClick={
																		addKeyword
																	}
																	size="small"
																>
																	<AddCircleIcon />
																</IconButton>
															</InputAdornment>
														)}
												</>
											),
										}}
									/>
								)}
							</Box>
							<Box
								display="flex"
								flexWrap="wrap"
								paddingTop="8px"
							>
								{values &&
									values.length > 0 &&
									values.map((keyword, index) => (
										<React.Fragment key={`${keyword}-chip`}>
											{editing && canEdit ? (
												<Chip
													clickable={false}
													label={values[index]}
													onDelete={() =>
														arrayHelpers.remove(
															index
														)
													}
												/>
											) : (
												<Chip
													clickable={false}
													key={index}
													label={values[index]}
												/>
											)}
										</React.Fragment>
									))}
							</Box>
						</>
					);
				}}
			/>
		</>
	);
};
