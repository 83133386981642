import React from 'react';
import { Typography } from '@material-ui/core';
interface FieldTitleProps {
  title?: string;
  color: any;
}
export const FieldTitle = (props: FieldTitleProps) => {
  const { title } = props;
  if(!title){
    return <></>;
  }
  
  return (
    <Typography variant="body1" align="left" color={props.color}>
    {title}
  </Typography>
  )
}

