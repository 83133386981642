import { makeStyles } from '@material-ui/styles';
import {
	theme,
	VerinoteBlueDark,
	VerinoteGreyFeature,
	VerinoteGreyToolbar,
} from './theme';

export const homeStyles = makeStyles({
	table: {
		overflowY: 'scroll',
		overflowX: 'hidden',
	},
	topToolbar: {
		backgroundColor: VerinoteGreyToolbar,
		padding: theme.spacing(1),
		borderBottom: `2px solid ${VerinoteGreyFeature}`,
		animation:
			'swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275) both',
		animationDelay: '200ms',
	},
	welcome: {
		paddingTop: '20vh',
		animation:
			'fade-in 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
	},
	notesList: {
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.2)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.2)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: VerinoteBlueDark,
			outline: `1px solid white`,
		},
		width: '35%',
		flexShrink: 1,
		display: 'inline-block',
		overflow: 'auto',
		paddingX: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginTop: -theme.spacing(1),
		animation:
			'slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
	},
	editNote: {
		padding: theme.spacing(1),
		width: '65%',
		display: 'inline-block',
		overflowY: 'scroll',
		overflowX: 'hidden',
	},
});
