import {
    Table,
    TableContainer,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    TablePagination, 
    Dialog
} from "@material-ui/core";
import {useState} from "react";
import {UserAuditLogDTO} from "../../../services";
import moment from "moment";

interface TableUserLog {
    data : UserAuditLogDTO[] | null;
    isOpen: boolean;
    closeModal: () => void;
}

export const TableUserLog = (props: TableUserLog) => {
    const cell = ["Field change", "Before value", "After value", "Modified By", "Modified At (Local time)"]
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Dialog open={props.isOpen} onClose={props.closeModal} maxWidth="lg" fullWidth={true}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {cell.map(it => (
                                <TableCell align="right">{it}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data && props.data.length > 0
                            ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell align="right">{row?.fieldChanged}</TableCell>
                                <TableCell align="right">{row?.beforeValue}</TableCell>
                                <TableCell align="right">{row?.afterValue}</TableCell>
                                <TableCell align="right">{row?.createdBy}</TableCell>
                                <TableCell align="right">{moment(row?.created).format("YYYY-MM-DD HH:mm a")}</TableCell>
                            </TableRow>
                        )) : <TableCell colSpan={7}>
                                <p style={{textAlign: 'center', fontSize: '15px'}}>No Data</p>
                            </TableCell> }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={props.data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Dialog>
    );
}