import { styled } from '@material-ui/core/styles';
import { ReactComponent as Logo } from './logo.svg';
import { VerinoteBlueDark } from 'config/theme';

export const AppBarLogo = styled(Logo)({
	fill: '#fff',
	height: '66px',
	marginLeft: '1rem',
	width: 'auto',
	animation: 'fade-in 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
	cursor: 'pointer',
	'&:hover': {
		fill: 'rgba(255,255,255,0.7)',
	},
});

export const MobileOnboardingLogo = styled(Logo)({
	fill: '#fff',
	height: 'auto',
	width: '60vw',
	margin: 'auto',
	marginBottom: '1vw',
	display: 'block',
	animation: 'fade-in 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
	cursor: 'pointer',
});

export const LoaderLogo = styled(Logo)({
	fill: VerinoteBlueDark,
	width: '12rem',
	height: 'auto',
	animation: 'fade-in 400s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
});

export const WelcomeLogo = styled(Logo)({
	fill: '#262626',
	width: '15rem',
	height: 'auto',
});
