import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography as Text,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import React from 'react';
import {
	ExportAuditDTO,
	InformationManagementMarkerDTO,
	ProtectiveMarkingDTO,
	SearchAuditDTO,
	SearchFilter,
} from 'services';
import { isEven } from 'utils';
interface AuditDTO extends ExportAuditDTO, SearchAuditDTO {}

interface LogRequest extends SearchFilter {
	exportType: number | undefined;
	password: string | undefined;
	exportPurpose: string | null | undefined;
}

const ExportLogRow = (props: {
	log: AuditDTO;
	search?: boolean;
	pm: ProtectiveMarkingDTO[];
	mm: InformationManagementMarkerDTO[];
}) => {
	const { log: audit, search, pm, mm } = props;
	const action = search ? 'Search' : 'Export';
	const nullValue = 'None';
	const actioning = `${action}ing`;
	const actioned = `${action}ed`;
	const request = audit.request
		? (JSON.parse(audit.request) as any)
		: ({} as any);

	const {
		now,
		exportPurpose,
		globalSearchReason,
		address,
		tags,
		searchStartDate,
		searchEndDate,
		protectiveMarkingId,
		informationManagementMarkerId,
		isPrivate,
		searchTerm,
	} = request['criteria'] ? request['criteria'] : request; // old format doesn't have .criteria field

	

	const dateFormat = (date: Date) => {
		return moment(date).format('YYYY-MM-DD [at] h:mm A z');
	};

	const report = [
		`${actioned} on`,
		`${now ? dateFormat(now) : nullValue}`,
		`${actioning} User`,
		`${audit.userEmail ?? nullValue}`,
		`Declared ${action} Reason`,
		`${(search ? globalSearchReason : exportPurpose) ?? nullValue}`,
		/* Unique Export */
		`Search Term`,
		`${searchTerm || ''}`,
		`${action}ed Address`,
		`${address ?? nullValue}`,
		`${actioned} Tags`,
		`${tags ?? nullValue}`,
		`Search Start Date`,
		`${searchStartDate ? dateFormat(searchStartDate) : nullValue}`,
		`Search End Date`,
		`${searchEndDate ? dateFormat(searchEndDate) : nullValue}`,
		`Searched Protective Marking`,
		`${
			protectiveMarkingId
				? pm.find((f) => f.id === request.protectiveMarkingId)?.name
				: nullValue
		}`,
		`Searched IMM/DLM`,
		`${
			informationManagementMarkerId
				? mm.find((f) => f.id === request.informationManagementMarkerId)
						?.name
				: nullValue
		}`,
		`Searched Private entries`,
		`${isPrivate ? 'yes' : 'no'}`,
	];

	if (search) {
		const returnedNoteIds = JSON.parse(audit?.response ?? '[]') as number[];
		report.push(`Returned Note IDs`);
		report.push(`${returnedNoteIds}`)
	} else {
		report.push(`Exported Note IDs`);
		report.push(`${request.relevantIds}`);
		report.push(`Unique Export Identifier`);
		report.push(`${audit.hash}`);
	}

	return (
		<ExpansionPanel>
			<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
				<Text
					color="textSecondary"
					variant="h6"
				>{`${action} ${audit.id}`}</Text>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
				<div>
					{report.map((_, index) =>
						isEven(index) ? (
							<div key={index} style={{ fontSize: 'Large' }}>
								<span style={{ fontWeight: 700 }}>
									{report[index]}:{' '}
								</span>
								<span>{report[index + 1]}</span>
							</div>
						) : (
							<div key={index}></div>
						)
					)}
				</div>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

export default ExportLogRow;
