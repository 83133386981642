/* eslint-disable no-console */
//import { observable, computed, action, runInAction } from "mobx";
import { action, computed, observable, runInAction } from "mobx";
import {
	NoteClient,
	NoteDTO,
	SearchFilter,
	SearchNotesViewModel,
	SearchType,
} from "services";
import { RootStore } from "store";
import BaseStore from "./baseStore";
import { GetPlatformSpecificDate, parseBadRequestError } from "utils";
import pgTsquery from "pg-tsquery";
import { SEARCH_PAGE_SIZE } from "config/SETTINGS";
import { EditNoteInput } from "./noteStore";

const EVENT_MONITOR_INTERVAL = 30000;

export const SEARCH_ANY_PROTECTIVE_MARKER_ID = Number.MIN_SAFE_INTEGER;
export const SEARCH_ANY_IMM_DLM_ID = Number.MIN_SAFE_INTEGER;

export interface SearchInput extends SearchFilter {
	monitor: boolean;
	parsedQuery?: string;
}

export class SearchStore extends BaseStore {
	private _noteClient: NoteClient;
	@observable private eventMonitor: number = 0;

	@observable results: NoteDTO[] = [];
	@observable activeIndex: number = 0;
	@observable hasMore: boolean = false;
	@observable isMonitoring: boolean = false;
	@observable searchInput: SearchInput = {
		isPrivate: false,
		searchType: SearchType.Standard,
		monitor: false,
		searchEndDate: undefined,
		searchStartDate: undefined,
		protectiveMarkingId: SEARCH_ANY_PROTECTIVE_MARKER_ID,
		informationManagementMarkerId: SEARCH_ANY_IMM_DLM_ID,
		pageSize: SEARCH_PAGE_SIZE,
		offset: 0,
		tags: [] as string[],
		attachment: "",
	} as SearchInput;

	@observable isAwaitingGlobalSearchReason: boolean = false;
	@observable globalSearchPurpose = "";

	@computed get isRequestingGlobalSearch(): boolean {
		return this.searchInput.searchType === SearchType.Global;
	}

	@computed get canHaveMore(): boolean {
		return this.results.length >= SEARCH_PAGE_SIZE;
	}

	@computed get sanitisedSearchInput(): SearchInput {
		const searchInput = this.searchInput;
		return {
			...searchInput,
			...(searchInput.protectiveMarkingId ===
				SEARCH_ANY_PROTECTIVE_MARKER_ID && {
				protectiveMarkingId: undefined,
			}),
			...(searchInput.informationManagementMarkerId ===
				SEARCH_ANY_IMM_DLM_ID && {
				informationManagementMarkerId: undefined,
			}),
		} as SearchInput;
	}

	constructor(root: RootStore) {
		super(root);
		this._noteClient = new NoteClient(this.API_URL);
	}

	@action MonitorEvent = (filter: SearchFilter, otp: string) => {
		console.log("monitoring event");
		window.clearTimeout(this.eventMonitor);
		const event = window.setInterval(() => {
			// keep requesting the SAME amount of notes matching the search critetia every EVENT_MONITOR_INTERVAL seconds
			// if the user scroll down during this time then we will load more (increment the pageSize)
			this._noteClient
				.search(otp, filter)
				.then((r: SearchNotesViewModel) => {
					runInAction(() => {
						if (r.notes) {
							this.success = `${r.notes.length} notes found for event: ${event}`;
							this.results = r.notes.map((n) => {
								return {
									...n,
									declaredStart: GetPlatformSpecificDate(
										n.declaredStart
									),
									created: GetPlatformSpecificDate(n.created),
								} as NoteDTO;
							});
							this.loading = false;
						}
					});
				})
				.catch((e) => {
					this._rootStore.submitExceptionToAppInsight(e);
					this._rootStore.otpStore.clearOtp();
					this.error = "Event Monitor Failed";
					this.loading = false;
				});
		}, EVENT_MONITOR_INTERVAL);

		this.success = `New Event being monitored`;
		this.eventMonitor = event;
		this.isMonitoring = true;
	};

	@action StopMonitoring() {
		if (this.eventMonitor) {
			window.clearTimeout(this.eventMonitor);
		}
		this.isMonitoring = false;
	}

	@action SetActiveIndex(index: number) {
		this.activeIndex = index;
	}

	@action SetSearchInput(input: SearchInput) {
		if (input) {
			this.searchInput = input;
		}
	}

	@action ResetSearchInput() {
		this.searchInput = {
			isPrivate: false,
			searchType: SearchType.Standard,
			monitor: false,
			searchEndDate: undefined,
			searchStartDate: undefined,
			protectiveMarkingId: SEARCH_ANY_PROTECTIVE_MARKER_ID,
			informationManagementMarkerId: SEARCH_ANY_IMM_DLM_ID,
		} as SearchInput;
	}

	@computed get activeNote(): NoteDTO | null {
		if (!this.results) {
			return null;
		}
		if (this.results.length > 0) {
			return this.results[this.activeIndex];
		}
		return null;
	}

	@action SearchNotesGlobally() {
		this.OpenGlobalSearchModal();
	}

	@action UpdateNoteInSearch(noteId: number, note: EditNoteInput) {
		var oldnote = this.results.find((x) => x.id === noteId);
		if (oldnote) {
			oldnote.title = note.title;
			oldnote.declaredStart = note.declaredStart;
			oldnote.declaredLocation = note.declaredLocation.name;
		}
	}

	@action async SearchNotes(nextPage?: boolean) {
		const search = (otp: string) => {
			this.AuthorisedRequest(this._noteClient, async () => {
				this.loading = true;
				this.searchInput.now = new Date();
				// mark hasMore = false to prevent the infinite scroll throwing the UI in a loop
				this.hasMore = false;

				if (nextPage) {
					// increment pageSize by SEARCH_PAGE_SIZE records
					this.searchInput.pageSize =
						(this.searchInput.pageSize || 0) + SEARCH_PAGE_SIZE;
				} else {
					// get the first page
					this.searchInput.pageSize = SEARCH_PAGE_SIZE;
				}

				const filter = ({
					...this.sanitisedSearchInput,
					// parse user input and convert to postgresql syntax
					searchTerm: pgTsquery()(
						(this.sanitisedSearchInput.searchTerm || "") as string
					),
				} as SearchInput) as SearchFilter;

				if (this.searchInput.monitor) {
					this.MonitorEvent(filter, otp);
				} else {
					this.StopMonitoring();
				}

				// get count when issueing new search only
				!nextPage &&
					this._noteClient
						.searchCount(otp, filter)
						.then((r: number) => {
							runInAction(
								() => (this.success = `Found ${r} notes`)
							);
						})
						.catch((e) => {
							// we don't need to do anything here because the main search would have failed too
						});


				this._noteClient
					.search(otp, filter)
					.then((r: SearchNotesViewModel) => {
						runInAction(() => {
							if (r.notes) {
								if (
									r.notes.length !==
										(this.searchInput.pageSize || 0) &&
									(this.searchInput.pageSize || 0) >
										SEARCH_PAGE_SIZE
								)
									this.success = "No more notes to load";

								this.results = r.notes.map((n) => {
									return {
										...n,
										declaredStart: GetPlatformSpecificDate(
											n.declaredStart
										),
										created: GetPlatformSpecificDate(
											n.created
										),
									} as NoteDTO;
								});

								this.loading = false;

								// if we ask for 60 and we get exactly 60, we have to assume there are more notes
								// if we get less than 60 which means there is no more notes
								this.hasMore =
									r.notes.length ===
									(this.searchInput.pageSize || 0);
							}
						});
					})
					.catch((e) => {
						this._rootStore.submitExceptionToAppInsight(e);
						if (e.status === 419) {
							// need to get new otp
							this._rootStore.otpStore.TriggerOTP(search);
						} else {
							this._rootStore.otpStore.clearOtp();
							this.error =
								parseBadRequestError(e) || "Search Failed";
							this.loading = false;
						}
					});
			});
		};

		if (this.searchInput.searchType === SearchType.Global) {
			// if we have otp in the store, just use it, if this otp expired, then the backend will tell us, then we will need to
			// ask user to put in new otp
			if (this._rootStore.otpStore.otp) {
				search(this._rootStore.otpStore.otp);
			} else {
				this._rootStore.otpStore.TriggerOTP(search);
			}
		} else {
			search("");
		}
	}

	@action OpenGlobalSearchModal() {
		this.isAwaitingGlobalSearchReason = true;
		this.globalSearchPurpose = "";
	}

	@action CloseGlobalSearchModal() {
		this.isAwaitingGlobalSearchReason = false;
	}

	@action UpdateGlobalSearchPurpose(purpose: string) {
		this.globalSearchPurpose = purpose;
	}

	@action SaveGlobalSearchPurpose() {
		if (this.globalSearchPurpose.length < 1) {
			return;
		}

		this.searchInput = {
			...this.searchInput,
			globalSearchReason: this.globalSearchPurpose,
		} as SearchInput;
		this.isAwaitingGlobalSearchReason = false;
		this.SearchNotes();
	}
}

export default SearchStore;
