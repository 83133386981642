import { RootStore } from "./index";
import { observable } from "mobx";
import { API_URL } from "../config/API_URL";
import {
  BaseClient,
  OTPClient,
  ClassificationClient,
  AttachmentClient,
  LocationClient,
  NoteClient,
  LogClient,
  UserClient,
  ConfigurationClient,
  TranscriptionsClient
} from "services";
export class BaseStore {
  protected _rootStore: RootStore;
  public API_URL: string;
  @observable loading: boolean = false;
  @observable error: string = "";
  @observable success: string = "";

  constructor(root: RootStore) {
    this._rootStore = root;
    this.API_URL = API_URL;
  }

  private CheckAuthToken = () => {
    const authStore = this._rootStore.authStore;
    const token = authStore.decodedToken;
    const expiry = new Date(token.exp * 1000);
    const now = new Date();
    if (expiry < now) {
      authStore.authenticate();
    }
  }

  protected ClientIsAuthorised = (client : BaseClient) => {
    const { authStore } = this._rootStore;
    if (authStore.bearerToken) {
      client.setAuthToken(authStore.bearerToken);
      return true;
    } else {
      return false;
    }
  }

  protected AuthorisedRequest = (client: OTPClient | ClassificationClient | AttachmentClient | LocationClient | NoteClient | LogClient | UserClient | ConfigurationClient | TranscriptionsClient, request : Function) => {
    this.CheckAuthToken();
    if (this.ClientIsAuthorised(client)) {
      try {
        return request();
      } catch (e) {
        this.error = "Action failed";
        console.log(e);
        if (e.status === 401 || e.status === "401") {
          console.log('Authorising request failed');
          this._rootStore.authStore.authenticate();
        }
      }
    }
  }
  
}

export default BaseStore;
