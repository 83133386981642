import React, {useMemo} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {Typography} from "@material-ui/core";

export default function AboutModal(props: any) {
  const version = useMemo(() => localStorage.getItem("Version"), []);

  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
		  <Typography variant="h5" align="center">
		  	Welcome To
		  </Typography>
		  <Typography variant="h1" align="center">
		  	<img src="/logoblack.png" width="300px"></img>
		  </Typography>
		  <Typography variant="body1" align="center">
		  	Version number: {version}
		  </Typography>
          </DialogContent>
        <DialogActions>          
          <Button onClick={props.closeModal} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}