/* eslint-disable no-console */
//import { observable, computed, action, runInAction } from "mobx";
import * as mime from 'mime';
import { AttachmentClient } from 'services';
import { RootStore } from 'store';
import {eraseCookie, parseBadRequestError, parseValidationError} from 'utils';
import BaseStore from './baseStore';

export interface CreateUserInput {
	workGroup: string;
	interfaceTimeZone: string;
	organisationIdentifier: string;
	accessControlGroup: number;
}

export const FAIL = 'FAIL';

export class FileStore extends BaseStore {
	private _attachmentClient: AttachmentClient;

	constructor(root: RootStore) {
		super(root);
		this._attachmentClient = new AttachmentClient(this.API_URL);
	}

	async fetchFile(guid: string, otp = "", reason = ""): Promise<Blob | null> {
		return this.AuthorisedRequest(this._attachmentClient, async () => {
			return this._attachmentClient
				.getPreviewFile(guid, otp, reason)
				.then((e) => {
					return e.data;
				})
				.catch((e) => {
					if (e.status === 419) {
						eraseCookie("otp");
						this._rootStore.previewStore.TogglePreviewModal();
					} else {
						this.error = "Get file failed";
					}
					this._rootStore.submitExceptionToAppInsight(e);
					return null;
				});
		});
	}

	async fetchZipfile(filename: string): Promise<Blob | null> {
		return this.AuthorisedRequest(this._attachmentClient, async () => {
			return this._attachmentClient
				.getZipFile(filename)
				.then((e) => {
					return e.data;
				})
				.catch((e) => {
					this._rootStore.submitExceptionToAppInsight(e);
					return null;
				});
		});
	}

	async uploadFile(file: Blob, name: string, activeNoteId: number): Promise<{id: string, filename: string}> {
		return new Promise(((resolve, reject) => {
			this.AuthorisedRequest(this._attachmentClient, async () => {
				// try detect file type File picker file type first
				const ext = mime.getExtension(file.type);
				const fileName = ext ? `${name}.${ext}` : name;

				this._attachmentClient
					.create(
						activeNoteId,
						fileName,
						false,
						null,
						null,
						{ fileName: `${name}`, data: file }
					)
					.then((res) => {
						this.loading = false;
						this.success = `File uploaded successfully`;
						resolve({id: res, filename: fileName});
					})
					.catch((e) => {
						this._rootStore.submitExceptionToAppInsight(e);
						const validation = parseValidationError(e);
						this.loading = false;

						if (validation && validation.FileName) {
							this.error = 'File format or extension is not valid';
						} else {
							this.error = parseBadRequestError(e) || 'Something went wrong with your file upload';
						}
						reject();
					});
			});
		}));
	}
}

export default FileStore;
