import React, { PropsWithChildren } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Popover from "@material-ui/core/Popover";

interface Props {
  id: string;
  buttonIcon: JSX.Element;
  closeOnClick?: boolean;
  showBackDrop?: boolean;
  disabled: boolean;
}

const Dropdown = (props: PropsWithChildren<Props>): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const id = anchorEl ? `${props.id}-dropdown` : undefined;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      {props.showBackDrop && <Backdrop open={open} />}
      {props.disabled ? (
        <span>{props.buttonIcon}</span>
      ) : (
        <span onClick={handleClick}>{props.buttonIcon}</span>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}>
        <div onClickCapture={() => props.closeOnClick && setAnchorEl(null)}>
          {props.children}
        </div>
      </Popover>
    </>
  );
};

export default Dropdown;
