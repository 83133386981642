import { Container, Typography } from '@material-ui/core';
import { MobileOnboardingLogo } from 'assets/logos';
import theme, { VerinoteBlue } from 'config/theme';
import React from 'react';
import appStore from '../../assets/images/apple-store.png';
import googlePlay from '../../assets/images/google-play-badge.png';


const IOS_DOWNLOAD_LINK = 'https://apps.apple.com/au/app/verinote-mobile/id1531046299';
const ANDROID_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=com.verisaas.verinote&hl=en&gl=US&pli=1';
const PROVISIONING_EMAIL = 'support@altiaintel.com';
const SUPPORT_URL_AGENCY = 'https://portal.altia-abm.com/hc/en-gb'
const SUPPORT_URL_INDIVIDUAL = 'https://portal.altia-abm.com/hc/en-gb'

const scale = (x: number, factor: number) =>
	window.innerHeight < 700 ? Math.round(factor * x) : x;

const Paragraph = ({ p, small }: { p: string; small?: boolean }) => {
	return (
		<>
			<Typography
				align="center"
				variant={small ? 'body2' : 'body1'}
				style={{
					color: '#FFFFFF',
					fontSize: small
						? `${scale(12, 0.75)}px`
						: `${scale(16, 0.75)}px`,
				}}
			>
				{p}
			</Typography>
			<br />
		</>
	);
};

const MobileView = () => {
	const mobileView_text_1 = [
		`We have detected you are attempting to access Capture on a mobile device`,
		`To ensure information security, access to Capture on mobile devices is resticted and only available through the official Capture Mobile Applications.`,
		`Please use the links below to download the official Capture Mobile Application for your device.`,
	];

	const mobileView_text_2 = [
		`If you have not yet registered or setup your Capture account please visit ${SUPPORT_URL_INDIVIDUAL} for instructions`,
		`If you are an employee of a agency already using Capture and need help setting up your Capture account, please visit ${SUPPORT_URL_AGENCY}`,
		``,
	];

	const LOGO_WIDTH = `${scale(150, 0.75)}px`;
	const CENTRE = {
		marginLeft: '50%',
		marginRight: 'auto',
		transform: `translate(-50%, -50%)`,
	};

	const logoStyle = {
		width: LOGO_WIDTH,
		height: 'auto',
		...CENTRE,
	};
	return (
		<div
			style={{
				backgroundColor: `${VerinoteBlue}`,
				height: '100%',
				width: '100%',
				left: 0,
				right: 0,
				bottom: 0,
				overflowX: 'hidden',
				overflowY: 'hidden',
				top: 0,
				position: 'absolute',
			}}
		>
			<Container maxWidth="sm">
				<MobileOnboardingLogo />
				{mobileView_text_1.map((p, i) => (
					<Paragraph p={p} small={false} key={i} />
				))}
				<div
					style={{ marginTop: '7vw', marginBottom: theme.spacing(2) }}
				>
					<img
						alt="ios download link"
						onClick={() =>
							(window.location.href = IOS_DOWNLOAD_LINK)
						}
						style={logoStyle}
						src={appStore}
					></img>
					<p></p>
					<img
						alt="Android download link"
						onClick={() =>
							(window.location.href = ANDROID_DOWNLOAD_LINK)
						}
						style={logoStyle}
						src={googlePlay}
					></img>
				</div>
			</Container>
			<div
				style={{
					bottom: -theme.spacing(2),
					position: 'absolute',
					paddingLeft: theme.spacing(2),
					paddingRight: theme.spacing(2),
					paddingTop: theme.spacing(1),
					backgroundColor: '#262626',
					color: '#FFFFFF',
					marginTop: theme.spacing(2),
				}}
			>
				{mobileView_text_2.map((p, i) => (
					<Paragraph p={p} small={true} key={i} />
				))}
			</div>
		</div>
	);
};

export default MobileView;
