/* eslint-disable no-console */
//import { observable, computed, action, runInAction } from "mobx";
import { OTPClient } from 'services';
import { RootStore } from 'store';
import BaseStore from './baseStore';
import { observable, action } from 'mobx';
import { parseBadRequestError } from 'utils';

export class OTPStore extends BaseStore {
	private _otpClient: OTPClient;

	constructor(root: RootStore) {
		super(root);
		this._otpClient = new OTPClient(this.API_URL);
	}
	@observable isAwaitingOTP: boolean = false;
	@observable otp: string = '';
	@observable sentViaEmail: boolean = false;
	@observable task: Function = () => {};

	reset() {
		this.isAwaitingOTP = false;
		this.task = () => {};
		this.loading = false;
	}

	@action clearOtp() {
		this.otp = '';
	}

	@action async SendOtp(otp: string) {
		// save this otp so that we can use it when people scroll to next page
		this.otp = otp;
		this.task(otp);
		this.reset();
	}

	@action async SendSignupOtp(number: string) {
		this.loading = true;

		this.AuthorisedRequest(this._otpClient, async () => {
			try {
				await this._otpClient.create(number);
				this.loading = false;
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.error = parseBadRequestError(e) || 'Error sending OTP. Please try again later';
				this.loading = false;
			}
		});
	}

	@action async TriggerOTP(callback: Function) {
		this.loading = true;
		this.sentViaEmail = false;
		this.AuthorisedRequest(this._otpClient, async () => {
			try {
				await this._otpClient.create('');
				this.isAwaitingOTP = true;
				this.task = callback;
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.loading = false;
				this.error = parseBadRequestError(e) || 'Error sending OTP. Please try again later';
			}
		});
	}

	@action async TriggerOTPEmail(callback: Function) {
		this.loading = true;
		this.sentViaEmail = true;
		this.AuthorisedRequest(this._otpClient, async () => {
			try {
				await this._otpClient.otpViaEmail('');
				this.isAwaitingOTP = true;
				this.task = callback;
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.loading = false;
				this.error = parseBadRequestError(e) || 'Error sending OTP. Please try again later';
			}
		});
	}

	@action cancelOtp() {
		this.reset();
	}
}

export default OTPStore;
