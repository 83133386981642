/* eslint-disable no-console */
import { routes } from 'config/routes';
import { action, computed, observable } from 'mobx';
import { RootStore } from 'store';
import { AUTOSAVE_TIMEOUT } from '../config/SETTINGS';
import BaseStore from './baseStore';

export class ApplicationStore extends BaseStore {
	@observable lastInteraction: Date = new Date();
	@observable loading: boolean = false;

	stores: BaseStore[] = [];

	getStores = () => {
		const {
			searchStore,
			fileStore,
			noteStore,
			authStore,
			userStore,
			exportStore,
			adminStore,
			configStore,
			otpStore,
			transcribeStore
		} = this._rootStore;
		return [
			searchStore,
			fileStore,
			noteStore,
			authStore,
			userStore,
			exportStore,
			adminStore,
			configStore,
			otpStore,
			transcribeStore
		];
	};

	getLoadingStores = () => {
		const {
			searchStore,
			fileStore,
			userStore,
			exportStore,
			adminStore,
			noteStore,
			configStore,
			otpStore,
		} = this._rootStore;
		return [
			searchStore,
			fileStore,
			userStore,
			exportStore,
			adminStore,
			noteStore,
			configStore,
			otpStore,
		];
	};

	@action clearSuccess(): void {
		this.getStores().forEach((s) => (s.success = ''));
	}

	@action clearError(): void {
		this.getStores().forEach((s) => (s.error = ''));
	}

	@computed get CurrentSuccess(): any {
		return this.getStores().find((s) => s.success)?.success;
	}

	@computed get CurrentError(): any {
		return this.getStores().find((s) => s.error)?.error;
	}

	@computed get CurrentLoading() {
		const loading = this.getLoadingStores().map((e) => e.loading);
		return loading.some((e) => e === true);
	}

	@computed get currentRoute(): any {
		const route = routes.find((r) => r.path === window.location.pathname);
		return route;
	}

	@action SetLastInteraction(date: Date) {
		this.lastInteraction = date;
	}

	autosave(): boolean {
		const difference = Math.abs(
			(new Date().getTime()) - (new Date(this.lastInteraction).getTime())
		);
		if (
			this._rootStore.noteStore.editing &&
			difference > AUTOSAVE_TIMEOUT
		) {
			this.lastInteraction = new Date();
			return true;
		}
		return false;
	}

	init() {
		window.addEventListener('mousemove', () => {
			this.SetLastInteraction(new Date());
		});
		window.addEventListener('keydown', () => {
			this.SetLastInteraction(new Date());
		});
	}

	constructor(root: RootStore) {
		super(root);
		this.stores = [root.authStore, root.userStore];
		this.init();
	}

	@action setLoading(l: boolean) {
		this.loading = l;
	}
}

export default ApplicationStore;
