import React from "react";
import  {Lottie } from "@crello/react-lottie";
// import { LoaderLogo } from '../logos';
// import { VerinoteBlueDark } from "config/theme";
// const loading = require("./loading.json");
const authenticating = require("./authenticating.json");
const recording = require("./recording.json");

const size = '150px';

export const LoadingAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: authenticating,
    style:{margin:'20px'},
    speed: 2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className="loader">
      <div className="loading-animation">
        <Lottie config={defaultOptions}
        style={{ margin: "auto" }}
        height={size}
        width={size}
        />
      </div>
    </div>
  );
};



export const RecordingAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: recording,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <Lottie
      config={defaultOptions}
      width={'300px'}
    />
  );
};