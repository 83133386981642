import BaseStore from "./baseStore";
import {action, observable} from "mobx";
import {AttachmentClient, NoteClient} from "../services";
import {RootStore} from "./index";
import {eraseCookie, setCookie} from "../utils";

export class PreviewStore extends BaseStore {
  private _attachmentClient: AttachmentClient;
  @observable previewPurpose = "";
  @observable isPreviewing: boolean = false;
  @observable isAwaitingPreviewReason: boolean = false;
  @observable otpPreview: string = "";
  @observable uuidFile: string = "";
  @observable previewUrl: string = "";
  @observable openPreview: Function = () => {};
  @observable handleLoading: Function = () => {}
  
  constructor(root: RootStore) {
    super(root);
    this._attachmentClient = new AttachmentClient(this.API_URL);
  }
  
  @action UpdatePreviewPurpose(purpose: string) {
    this.previewPurpose = purpose;
  }

  @action TogglePreviewModal(uuid = "") {
    this.isAwaitingPreviewReason = !this.isAwaitingPreviewReason;
    this.uuidFile = uuid || this.uuidFile;
    if (!this.isAwaitingPreviewReason) {
      this.handleLoading(false);
    }
  }
  
  @action RequestPreview() {
    this.previewUrl = "";
    this.isAwaitingPreviewReason = false;
    this.handleLoading(false);
    const updateOtpPreview = (otp: string) => {
      this.handleLoading(true);
      this._rootStore.fileStore.fetchFile(this.uuidFile, otp, this.previewPurpose).then(value => {
        if (value) {
          setCookie("otp", otp);
          this.previewUrl =  URL.createObjectURL(value);
          this.openPreview();
        } else {
          this.previewUrl = "";
          eraseCookie("otp");
          this.handleLoading(false);
        }
      });
    }
    this._rootStore.otpStore.TriggerOTP(updateOtpPreview);
  }
  
  @action UpdateUUIDFile(uuid: string) {
    this.uuidFile = uuid;
  }
  
  @action UpdatePreviewUrl(url: string) {
    this.previewUrl = url;
  }
  
  @action SetOpenPreview(openPreview: Function) {
    this.openPreview = openPreview
  }
  
  @action SetHandleLoading(updateLoading: Function) {
    this.handleLoading = updateLoading;
  }
}