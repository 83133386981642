import moment from "moment";
import { SearchType } from "services";

import {
	SearchInput,
	SEARCH_ANY_IMM_DLM_ID,
	SEARCH_ANY_PROTECTIVE_MARKER_ID,
} from "store/searchStore";

function quote(val: any) {
	return (val || "").indexOf(" ") > 0 ? `"${val}"` : val;
}
export function toQueryString(search: Partial<SearchInput>) {
	let query = "";
	console.log("to query", search);

	Object.keys(search).forEach((k) => {
		const key = k as keyof SearchInput;
		const value = search[key];
		switch (key) {
			case "address":
				if (value) query += `loc:${quote(value)} `;
				break;
			case "protectiveMarkingId":
				if (value !== SEARCH_ANY_PROTECTIVE_MARKER_ID)
					query += `marking:${value} `;
				break;
			case "informationManagementMarkerId":
				if (value !== SEARCH_ANY_IMM_DLM_ID) query += `imm:${value} `;
				break;
			case "urn":
				if (value) query += `urn:${quote(value)} `;
				break;
			case "tags":
				if ((value as string[])?.length)
					query += `tags:${(value as string[]).join(",")} `;
				break;
			case "attachment":
				if (value) query += `attachment:${quote(value)} `;
				break;
			case "dateCreated":
				if (value === true) query += `in:created `;
				break;
			case "isPrivate":
				if (value === true) query += `is:private `;
				break;
			case "workUnit":
				if (value) query += `unit:${quote(value)} `;
				break;
			case "userName":
				if (value) query += `user:${quote(value)} `;
				break;
			case "searchType":
				if ((value as SearchType) === SearchType.Global)
					query += `type:global `;
				break;
			default:
				break;
		}
	});
	
	if (search.searchStartDate && search.searchEndDate) {
		query += `date:${moment(search.searchStartDate).format(
			"YYYY/MM/DD"
		)}-${moment(search.searchEndDate).format("YYYY/MM/DD")} `;
	} else if (search.searchStartDate) {
		query += `from:${moment(search.searchStartDate).format("YYYY/MM/DD")} `;
	} else if (search.searchEndDate) {
		query += `to:${moment(search.searchEndDate).format("YYYY/MM/DD")} `;
	}
	return search.searchTerm ? search.searchTerm + " " + query : query;
}

export function fromQueryString(query: string) {
	var searchQuery = require("search-query-parser");
	var options = {
		keywords: [
			"loc",
			"marking",
			"imm",
			"urn",
			"tags",
			"attachment",
			"is",
			"unit",
			"user",
			"type",
			"from",
			"to",
		],
		ranges: ["date"],
	};
	var search = searchQuery.parse(query, options);
	
	return {
		// search-query-parser returns string if there is no match
		searchTerm: typeof search === "string" ? search : search.text,
		address: search.loc,
		protectiveMarkingId: search.marking || SEARCH_ANY_PROTECTIVE_MARKER_ID,
		informationManagementMarkerId: search.imm || SEARCH_ANY_IMM_DLM_ID,
		urn: search.urn,
		tags: Array.isArray(search.tags)
			? search.tags
			: search.tags
			? [search.tags]
			: [],
		attachment: search.attachment,
		dateCreated: !!search.in,
		isPrivate: !!search.is,
		searchType:
			search.type === "global" ? SearchType.Global : SearchType.Standard,
		workUnit: search.unit,
		userName: search.user,
		searchStartDate: search.from
			? moment(search.from, "YYYY/MM/DD").toDate()
			: search.date?.from
			? moment(search.date?.from, "YYYY/MM/DD").toDate()
			: undefined,
		searchEndDate: search.to
			? moment(search.to, "YYYY/MM/DD").toDate()
			: search.date?.to
			? moment(search.date?.to, "YYYY/MM/DD").toDate()
			: undefined,
	} as SearchInput;
}
