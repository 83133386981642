import React from "react";
import { Button, Box, Checkbox } from "@material-ui/core";

interface ExportControlProps {
	active: boolean;
	confirm: () => void;
	toggle: () => void;
	toggleSelectAll: () => void;
	notesSelected: boolean;
	allSelected: boolean;
	disabled: boolean;
}
export const ExportControl = (props: ExportControlProps) => {
	const {
		active,
		confirm,
		toggle,
		notesSelected,
		allSelected,
		toggleSelectAll,
		disabled,
	} = props;
	return (
		<Box display="flex" justifyContent="space-evenly">
			{active && (
				<Checkbox
					className="fade-in-anim"
					color="primary"
					checked={allSelected}
					indeterminate={!allSelected && notesSelected}
					onClick={toggleSelectAll}
				/>
			)}
			<Button
				disabled={disabled}
				disableRipple
				color={active ? "default" : "primary"}
				onClick={toggle}
			>
				{active ? "Cancel" : "Export"}
			</Button>

			{active && (
				<Button
					variant="outlined"
					color="primary"
					size="small"
					onClick={confirm}
					disabled={!notesSelected}
				>
					{"Export"}
				</Button>
			)}
		</Box>
	);
};
