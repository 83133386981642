import { AccessControlGroup } from "../services/Verinote-api";

// application level permission, each permission is linked to a button on the UI -> this doesn't changed based on data
const Static: Partial<Record<Permissions, Permissions>> = {
	CreateOwnData: "CreateOwnData",
	CreateOtherData: "CreateOtherData",
	SearchOwnData: "SearchOwnData",
	SearchOwnWorkUnitData: "SearchOwnWorkUnitData",
	SearchAgencyWideUserData: "SearchAgencyWideUserData",
	ReadAgencyWidePrivateUserData: "ReadAgencyWidePrivateUserData",
	ExportOwnData: "ExportOwnData",
	UpdateAccessControlGroup: "UpdateAccessControlGroup",
	UserManagement: "UserManagement",
	Audits: "Audits",
	SearchSpecificUser: "SearchSpecificUser",
	SearchByWorkgroup: "SearchByWorkgroup",
};

// data row level permission
// TODO: Replace `any` below with strongly typed interface
export const Dynamic: Partial<Record<Permissions, any>> = {
	ReadOwnData: {
		ReadOwnData: ({ userId, ownerId }: AcgCheckData) => {
			// read own note
			if (!userId || !ownerId) return false;
			return userId.toString() === ownerId.toString();
		},
	},
	ReadOwnWorkUnitData: {
		ReadOwnWorkUnitData: ({ userUnitId, unitId }: AcgCheckData) => {
			// read note created by users in same Unit as you are
			if (!userUnitId || !unitId) return false;
			return userUnitId.toString() === unitId.toString();
		},
	},
	ExportOwnWorkUnitData: {
		ExportOwnWorkUnitData: ({ userUnitId, unitId }: AcgCheckData) => {
			// read note created by users in same Unit as you are
			if (!userUnitId || !unitId) return false;
			return userUnitId.toString() === unitId.toString();
		},
	},
	ReadAgencyWideUserData: {
		ReadAgencyWideUserData: ({ isPrivate }: AcgCheckData) => {
			// read all notes that are NOT marked as Private
			return !isPrivate;
		},
	},
	ReadAgencyWidePrivateUserData: {
		ReadAgencyWidePrivateUserData: ({ isPrivate }: AcgCheckData) => {
			// read all notes that are NOT marked as Private
			return isPrivate;
		},
	},

	UpdateData: {
		UpdateData: ({ userId, ownerId, isLawyer }: AcgCheckData) => {
			if (!userId || !ownerId) return false;

			// Update Own Data
			if (userId.toString() === ownerId.toString()) return true;

			// Lawyer can update
			if (isLawyer === true) return true;

			return false;
		},
	},

	UpdateClassificationField: {
		UpdateClassificationField: ({
			userId,
			ownerId,
			isLawyer,
		}: AcgCheckData) => {
			// owner can edit and Lawyer can edit

			if (!userId || !ownerId) return false;

			// Update Own Data
			if (userId.toString() === ownerId.toString()) return true;

			return isLawyer;
		},
	},

	UpdateNonClassificationField: {
		UpdateNonClassificationField: ({ userId, ownerId }: AcgCheckData) => {
			if (!userId || !ownerId) return false;
			return userId.toString() === ownerId.toString();
		},
	},

	UpdateAgencyWideUserPrivateData: {
		UpdateAgencyWideUserPrivateData: ({ isLawyer }: AcgCheckData) => {
			return isLawyer;
		},
	},
};

const BaseLine = {
	roleId: AccessControlGroup.Baseline,
	static: [
		Static.CreateOwnData,
		Static.SearchOwnData,
		Static.ExportOwnData,
		Static.SearchSpecificUser,
		Static.SearchByWorkgroup,
	],
	dynamic: {
		...Dynamic.ReadOwnData,
		...Dynamic.UpdateData,
		...Dynamic.UpdateClassificationField,
		...Dynamic.UpdateNonClassificationField,
	},
};

const Restricted = {
	roleId: AccessControlGroup.Restricted,
	static: [
		Static.CreateOwnData,
		Static.SearchOwnData,
		Static.ExportOwnData,
		Static.SearchSpecificUser,
	],
	dynamic: {
		...Dynamic.ReadOwnData,
		...Dynamic.UpdateData,
		...Dynamic.UpdateClassificationField,
		...Dynamic.UpdateNonClassificationField,
	},
};

const Supervisor = {
	roleId: AccessControlGroup.Supervisor,
	// can do what BaseLine can plus more
	static: [...BaseLine.static, Static.SearchOwnWorkUnitData],
	dynamic: { ...BaseLine.dynamic, ...Dynamic.ReadOwnWorkUnitData },
};

const Intelligence = {
	roleId: AccessControlGroup.Intelligence,
	static: [...BaseLine.static, Static.SearchAgencyWideUserData],
	dynamic: { ...BaseLine.dynamic, ...Dynamic.ReadOwnWorkUnitData },
};

const Integrity = {
	static: [
		...BaseLine.static,
		Static.SearchAgencyWideUserData,
		Static.Audits,
	],
	dynamic: {
		...BaseLine.dynamic,
		...Dynamic.ReadOwnWorkUnitData,
		...Dynamic.ReadAgencyWideUserData,
		...Dynamic.ReadAgencyWidePrivateUserData,
	},
};

const CEODelegate = {
	static: [
		...BaseLine.static,
		Static.SearchAgencyWideUserData,
		Static.Audits,
		Static.SearchSpecificUser,
	],
	dynamic: {
		...BaseLine.dynamic,
		...Dynamic.ReadOwnWorkUnitData,
		...Dynamic.ReadAgencyWideUserData,
		...Dynamic.ReadAgencyWidePrivateUserData,
	},
};

const OrgLawyer = {
	static: [
		...BaseLine.static,
		Static.SearchAgencyWideUserData,
	],
	dynamic: {
		...BaseLine.dynamic,
		...Dynamic.ReadOwnWorkUnitData,
		...Dynamic.ReadAgencyWideUserData,
		...Dynamic.ReadAgencyWidePrivateUserData,

		...Dynamic.UpdateAgencyWideUserPrivateData,
	},
};

const Admin = {
	static: [
		...BaseLine.static,
		Static.UpdateAccessControlGroup,
		Static.UserManagement,
	],
	dynamic: {
		...BaseLine.dynamic,
		...Dynamic.ReadOwnWorkUnitData,
		...Dynamic.ReadAgencyWideUserData,
		...Dynamic.ReadAgencyWidePrivateUserData,
	},
};

const Integration = {
	static: [] as Permissions[],
	dynamic: {},
}; // Needed to serialise for the rules but should have no permissions in the UI

const Rules = {
	[AccessControlGroup.Baseline]: BaseLine,
	[AccessControlGroup.Supervisor]: Supervisor,
	[AccessControlGroup.Intelligence]: Intelligence,
	[AccessControlGroup.Integrity]: Integrity,
	[AccessControlGroup.CEO_Delegate]: CEODelegate,
	[AccessControlGroup.OrganisationLawyer]: OrgLawyer,
	[AccessControlGroup.Admin]: Admin,
	[AccessControlGroup.Integration]: Integration,
	[AccessControlGroup.Restricted]: Restricted,
};

export type Permissions =
	| "UserManagement"
	| "Audits"
	| "CreateOwnData"
	| "CreateOtherData"
	| "SearchOwnData"
	| "SearchOwnWorkUnitData"
	| "SearchAgencyWideUserData"
	| "ReadAgencyWidePrivateUserData"
	| "ExportOwnData"
	| "UpdateAccessControlGroup"
	| "ReadOwnData"
	| "ReadOwnWorkUnitData"
	| "ExportOwnWorkUnitData"
	| "ReadAgencyWideUserData"
	| "UpdateData"
	| "UpdateClassificationField"
	| "UpdateNonClassificationField"
	| "UpdateAgencyWideUserPrivateData"
	| "SearchSpecificUser"
	| "SearchByWorkgroup";

interface AcgCheckData {
	userId?: string;
	ownerId?: number | string | undefined;
	userUnitId?: number;
	unitId?: number;
	isLawyer?: boolean;
	isPrivate?: boolean;
}

const checkPermission = (
	role: AccessControlGroup | undefined,
	action: Permissions,
	data?: AcgCheckData
): boolean => {
	// BaseLine role = 0
	if (role === null || role === undefined) return false;
	const permissions = Rules[role];
	if (!permissions) {
		// role is not present in the rules
		return false;
	}

	const staticPermissions = permissions.static;

	if (staticPermissions && staticPermissions.includes(action)) {
		// match static rule
		return true;
	}

	const dynamicPermissions = permissions.dynamic as any;

	if (dynamicPermissions) {
		const permissionCondition = dynamicPermissions[action];
		if (!permissionCondition) {
			// dynamic rule not provided for action
			return false;
		}

		return permissionCondition(data);
	}
	return false;
};

// this is a React Component that you can use to wrap another component based on permission
/**
 * Usage:
 * 
    <Can
      role={user.role}
      action="UpdateData"
      data={{
              userId: user.id,
              onwerId: p3ost.ownerId
            }}
      yes={() => (
        <h2>User can do it</h2>
      )}
      no={() => <h2>User can't do it</h2>}
    />
 */
const Can = ({
	role,
	action,
	data,
	yes,
	no,
}: {
	role: AccessControlGroup | undefined;
	action: Permissions;
	data?: AcgCheckData;
	yes?: any;
	no?: any;
}) => {
	const empty = () => null;
	const y = yes ?? empty;
	const n = no ?? empty;
	return checkPermission(role, action, data) ? y() : n();
};

export { Rules, checkPermission, Can };
