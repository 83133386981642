import { useEffect, useRef, useState } from "react";
import {placeCaretAtEnd} from "../utils";
import {makeStyles} from "@material-ui/core";
import theme from "../config/theme";
import clsx from 'clsx';

const useStyles = makeStyles({
  contentEditable: {
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: theme.palette.grey[300],
    padding: '14px',
    '&.bgGray100': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  tips: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[500],
    fontStyle: 'italic',
    paddingTop: theme.spacing(0.5),
    display: 'block',
    opacity: 0,
    [theme.breakpoints.up('md')]: {
      opacity: 1,
    },
  },
});

const EditPhrase: React.FC<{
  initialValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}> = ({ disabled, initialValue, onChange }) => {
  const classes = useStyles();
  const [text, setText] = useState(initialValue);
  const contentEditableRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (contentEditableRef.current) {
        contentEditableRef.current.focus();
        placeCaretAtEnd(contentEditableRef.current);
      }
    }, 100);
  }, []);

  const handleChange = (evt) => {
    setText(evt.target.value);
  };

  const handleBlur = () => {
    if (contentEditableRef.current && onChange) {
      onChange(contentEditableRef?.current?.innerText?.trim());
    }
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      onChange(
        contentEditableRef?.current?.innerText?.replaceAll("\n", "").trim()
      );
    }
  };

  return (
    <>
      <div
        ref={contentEditableRef}
        dangerouslySetInnerHTML={{ __html: text }}
        contentEditable={!disabled}
        className={clsx(classes.contentEditable, {
          'bgGray100': disabled,
        })}
        onBlur={handleBlur}
        onKeyUp={onKeyUp}
        onChange={handleChange}
      />
      <div className={classes.tips}>
          Tips: Press Enter to save
      </div>
    </>
  );
};

export default EditPhrase;
