import { Button, makeStyles } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import React from 'react';

const styles = makeStyles({
	docsButton: {
		minWidth: '300px',
		height: '100px',
		display: 'flex',
		justifyContent: 'space-around',
		marginTop: (props: any) => {
			return props.newRecording ? 55 : -68;
		},
	},
});

interface ViewMediaProps {
	isAudio: boolean;
	isImage: boolean;
	blobUrl: string;
	autoPlay?: boolean;
	onView?: any;
	newRecording?: boolean;
}

export const ViewMedia = ({
	isAudio,
	isImage,
	blobUrl,
	onView,
	autoPlay,
	newRecording,
}: ViewMediaProps) => {
	const classes = styles({ newRecording });

	if (!blobUrl) return <></>;

	if (isImage) {
		return (
			<div style={{ display: 'flex', justifyContent: 'space-around' }}>
				<img
					alt="uploaded-file"
					onContextMenu={(e) => e.preventDefault()}
					onClick={onView}
					style={{ maxWidth: '300px', height: 'auto' }}
					src={blobUrl}
				/>
			</div>
		);
	}

	if (isAudio) {
		return (
			<div className={classes.docsButton}>
				<audio
					controlsList="nodownload"
					style={{ width: '300px' }}
					onContextMenu={(e) => e.preventDefault()}
					autoPlay={autoPlay}
					key={blobUrl}
					controls
				>
					<source src={blobUrl} type="audio/wav" />
				</audio>
			</div>
		);
	} else {
		return (
			<div className={classes.docsButton}>
				<Button
					variant="text"
					onClick={onView}
					startIcon={<FolderOpenIcon fontSize="small" />}
				>
					Open
				</Button>
			</div>
		);
	}
};
