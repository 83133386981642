/* eslint-disable  @typescript-eslint/no-explicit-any */
import { LoadingAnimation } from "assets/animation";
import React from "react";
import { Route } from "react-router-dom";
import Home from "containers/Home";
import Search from "containers/Search";
import Profile from "containers/Profile";
import Admin from "containers/Admin";

export interface RouteConfig {
	path: string;
	title: string;
	canActivate?: any;
	routeError?: string;
	component: React.ComponentType<any>;
}

export const HomeRoute: RouteConfig = {
	path: "/",
	title: "Home",
	component: Home,
};

export interface RouteCheck {
	route: string;
	validUser: boolean;
	isAdmin: boolean;
	isIntegrity: boolean;
	isCEOorDelegate: boolean;
}

export const routes = [
	HomeRoute,
	{
		path: "/search",
		title: "Search",
		component: Search,
	},
	{
		path: "/profile",
		title: "Profile",
		component: Profile,
	},
	{
		path: "/admin",
		title: "Admin",
		canActivate: ({
			validUser,
			isAdmin,
			isIntegrity,
			isCEOorDelegate,
		}: any) => {
			return validUser && (isAdmin || isIntegrity || isCEOorDelegate);
		},
		routeError: "Admin permission is required to access this area",
		component: Admin,
	},
] as RouteConfig[];

export const canNavigateToRoute = ({
	route,
	validUser,
	isAdmin,
	isIntegrity,
	isCEOorDelegate,
}: RouteCheck): [RouteConfig, string] => {
	const cr = routes.find((r) => r.path === route);
	if (
		cr?.canActivate &&
		!cr.canActivate({ validUser, isAdmin, isIntegrity, isCEOorDelegate })
	) {
		return [HomeRoute, cr.routeError || "Access denied"];
	}
	return [cr || HomeRoute, ""];
};

export const hasRouteGuard = (path: string) =>
	routes.find((r) => r.path === path)?.canActivate;

export const getRouteByPath = (path: string) =>
	routes.find((r) => r.path === path);

export const Routes = (): JSX.Element => {
	return (
		<React.Suspense fallback={<LoadingAnimation />}>
			{routes.map((r) => {
				return (
					<Route
						key={r.title}
						path={r.path}
						component={r.component}
						exact
					/>
				);
			})}
		</React.Suspense>
	);
};
