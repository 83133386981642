import { Box, Typography } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';
import theme, { classColors, VerinoteGreyToolbar } from 'config/theme';
import moment from 'moment';
import React, { createRef } from 'react';
import { NoteDTO } from 'services';
import { GetNoteTitle, GetTimeDateString } from 'utils';

const useStyles = makeStyles({
	root: {
		margin: theme.spacing(1),
		cursor: "pointer",
		padding: theme.spacing(1),
		borderRadius: '5px',
		boxShadow:
			'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
		lineHeight: `${theme.spacing(3)}px`,
	},
	color: {
		backgroundColor: classColors.Pink,
	},
});

const iconColor = '#CFCFCF';

interface NoteCardProps {
	note: NoteDTO;
	selected: boolean;
	onSelectNote: () => void;
	disabled: boolean;
}

export const NoteCard = ({
	note,
	selected,
	disabled,
	onSelectNote,
}: NoteCardProps) => {
	const classes = useStyles();
	const ref = createRef<HTMLDivElement>();
	const hasAttachment = note.attachments && note.attachments?.length > 0;

	return (
		<span ref={selected ? ref : null}>
			<Box
				className={classes.root}
				onClick={onSelectNote}
				style={{
					borderLeftColor: note.pmColour,
					borderLeftStyle: 'solid',
					borderLeftWidth: '12px',
					backgroundColor: selected ? '#FFFFFF' : VerinoteGreyToolbar,
					paddingLeft: theme.spacing(2),
					filter: disabled && !selected ? 'blur(3px)' : '',
					transform: disabled && !selected ? 'Scale(0.90)' : '',
				}}
			>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
				>
					<Box
						display="flex"
						justifyContent="space-between"
						flexDirection="column"
					>
						<Box style={{ fontWeight: 600 }}>
							{GetNoteTitle(note.title)}
						</Box>
						<Box>
							<ScheduleIcon
								style={{
									marginBottom: '-2px',
									fontSize: '14px',
								}}
							/>
							{` ${moment(
								note.declaredStart
							).fromNow()} - ${GetTimeDateString(
								note.declaredStart
							)}`}
						</Box>
						<Box>
							<Typography
								style={{
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									maxWidth: '25vw',
									minHeight: '1.25em',
								}}
								variant="body1"
							>
								<LocationOnIcon
									style={{
										marginBottom: '-2px',
										marginLeft: '-3px',
										color: iconColor,
									}}
									fontSize="small"
								/>

								{note.declaredLocation}
							</Typography>
						</Box>
					</Box>
					<Box alignSelf="flex-end">
						<Box>
							{hasAttachment && (
								<AttachmentIcon
									style={{ transform: 'rotateZ(-45deg)' }}
									fontSize="small"
								/>
							)}
							{note.isPrivate && (
								<VisibilityOffIcon fontSize="small" />
							)}
						</Box>
					</Box>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					flexDirection="column"
				>
					<Box>
						<Typography
							style={{
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								maxWidth: '25vw',
								minHeight: '1.25em',
							}}
							variant="body1"
						>
							<PersonIcon
								style={{
									marginBottom: '-2px',
									marginLeft: '-3px',
									color: iconColor,
								}}
								fontSize="small"
							/>
							{` ${note.ownerName} `}
						</Typography>
					</Box>
				</Box>
			</Box>
		</span>
	);
};
