import React from "react";
import { AuthStore } from "./authStore";
import { UserStore } from "./userStore";
import { ApplicationStore } from "./applicationStore";
import { NoteStore } from "./noteStore";
import FileStore from "./fileStore";
import SearchStore from "./searchStore";
import ExportStore from "./exportStore";
import AdminStore from "./adminStore";
import OTPStore from "./otpStore";
import { ConfigStore } from "./configStore";
import { PreviewStore } from "./previewStore";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {TranscribeStore} from "./transcribeStore";

export class RootStore {
	applicationStore: ApplicationStore;
	authStore: AuthStore;
	userStore: UserStore;
	noteStore: NoteStore;
	fileStore: FileStore;
	searchStore: SearchStore;
	exportStore: ExportStore;
	adminStore: AdminStore;
	otpStore: OTPStore;
	configStore: ConfigStore;
	previewStore: PreviewStore;
	transcribeStore: TranscribeStore;
	appInsights: ApplicationInsights;

	constructor() {
		this.authStore = new AuthStore(this);
		this.userStore = new UserStore(this);
		this.applicationStore = new ApplicationStore(this);
		this.noteStore = new NoteStore(this);
		this.fileStore = new FileStore(this);
		this.searchStore = new SearchStore(this);
		this.exportStore = new ExportStore(this);
		this.adminStore = new AdminStore(this);
		this.otpStore = new OTPStore(this);
		this.configStore = new ConfigStore(this);
		this.previewStore = new PreviewStore(this);
		this.transcribeStore = new TranscribeStore(this);

		console.log("app insight key", localStorage.getItem("TelemetryKey"));

		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: localStorage.getItem("TelemetryKey") || "",
			},
		});
		this.appInsights.loadAppInsights();
	}

	submitExceptionToAppInsight(error: any) {
		console.error('unhandled JS error', error);
		if (error instanceof Error) {
			this.appInsights.trackException({
				exception: error,
			});
		} else {
			this.appInsights.trackException({
				exception: new Error(error),
			});
		}
	}
}

export function initStore(): RootStore {
	return new RootStore();
}

export const StoreContext = React.createContext<RootStore | null>(null);

export const useStore = (): RootStore => {
	const store = React.useContext(StoreContext);

	if (!store) {
		throw new Error("you forgot to use the store Provider");
	}
	return store;
};
