import React, { useEffect, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import {
	Button,
	TextField,
	Box,
	Typography,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { useStyles } from "./index";
import { ExportType } from "services";
import { ExportMode } from "store/exportStore";
import { SearchInput } from "store/searchStore";
interface CheckBox {
	name: string;
	key: string;
	isChecked: boolean;
}
const ExportModal = () => {
	const classes = useStyles();
	const { exportStore, searchStore } = useStore();
	const { exportPurpose, searchExport } = exportStore;
	const { searchInput, canHaveMore } = searchStore;
	const ref = useRef<HTMLInputElement>();

	const isFormInValid = () =>
		!exportPurpose.length ||
		(exportStore.exportMode !== ExportMode.Attachment &&
			!searchInput.exportTypes?.length);

	const [checkedItems, setCheckedItems] = React.useState<CheckBox[]>([
		{
			name: "Redacted Report",
			key: ExportType.Redacted.toString(),
			isChecked: true,
		},
		{
			name: "Un-Redacted Report",
			key: ExportType.Unredacted.toString(),
			isChecked: false,
		},
		{
			name: "Export Interaction/Authenticity Report",
			key: ExportType.Interaction.toString(),
			isChecked: false,
		},
		{
			name: "Export as Statement",
			key: ExportType.Statement.toString(),
			isChecked: false,
		},
	]);
	const [allChecked, setAllChecked] = React.useState(false);
	const [exportAll, setExportAll] = React.useState(false);

	useEffect(() => {
		// clear the form
		searchStore.SetSearchInput({
			...searchInput,
			exportTypes: [],
		} as any);
	}, []);

	useEffect(() => {
		searchStore.SetSearchInput({
			...searchInput,
			exportTypes: checkedItems
				.filter((k) => k.isChecked)
				.map((k) => +k.key as ExportType),
		} as SearchInput);
	}, [checkedItems]);

	const handleChange = (e: any) => {
		let itemName = e.target.name;
		let checked = e.target.checked;

		if (itemName === "checkAll") {
			setAllChecked(checked);
			setCheckedItems(
				checkedItems.map((item: any) => ({
					...item,
					isChecked: checked,
				}))
			);
		} else {
			const updatedList = checkedItems.map((item: any) =>
				item.key === itemName ? { ...item, isChecked: checked } : item
			);
			setCheckedItems(updatedList);
			setAllChecked(updatedList.every((item: any) => item.isChecked));
		}

		if (itemName !== ExportType.Redacted.toString() && checked) {
			alert(
				"Warning: you are about to export unredacted information which may be sensitive or protected. Please consider carefully whether you are required to export unredacted documents and consider exporting the redacted version only. If you do export or produce unredacted information, handle with care and against your organisation's policies/procedures."
			);
		}
	};

	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={true}
			onClose={() => exportStore.ToggleExportModal(false)}
		>
			<div className={classes.paper}>
				<Box flexDirection="column">
					{exportStore.exportMode !== ExportMode.Attachment && (
						<>
							{searchExport &&
								exportStore.GetAllSelected(
									searchStore.results
								) &&
								canHaveMore && (
									<>
										<Box>
											<Typography>
												Do you want to export all notes
												matching your search criteria?
											</Typography>
										</Box>
										<Box paddingBottom={2}>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														value={exportAll}
														checked={exportAll}
														onChange={(e) =>
															setExportAll(
																e.target.checked
															)
														}
													/>
												}
												label="Yes, export all"
											/>
										</Box>
									</>
								)}

							<Box>
								<Typography>
									What report do you want to export?
								</Typography>
							</Box>
							<Box display="block">
								{checkedItems.map((item: any) => (
									<FormControlLabel
										key={item.key}
										style={{ display: "block" }}
										control={
											<Checkbox
												color="primary"
												name={item.key}
												value={item.key}
												checked={item.isChecked}
												onChange={handleChange}
											/>
										}
										label={item.name}
									/>
								))}

								<FormControlLabel
									style={{ display: "block" }}
									control={
										<Checkbox
											color="primary"
											name="checkAll"
											checked={allChecked}
											onChange={handleChange}
										/>
									}
									label="All"
								/>
							</Box>
						</>
					)}
					<Box>
						<TextField
							inputRef={ref}
							fullWidth
							label="Why are you exporting this report?"
							onKeyPress={(ev) => {
								if (ev.key === "Enter" && !isFormInValid()) {
									ev.preventDefault();
									exportStore.ExportFiles(exportAll);
								}
							}}
							onChange={(e) =>
								exportStore.UpdateExportPurpose(e.target.value)
							}
						/>
					</Box>
					<Box>
						<Button
							onClick={() => exportStore.ToggleExportModal(false)}
						>
							Cancel
						</Button>
						<Button
							disabled={isFormInValid()}
							color="primary"
							onClick={() => exportStore.ExportFiles(exportAll)}
						>
							Export
						</Button>
					</Box>
				</Box>
			</div>
		</Modal>
	);
};

export default observer(ExportModal);
