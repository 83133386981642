import { Container, Box, Typography } from "@material-ui/core";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/styles";
import {
	theme,
	VerinoteBlueDark,
	VerinoteGreyFeature,
	VerinoteGreyToolbar,
} from "config/theme";
import { NewUserForm } from "../../components/NewUserForm";
import { AccessControlGroup } from "services";
import { acgNameConverter } from "../../utils/index";
import { useAuth } from "react-oidc-context";

const useStyles = makeStyles({
	table: {
		overflowY: "scroll",
		overflowX: "hidden",
	},
	topToolbar: {
		backgroundColor: VerinoteGreyToolbar,
		padding: theme.spacing(1),
		borderBottom: `2px solid ${VerinoteGreyFeature}`,
		animation:
			"swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275) both",
	},
	welcome: {
		paddingTop: "10vh",
		animation:
			"fade-in 400ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both",
	},
	notesList: {
		"&::-webkit-scrollbar": {
			width: "0.4em",
			borderRadius: "50px",
		},
		"&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
			webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
			borderRadius: "50px",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: VerinoteBlueDark,
			outline: `1px solid white`,
			borderRadius: "50px",
		},
		width: "35%",
		flexShrink: 1,
		display: "inline-block",
		overflow: "auto",
		paddingX: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginTop: -theme.spacing(1),
		animation:
			"slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
	},
	fab: {
		position: "absolute",
		bottom: theme.spacing(3),
		right: theme.spacing(2),
	},
	editNote: {
		padding: theme.spacing(1),
		width: "65%",
		display: "inline-block",
		overflowY: "scroll",
		overflowX: "hidden",
	},
});

const Profile = () => {
	const auth = useAuth();
	const { userStore, adminStore } = useStore();
	const user = userStore.currentUser;
	const accessControlGroup =
		AccessControlGroup[Number(user?.accessControlGroup)];

	const classes = useStyles();

	return (
		<>
			<Container className={classes.welcome}>
				<Container maxWidth="sm">
					<Typography variant="h4">Your Profile</Typography>

					<Box marginBottom="40px" marginTop="10px">
						<Typography variant="body1">
							<strong>Full Name:</strong>{" "}
							{`${user?.firstName} ${user?.lastName}`}
						</Typography>
						<Typography variant="body1">
							<strong>Email:</strong> {`${user?.email}`}
						</Typography>
						<Typography variant="body1">
							<strong>Current Access Control Group:</strong>{" "}
							{`${acgNameConverter(accessControlGroup)}`}
						</Typography>
						<Typography variant="body1">
							<strong>Organisation:</strong>{" "}
							{user.agency}
						</Typography>
						<Typography variant="body1">
							<strong>Position:</strong>{" "}
							{user.position}
						</Typography>
						<Typography variant="body1">
							<strong>Work Unit:</strong>{" "}
							{user.workGroup}
						</Typography>
						<Typography variant="body1">
							<strong>Employee ID</strong>{" "}
							{user.organisationIdentifier}
						</Typography>
						<Typography variant="body1">
							<strong>Mobile Number</strong>{" "}
							{user.phone}
						</Typography>
						<Box pt={2}>
							<Typography variant="body1">
								<strong>Update above details on</strong>{" "}
								<a
									href={`${auth?.settings?.authority}/home`}
									target="_blank"
								>
									Altia ID
								</a>
							</Typography>
						</Box>
					</Box>

					<NewUserForm
						handleSubmit={(values) => {
							const valuesTosend = {
								...values,
								...user,
								requestedAccessControlGroup: Number(
									values.requestedAccessControlGroup
								),
								workGroup: values.workUnit ?? user.workGroup,
								position: values.position ?? user.position,
								interfaceTimeZone:
									values.interfaceTimeZone ??
									user.interfaceTimeZone,
								organisationIdentifier:
									values.employeeId ??
									user.organisationIdentifier,
								agency: values.organisation ?? user.agency,
							} as any;
							adminStore.UpdateUser(valuesTosend);
						}}
						forProfile={true}
						initialValues={user}
					/>
				</Container>
			</Container>
		</>
	);
};

export default observer(Profile);
