import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Input,
	InputAdornment,
	makeStyles,
	MenuItem,
	Popover,
	Select,
	TextField,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import { SelectItem } from "components";
import { theme } from "config/theme";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	InformationManagementMarkerDTO,
	ProtectiveMarkingDTO,
	SearchType,
	UserSearchDTO,
} from "services";
import { useStore } from "store";
import {
	SEARCH_ANY_IMM_DLM_ID,
	SEARCH_ANY_PROTECTIVE_MARKER_ID,
	SearchInput,
} from "store/searchStore";
import { KeyEventIsEnter, MapMarkingsToSelect } from "utils";
import { fromQueryString, toQueryString } from "utils/searchParser";
import { KeywordFieldArray } from "../components/KeywordFieldArray";
import { DateRangePicker } from "./DateRangePicker";
import ConfirmDialog from "./modal/ConfirmDialog";

const useStyles = makeStyles({
	dropdown: {
		width: 400,
		padding: theme.spacing(3),
	},
	monitorBox: {
		color: "white",
		width: 110,
		marginRight: "-2px",
		borderRadius: "5px 0px 0px 5px",
		padding: 9,
	},
	monitorDropdown: {
		width: 110,
	},
	monitorToggle: {
		marginLeft: "-14px",
		marginRight: "-4px",
		cursor: "pointer",
		lineHeight: "38px",
		paddingLeft: "10px",
		paddingRight: "10px",
		width: "100px",
		textAlign: "center",
		borderRight: "solid 1px #051C2C",
	},
	isMonitoring: {
		animation: "glow .5s infinite alternate",
		color: "#bf0404",
	},
});

interface SearchControlProps {
	search: () => void;
	searchInput: SearchInput;
	setSearchInput: (input: SearchInput) => void;
	disabled: boolean;
	protectiveMarkings: ProtectiveMarkingDTO[];
	workunits: string[];
	managementMarkers: InformationManagementMarkerDTO[];
	getLocations: (value: string) => Promise<any[]>;
	searchUsers: (value: string) => Promise<any[]>;
	canGlobalSearch?: Boolean;
	canSearchByWorkgroup?: Boolean;
	globalSearchLabel: string;
	userSearchResult: UserSearchDTO[] | undefined;
	canDoUserSearch: boolean;
	isMonitoring?: boolean;
}
export const SearchControl = (props: SearchControlProps) => {
	const classes = useStyles();

	const {
		setSearchInput: setInput,
		searchInput: input,
		disabled,
		protectiveMarkings,
		workunits,
		managementMarkers,
		canGlobalSearch,
		globalSearchLabel,
		canSearchByWorkgroup,
		canDoUserSearch,
		userSearchResult,
		searchUsers,
		isMonitoring,
	} = props;

	const markings: SelectItem[] = MapMarkingsToSelect(
		protectiveMarkings,
		"Any Protective Marking",
		SEARCH_ANY_PROTECTIVE_MARKER_ID
	);
	const imm: SelectItem[] = MapMarkingsToSelect(
		managementMarkers,
		"Any IMM/DLM",
		SEARCH_ANY_IMM_DLM_ID
	);
	const searchRef = useRef<HTMLInputElement>();
	const searchBoxRef = useRef<any>();
	const monitorRef = useRef<any>();

	const focusField = () => {
		const node = searchRef.current;
		node?.focus();
	};

	const onUserSearch = (e: any, v: any) => searchUsers(v);

	useEffect(() => {
		window.setTimeout(() => {
			focusField();
		}, 100);
	}, []);

	const [search, setSearch] = useState<Partial<SearchInput>>(input);

	const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(
		false
	);

	const [confirmCancel, setConfirmCancel] = useState(false);

	const history = useHistory();

	const isInSearchMode = history.location.pathname === "/search";

	const { searchStore, noteStore } = useStore();

	const changeMonitorState = () => {
		if (search.monitor && isMonitoring) {
			// stop monitoring at the same time
			searchStore.StopMonitoring();
		}

		const newMonitorState = !search.monitor;
		setSearch({
			...search,
			monitor: newMonitorState,
			isMonitor: newMonitorState,
		});
	};

	const doSearch = (advanced?: boolean) => {
		if (noteStore.editing) {
			setConfirmCancel(true);
			return;
		}

		const searchReq = advanced
			? {
					...input,
					...search,
					// convert the form into the single text line
					parsedQuery: toQueryString(search),
			  }
			: {
					...input,
					...search,
					// parse search text into the form
					...fromQueryString(search.parsedQuery || ""),
			  };

		setSearch(searchReq);

		setInput(searchReq as SearchInput);

		props.search();

		if (history.location.pathname !== "/search") history.push("/search");
	};

	const onShowAdvancedSearch = () => {
		const parsed = fromQueryString(search.parsedQuery || "");

		// copy the value from
		setSearch({
			...search,
			...parsed,
			parsedQuery: search.parsedQuery,
		});

		setShowAdvancedSearch(true);
	};

	const clearSearch = () => {
		setSearch({
			searchTerm: "",
			address: "",
			protectiveMarkingId: SEARCH_ANY_PROTECTIVE_MARKER_ID,
			informationManagementMarkerId: SEARCH_ANY_IMM_DLM_ID,
			urn: "",
			tags: [],
			attachment: "",
			dateCreated: false,
			isPrivate: false,
			searchType: SearchType.Standard,
			workUnit: "",
			userName: "",
		});
	};

	const searchOnEnter = (e: any) => KeyEventIsEnter(e) && doSearch(true);

	const AdvancedSearchForm = () => {
		return (
			<div className={classes.dropdown} style={{ minWidth: "700px" }}>
				<Box display="flex" flexDirection="column">
					<Box paddingBottom="8px">
						<TextField
							fullWidth
							label="Has the words"
							onKeyDown={searchOnEnter}
							value={search.searchTerm || ""}
							onChange={(v) =>
								setSearch({
									...search,
									searchTerm: v ? v.target.value : "",
								})
							}
						></TextField>
					</Box>

					<Box>
						<TextField
							fullWidth
							label="Declared Location"
							onKeyDown={searchOnEnter}
							value={search.address || ""}
							onChange={(v) =>
								setSearch({
									...search,
									address: v ? v.target.value : "",
								})
							}
						></TextField>
					</Box>

					<Box paddingBottom="8px">
						<DateRangePicker
							start={
								search.searchStartDate
									? moment(search.searchStartDate)
									: null
							}
							end={
								search.searchEndDate
									? moment(search.searchEndDate)
									: null
							}
							updateStart={(d) =>
								setSearch({
									...search,
									searchStartDate: d,
								})
							}
							updateEnd={(d) =>
								setSearch({
									...search,
									searchEndDate: d,
								})
							}
						/>
					</Box>

					<Box
						paddingBottom="8px"
						paddingTop="8px"
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<Select
							input={<Input fullWidth />}
							name="ProtectiveMarkingSelect"
							defaultValue={"null"}
							variant="outlined"
							value={search.protectiveMarkingId}
							onChange={(e) => {
								setSearch({
									...search,
									protectiveMarkingId: Number(e.target.value),
								});
							}}
							displayEmpty
							color="secondary"
						>
							{markings.map((i) => (
								<MenuItem value={i.value} key={i.value}>
									{i.key}
								</MenuItem>
							))}
						</Select>
						<Select
							name="IMMSelect"
							defaultValue={"null"}
							variant="outlined"
							style={{ marginLeft: "20px" }}
							value={search.informationManagementMarkerId}
							input={<Input fullWidth />}
							onChange={(e) => {
								setSearch({
									...search,
									informationManagementMarkerId: Number(
										e.target.value
									),
								});
							}}
							displayEmpty
							color="secondary"
						>
							{imm.map((i) => (
								<MenuItem value={i.value} key={i.value}>
									{i.key}
								</MenuItem>
							))}
						</Select>
					</Box>

					<Box paddingBottom="8px">
						<TextField
							fullWidth
							label="Unique Reference Number"
							onKeyDown={searchOnEnter}
							value={search.urn || ""}
							onChange={(v) =>
								setSearch({
									...search,
									urn: v ? v.target.value : "",
								})
							}
						></TextField>
					</Box>

					<Box paddingTop="8px">
						<Formik
							onSubmit={(v) => {
								setSearch({
									...search,
									tags: v.tags,
								});
							}}
							initialValues={search}
						>
							{({ ...e }) => (
								<Form onClickCapture={() => e.submitForm()}>
									<KeywordFieldArray
										name="tags"
										editing={true}
										canEdit={true}
										title=""
										placeholder="Tags"
										values={search.tags}
									/>
								</Form>
							)}
						</Formik>
					</Box>

					<Box paddingBottom="8px">
						<TextField
							fullWidth
							label="Attachment file name"
							onKeyDown={searchOnEnter}
							value={search.attachment}
							onChange={(v) => {
								setSearch({
									...search,
									attachment: v ? v.target.value : "",
								});
							}}
						></TextField>
					</Box>

					<Box display="flex" flexDirection="row" paddingBottom="8px">
						<FormControlLabel
							control={
								<Checkbox
									disabled={disabled}
									color="primary"
									onClick={() =>
										setSearch({
											...search,
											dateCreated: !search.dateCreated,
										})
									}
									checked={search.dateCreated || false}
								/>
							}
							label="Date Created"
						/>
						<FormControlLabel
							control={
								<Checkbox
									disabled={disabled}
									color="primary"
									onClick={() =>
										setSearch({
											...search,
											isPrivate: !search.isPrivate,
										})
									}
									checked={search.isPrivate}
								/>
							}
							label="Private"
						/>

						{canGlobalSearch && (
							<FormControlLabel
								control={
									<Checkbox
										disabled={disabled}
										color="primary"
										onClick={() =>
											setSearch({
												...search,
												workUnit:
													search.searchType ===
													SearchType.Global
														? ""
														: search.workUnit,
												searchType:
													search.searchType ===
													SearchType.Global
														? SearchType.Standard
														: SearchType.Global,
											})
										}
										checked={
											search.searchType ===
											SearchType.Global
										}
									/>
								}
								label={globalSearchLabel}
							/>
						)}
					</Box>
					{canSearchByWorkgroup &&
						search.searchType === SearchType.Global && (
							<Box paddingBottom="8px">
								<Autocomplete
									options={workunits}
									value={search.workUnit || ""}
									onChange={(e, v) =>
										setSearch({
											...search,
											workUnit: v,
										})
									}
									getOptionLabel={(option) => option}
									renderInput={(params) => (
										<TextField
											{...params}
											style={{
												width: "100%",
											}}
											label="Work Unit"
										/>
									)}
								/>
							</Box>
						)}

					{canDoUserSearch &&
						search.searchType === SearchType.Global && (
							<Box paddingBottom="8px">
								<Autocomplete
									options={userSearchResult}
									value={search?.userName || ""}
									getOptionLabel={(option) => {
										return option.firstName
											? `${option.firstName} ${option.lastName} [${option.organisationIdentifier}]`
											: option;
									}}
									onInputChange={onUserSearch}
									onChange={(e, v: UserSearchDTO) =>
										setSearch({
											...search,
											userName: v?.email,
										})
									}
									renderInput={(params) => (
										<TextField
											{...params}
											style={{
												width: "100%",
											}}
											label="User"
										/>
									)}
								/>
							</Box>
						)}

					<Box
						marginTop="8px"
						paddingBottom="8px"
						display="flex"
						justifyContent="flex-end"
					>
						<Button onClick={clearSearch}>Clear Filter</Button>
						<Button
							color="primary"
							style={{ marginLeft: "10px" }}
							onClick={() => {
								doSearch(true);
								setShowAdvancedSearch(false);
							}}
							variant="contained"
						>
							Searching
						</Button>
					</Box>
				</Box>
			</div>
		);
	};

	return (
		<>
			<ConfirmDialog
				open={confirmCancel}
				title="You have an unsaved entry"
				content="Please finalise your entry and save before navigating away."
				okClick={() => setConfirmCancel(false)}
				cancelClick={() => setConfirmCancel(false)}
			></ConfirmDialog>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				flexGrow={1}
			>
				<style>
					{`@keyframes glow {
					to {
						text-shadow: 0 0 10px red;
					}
				}`}
				</style>
				<div ref={searchBoxRef}>
					<Box
						display={!isInSearchMode ? "none" : "flex"}
						flexGrow={1}
						minWidth={"700px"}
						bgcolor="white"
						borderRadius="5px"
					>
						{/* main search box */}
						<TextField
							inputRef={searchRef}
							disabled={disabled}
							placeholder="Search...."
							onKeyDown={(e) => {
								if (KeyEventIsEnter(e)) {
									console.log("do search");
									doSearch();
									e.preventDefault();
								}
							}}
							fullWidth
							value={search.parsedQuery || ""}
							onChange={(e) =>
								setSearch({
									...search,
									parsedQuery: e.target.value,
								})
							}
							size="small"
							variant="outlined"
							InputProps={{
								startAdornment: (
									<>
										<InputAdornment position="start">
											<span
												ref={monitorRef}
												className={classNames({
													[classes.monitorToggle]: true,
													[classes.isMonitoring]: isMonitoring,
												})}
												title={
													search.monitor
														? isMonitoring
															? "Stop monitoring"
															: "Turn on search mode"
														: "Turn on Monitor mode"
												}
												onClick={changeMonitorState}
											>
												{search.monitor
													? isMonitoring
														? "Monitoring"
														: "Monitor"
													: "Search"}
											</span>
										</InputAdornment>
										<InputAdornment position="start">
											<IconButton
												disabled={disabled}
												onClick={() => doSearch(false)}
												size="small"
											>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									</>
								),
								endAdornment: (
									<InputAdornment position="end">
										<FilterListIcon
											onClick={onShowAdvancedSearch}
											style={{
												marginLeft: "0.2rem",
												marginRight: "0.5rem",
												cursor: "pointer",
											}}
										/>
									</InputAdornment>
								),
							}}
						/>

						{/* advanced search */}
						<Popover
							anchorEl={searchBoxRef.current}
							open={showAdvancedSearch}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							onClose={() => setShowAdvancedSearch(false)}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
						>
							{AdvancedSearchForm()}
						</Popover>
					</Box>

					{/* place holder for search bar */}
					<Box
						display={isInSearchMode ? "none" : "flex"}
						flexGrow={1}
						minWidth={"700px"}
						borderRadius="5px"
						justifyContent="center"
						onClick={() => {
							history.push("/search");
							setTimeout(() => {
								searchRef.current?.focus();
							}, 0);
						}}
						style={{
							lineHeight: "44px",
							fontSize: "1rem",
							backgroundColor: "#e6e8e9",
							cursor: "pointer",
							color: "black",
						}}
					>
						Search
					</Box>
				</div>
			</Box>
		</>
	);
};
