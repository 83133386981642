import { Box, makeStyles, Paper } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ExportControl } from "components";
import classNames from "classnames";
import DatePicker from "components/DatePicker";
import { TRANSITION_TIME, VerinoteGreyToolbar } from "config/theme";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { useStore } from "store";
import { APPBAR_HEIGHT } from "utils";

const useStyles = makeStyles({
	topToolbar: {
		backgroundColor: VerinoteGreyToolbar,
	},
	loading: {
		opacity: 1,
		height: 4,
		position: "absolute",
		width: "100%",
		top: APPBAR_HEIGHT,
		zIndex: 100000,
	},
	searchToolbar: {
		padding: 7,
	},
	animateIn: {
		animation:
			"swing-in-top-fwd 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275) both",
		animationDelay: `${TRANSITION_TIME - 100}ms`,
	},
	animateOut: {
		animation: `slide-out-top ${
			TRANSITION_TIME - 100
		}ms cubic-bezier(.55,.085,.68,.53) both`,
		animationDelay: "50ms",
	},
});

interface AppToolbarProps {
	currentRoute: string;
	changeRoute: boolean;
}

const AppToolbar = ({ currentRoute, changeRoute }: AppToolbarProps) => {
	const {
		noteStore,
		exportStore,
		searchStore,
		applicationStore: { CurrentLoading },
	} = useStore();

	const classes = useStyles();
	const { addedNoteCount } = noteStore;
	const showControls = currentRoute === "Home" || currentRoute === "Search";

	return (
		<div className="w-100">
			{CurrentLoading && <LinearProgress className={classes.loading} />}
			{showControls && (
				<Paper
					className={classNames(classes.topToolbar, {
						[classes.animateOut]: changeRoute,
						[classes.animateIn]: !changeRoute,
						[classes.searchToolbar]: false,
					})}
					square
				>
					{currentRoute === "Home" && (
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
						>
							<ExportControl
								disabled={
									noteStore.editing ||
									!(
										noteStore.notes &&
										noteStore.notes.length > 0
									)
								}
								toggleSelectAll={() =>
									exportStore.SelectAll(noteStore.notes)
								}
								allSelected={exportStore.GetAllSelected(
									noteStore.notes
								)}
								notesSelected={
									exportStore.selectedNotes.length > 0
								}
								active={exportStore.isExporting}
								toggle={() => exportStore.ToggleIsExporting()}
								confirm={() =>
									exportStore.ToggleExportModal(false)
								}
							/>
							<DatePicker
								disabled={noteStore.editing}
								handleDateChange={(date) =>
									noteStore.SetSessionDate(moment(date))
								}
								handleMonthChange={(date: Date) =>
									noteStore.ChangeSelectedMonth(date)
								}
								selectedDays={noteStore.selectedDays}
								noteCount={addedNoteCount}
								sessionDate={noteStore.sessionDate}
								showArrows
							/>
						</Box>
					)}

					{currentRoute === "Search" && (
						<Box
							display="flex"
							flexDirection="row"
							height={56}
							justifyContent="space-between"
						>
							<Box display="flex" minWidth="120px">
								<ExportControl
									disabled={
										!(
											searchStore.results &&
											searchStore.results.length > 0
										)
									}
									toggleSelectAll={() =>
										exportStore.SelectAll(
											searchStore.results
										)
									}
									allSelected={exportStore.GetAllSelected(
										searchStore.results
									)}
									notesSelected={
										exportStore.selectedNotes.length > 0
									}
									active={exportStore.isExporting}
									toggle={() =>
										exportStore.ToggleIsExporting()
									}
									confirm={() =>
										exportStore.ToggleExportModal(true)
									}
								/>
							</Box>
						</Box>
					)}
				</Paper>
			)}
		</div>
	);
};

export default observer(AppToolbar);
