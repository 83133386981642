import React from "react";
import DatePicker from "./DatePicker";
import { Box } from "@material-ui/core";
import { Moment } from "moment-timezone";

interface DateRangePickerProps {
	updateStart: (date?: Date) => void;
	updateEnd: (date?: Date) => void;
	start?: Moment | null;
	end?: Moment | null;
}

export const DateRangePicker = (props: DateRangePickerProps) => {
	const { updateStart, updateEnd, end, start } = props;

	return (
		<Box display="flex" flexDirection="row" justifyContent="space-between">
			<DatePicker
				label="Start Date"
				fullWidth
				handleDateChange={(date) => {
					updateStart(date);
				}}
				maxDate={end ? end.toDate() : undefined}
				handleMonthChange={(e) => {}}
				selectedDays={{} as { [key: string]: number }}
				noteCount={0}
				sessionDate={start ?? null}
				clear={() => updateStart()}
			/>

			<Box marginLeft="20px" width="100%">
				<DatePicker
					label="End Date"
					fullWidth
					handleDateChange={(date) => {
						updateEnd(date);
					}}
					minDate={start ? start.toDate() : undefined}
					handleMonthChange={() => {}}
					selectedDays={{} as { [key: string]: number }}
					noteCount={0}
					sessionDate={end ?? null}
					clear={() => updateEnd()}
				/>
			</Box>
		</Box>
	);
};
