import React, { useEffect, PropsWithChildren, useState } from "react";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import { NewUserForm } from "../../components/NewUserForm";
import {
	Typography,
	Container,
	Checkbox,
	FormControlLabel,
	Box,
	Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CreateUserInput } from "store/userStore";
import moment from "moment-timezone";
import { policy, terms, eula } from "./documents";
import MarkdownRenderer from "react-markdown-renderer";

const useStyles = makeStyles({
	container: {},
	disabled: { marginTop: "5vh" },
	content: {
		animation:
			"fade-in 800ms cubic-bezier(0.390, 0.575, 0.565, 1.000) both",
		animationDelay: "200ms",
		padding: "2rem 0",
	},
	terms: {
		maxHeight: "30vh",
		overflowY: "scroll",
		borderStyle: "solid",
		borderRadius: "10px",
		borderColor: "#CFCFCF",
	},
	termsHeader: {
		marginTop: "20px",
	},
});

const NewUser = (props: PropsWithChildren<{}>) => {
	const { userStore, authStore } = useStore();
	const styles = useStyles();
	const { currentUser, isEnabled, loading } = userStore;

	const submit = (input: CreateUserInput) => {
		userStore.createNewUser(input);
	};

	useEffect(() => {
		if (!loading && !currentUser) {
			window.setTimeout(() => {
				if (!currentUser) {
					userStore.getCurrentUser();
				}
			}, 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, loading]);

	if (!isEnabled && !loading) {
		return (
			<div className={styles.disabled}>
				<div className={styles.content}>
					<Typography variant="h4" color="error" align="center">
						Not Logged In
					</Typography>

					<Container maxWidth="sm">
						<Typography
							variant="h6"
							color="textSecondary"
							align="center"
						>
							Looks like your session has expired. You should be
							automatically redirected to the login page shortly.
							If you are not automatically redirected after a few
							seconds please refresh your browser.
						</Typography>
					</Container>
				</div>
			</div>
		);
	}

	if (!currentUser || !currentUser.id) {
		const userInfo = authStore.decodedToken;
		const initialValues = {
			interfaceTimeZone: moment.tz.guess(),
			phone: userInfo["mobileNumber"],
			otp: "",
			// get default value from the jwt token for backward compatibility
			position: userInfo["position"],
			agency: userInfo["company"],
			workGroup: userInfo["workUnit"],
			organisationIdentifier: userInfo["employeeId"],
			requestedAccessControlGroup: 0
		};

		return (
			<div className={styles.container}>
				<div className={styles.content}>
					<Typography variant="h4" color="textPrimary" align="center">
						Welcome to Capture
					</Typography>
					<Typography
						variant="h6"
						color="textSecondary"
						align="center"
					>
						Create Your Profile
					</Typography>
					<Typography color="textSecondary" align="center">
						All fields are mandatory
					</Typography>
					<Container maxWidth="xs">
						<NewUserForm
							handleSubmit={(values: any) => {
								const valuesTosend = {
									phone: values.phone,
									otp: values.otp,
									requestedAccessControlGroup:
										+values.requestedAccessControlGroup,
									workUnit:
										values.workUnit ??
										initialValues.workGroup,
									position:
										values.position ??
										initialValues.position,
									interfaceTimeZone:
										values.interfaceTimeZone ??
										initialValues.interfaceTimeZone,
									employeeId:
										values.employeeId ??
										initialValues.organisationIdentifier,
									organisation:
										values.organisation ??
										initialValues.agency,
								} as any;

								submit(valuesTosend);
							}}
							initialValues={initialValues}
						/>
					</Container>
				</div>
			</div>
		);
	}

	return <>{props.children}</>;
};

export default observer(NewUser);
