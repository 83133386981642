import {
	Badge,
	Container,
	Paper,
	Tab,
	Tabs,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
	theme,
	VerinoteBlueDark,
	VerinoteGreyFeature,
	VerinoteGreyToolbar,
} from 'config/theme';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from 'store';
import { Can } from '../../store/rbac';
import { AuditViewControls, UserControls } from './components';

const useStyles = makeStyles({
	table: {
		overflowY: 'scroll',
		overflowX: 'hidden',
	},
	topToolbar: {
		backgroundColor: VerinoteGreyToolbar,
		padding: theme.spacing(1),
		borderBottom: `2px solid ${VerinoteGreyFeature}`,
		animation:
			'swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275) both',
	},
	mainContent: {
		paddingTop: '5vh',
		paddingBottom: '4vh',

		animation: 'fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
	},
	notesList: {
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.2)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.2)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: VerinoteBlueDark,
			outline: `1px solid white`,
		},
		width: '35%',
		flexShrink: 1,
		display: 'inline-block',
		overflow: 'auto',
		paddingX: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginTop: -theme.spacing(1),
		animation:
			'slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing(3),
		right: theme.spacing(2),
	},
	editNote: {
		padding: theme.spacing(1),
		width: '65%',
		display: 'inline-block',
		overflowY: 'scroll',
		overflowX: 'hidden',
	},
});

const Admin = () => {
	// hooks
	const { adminStore, userStore } = useStore();
	const {
		searchAudits,
		exportAudits,
		users,
		AccessActionRequiredCount,
	} = adminStore;
	const { isAdmin, userRole } = userStore;
	const classes = useStyles();
	const [activeTab, setActiveTab] = React.useState(0);

	useEffect(() => {
		if (activeTab === 0 && isAdmin) {
			adminStore.GetUsers();
		}
	}, [activeTab, isAdmin, adminStore]);

	const AuditTabControl = () => (
		<Paper square>
			<Tabs
				centered
				value={activeTab}
				indicatorColor="primary"
				textColor="primary"
				onChange={(_, newValue: number) => setActiveTab(newValue)}
				aria-label="Admin-options"
			>
				<Tab label="Export Logs" />
				<Tab label="Search Logs" />
			</Tabs>
		</Paper>
	);

	const AdminTabControl = () => (
		<Paper square>
			<Tabs
				centered
				value={0}
				indicatorColor="primary"
				textColor="primary"
				aria-label="Admin-options"
			>
				<Tab
					label={
						<>
							{AccessActionRequiredCount > 0 ? (
								<Badge
									style={{ paddingRight: theme.spacing(1) }}
									badgeContent={AccessActionRequiredCount}
									color="error"
								>
									User Management
								</Badge>
							) : (
								'User Management'
							)}
						</>
					}
				/>
			</Tabs>
		</Paper>
	);

	return (
		<>
			<Can
				role={userRole}
				action="UserManagement"
				yes={() => <AdminTabControl />}
			/>
			<Can
				role={userRole}
				action="Audits"
				yes={() => <AuditTabControl />}
			/>

			<Container className={classes.mainContent}>
				<Typography variant="h5" align="center">
					Admin Portal
				</Typography>

				<Can
					role={userRole}
					action="UserManagement"
					yes={() => (
						<UserControls
							AccessActionRequiredCount={
								AccessActionRequiredCount
							}
							users={users}
							updateUser={(user) => adminStore.UpdateUser(user)}
						/>
					)}
				/>

				<Can
					role={userRole}
					action="Audits"
					yes={() => (
						<AuditTabs
							activeTab={activeTab}
							searchAudits={searchAudits}
							exportAudits={exportAudits}
						/>
					)}
				/>
			</Container>
		</>
	);
};

export default observer(Admin);

function AuditTabs({ activeTab, searchAudits, exportAudits }: any) {
	const { adminStore, userStore } = useStore();
	const { isValidUser } = userStore;

	useEffect(() => {
		if (!isValidUser) return;
		if (activeTab === 0) {
			adminStore.GetExportAudit();
		}
		if (activeTab === 1) {
			adminStore.GetSearchAudit();
		}
	}, [activeTab, adminStore, isValidUser]);

	return (
		<>
			{activeTab === 0 && (
				<AuditViewControls exportAudits={exportAudits} />
			)}
			{activeTab === 1 && (
				<>
					<AuditViewControls search exportAudits={searchAudits} />
				</>
			)}
		</>
	);
}
