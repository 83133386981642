import { Box, Button, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import { useStyles } from './index';


interface ModalProps {
	open: boolean;
	onClose: any;
}
const SwitchTenantModal = ({ open, onClose }: ModalProps) => {
	const classes = useStyles();
	return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={open}
		>
			<div className={classes.paper}>
				<Box flexDirection="row">
					<Typography>
						This window will be closed and you will be required to fill in the URL again.
					</Typography>
					<Typography>
						Do you want to continue?
					</Typography>
					<Box paddingTop="1rem" className="text-right">
						<Button variant="contained" size="small" color="primary" onClick={() => onClose(true)}>Ok</Button>
						<Button variant="contained" size="small" color="secondary" onClick={() => onClose(false)}>Cancel</Button>
					</Box>
				</Box>
			</div>
		</Modal>
	);
};

export default SwitchTenantModal;
