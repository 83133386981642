import MomentUtils from "@date-io/moment";
import { Badge, Box, IconButton, InputAdornment } from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ClearIcon from "@material-ui/icons/Clear";
import {
	KeyboardDatePicker as DatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import makeStyles from "@material-ui/styles/makeStyles";
import { theme, VerinoteBlue } from "config/theme";
import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import { makeJSDateObject } from "utils";

interface DatePickerProps {
	handleDateChange: (date: Date) => void;
	handleMonthChange: (date: Date) => void;
	noteCount: number;
	sessionDate: Moment | null;
	selectedDays: { [key: string]: number };
	label?: string;
	showArrows?: boolean;
	clear?: () => void;
	maxDate?: Date;
	minDate?: Date;
	disabled?: boolean;
	fullWidth?: boolean;
}
const styles = makeStyles({
	button: {
		"&:hover": {
			backgroundSize: 0.5,
		},
	},
});

const buttonPadding = 1;

const VerinoteDatePicker = ({
	sessionDate,
	handleMonthChange,
	selectedDays,
	showArrows,
	clear,
	disabled,
	handleDateChange,
	label,
	minDate,
	fullWidth,
}: DatePickerProps) => {
	// hooks
	const classes = styles();

	const changeMonth = async (
		date: MaterialUiPickersDate | Date
	): Promise<void> => {
		handleMonthChange(makeJSDateObject(date));
	};

	useEffect(() => {
		if (sessionDate) {
			handleMonthChange(makeJSDateObject(sessionDate));
		}
	}, [sessionDate]);

	// handlers
	const handleCurrentDateChange = (date: MaterialUiPickersDate | Moment) => {
		if (date?.isValid) {
			handleDateChange(makeJSDateObject(date));
		}
	};

	const disableNextDay = () => {
		if (sessionDate) {
			const date = moment(sessionDate).clone().add(1, "days");
			const today = moment();
			return date.isAfter(today);
		}
		return false;
	};

	const changeDateByDays = (number: number) => {
		if (sessionDate) {
			const date = moment(sessionDate).clone().add(number, "days");
			handleCurrentDateChange(date);
		}
	};

	const date = sessionDate?.toDate();

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Box
				display="flex"
				flexDirection="row"
				width={fullWidth ? "100%" : "unset"}
			>
				{showArrows && (
					<Box alignSelf="center">
						<IconButton
							disableFocusRipple
							disableRipple
							disableTouchRipple
							className={classes.button}
							disabled={disabled}
							color="primary"
							onClick={() => changeDateByDays(-1)}
						>
							<Badge
								color="secondary"
								style={{
									paddingRight: theme.spacing(buttonPadding),
									paddingTop: theme.spacing(buttonPadding),
								}}
								overlap="circle"
								badgeContent={
									selectedDays[
										`${date ? date.getDate() - 1 : ""}`
									]
								}
							>
								<ArrowLeftIcon />
							</Badge>
						</IconButton>
					</Box>
				)}
				<DatePicker
					disabled={disabled}
					fullWidth={fullWidth}
					size="small"
					autoOk={true}
					okLabel=""
					label={label}
					style={{ marginTop: 14 }}
					keyboardIcon={
						<Badge overlap="circle" badgeContent={``}>
							<CalendarTodayIcon color="primary" />
						</Badge>
					}
					minDate={minDate}
					leftArrowIcon={<ArrowLeftIcon color="primary" />}
					rightArrowIcon={<ArrowRightIcon color="primary" />}
					color="primary"
					value={sessionDate}
					onChange={handleCurrentDateChange}
					onMonthChange={changeMonth}
					renderDay={(
						day,
						selectedDay,
						isInCurrentMonth,
						dayComponent
					) => {
						const date = makeJSDateObject(day);
						const isSelected =
							isInCurrentMonth &&
							selectedDays[date.getDate().toString()];

						return (
							<Badge
								overlap="circle"
								color="error"
								max={99}
								badgeContent={
									isSelected
										? `${selectedDays[date.getDate()]}`
										: undefined
								}
							>
								{dayComponent}
							</Badge>
						);
					}}
					InputProps={{
						endAdornment: (
							<>
								{clear && sessionDate && (
									<InputAdornment position="end">
										<IconButton
											onClick={(e) => {
												if (clear) {
													e.stopPropagation();
													clear();
												}
											}}
										>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								)}
							</>
						),
					}}
				/>
				{showArrows && (
					<Box alignSelf="center">
						<IconButton
							disabled={disableNextDay()}
							color="primary"
							onClick={() => changeDateByDays(1)}
						>
							<Badge
								color="secondary"
								style={{
									paddingLeft: theme.spacing(buttonPadding),
									paddingTop: theme.spacing(buttonPadding),
									color: VerinoteBlue,
								}}
								overlap="circle"
								badgeContent={
									selectedDays[
										`${date ? date.getDate() + 1 : ""}`
									]
								}
								anchorOrigin={{
									horizontal: "left",
									vertical: "top",
								}}
							>
								<ArrowRightIcon />
							</Badge>
						</IconButton>
					</Box>
				)}
			</Box>
		</MuiPickersUtilsProvider>
	);
};

export default VerinoteDatePicker;
