import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	SnackbarContent,
	Snackbar,
	Typography,
	Button,
} from '@material-ui/core';
import { useStore } from 'store';
import { red } from '@material-ui/core/colors';
import { observer } from 'mobx-react-lite';
import { theme } from 'config/theme';

const useStyles = makeStyles({
	error: {
		backgroundColor: red[600],
	},
	success: {
		backgroundColor: 'rgba(5, 131, 242, 0.8)',
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(6),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
});

export interface Props {
	message?: any;
	onClose?: () => void;
	error: boolean;
}

const SnackbarContentWrapper = (props: Props): JSX.Element => {
	const styles = useStyles();
	const { message, onClose, error } = props;
	const messageRender = (
		<span id="client-snackbar" className={styles.message}>
			{message}
		</span>
	);
	return (
		<SnackbarContent
			className={error ? styles.error : styles.success}
			aria-describedby="client-snackbar"
			message={messageRender}
			action={[
				<Button key="close" aria-label="close" onClick={onClose}>
					<Typography color="textSecondary" variant="subtitle2">
						Dismiss
					</Typography>
				</Button>,
			]}
		/>
	);
};

const StatusSnackbar = (): JSX.Element => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('true');
	const [error, setError] = useState(true);
	const onClose = (): void => setOpen(false);

	const { applicationStore, userStore } = useStore();

	const openSnackbar = (text: string): void => {
		setMessage(text);
		setOpen(true);
	};

	useEffect(() => {
		if (applicationStore.CurrentError) {
			setError(true);
			openSnackbar(applicationStore.CurrentError);
			applicationStore.clearError();
		}
	}, [applicationStore.CurrentError, applicationStore]);

	useEffect(() => {
		if (applicationStore.CurrentSuccess) {
			setError(false);
			openSnackbar(applicationStore.CurrentSuccess);
			applicationStore.clearSuccess();
		}
	}, [applicationStore.CurrentSuccess, applicationStore]);

	if (userStore.isEnabled) {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				open={open}
				autoHideDuration={5000}
				onClose={onClose}
			>
				<SnackbarContentWrapper
					error={error}
					message={message}
					onClose={onClose}
				/>
			</Snackbar>
		);
	}
	return <></>;
};

export default observer(StatusSnackbar);
