import React, { useEffect, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import {
  Button,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./index";

const PreviewOtpModal = () => {
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>();
  const { previewStore } = useStore();
  const { previewPurpose } = previewStore;

  const isFormInValid = () => !previewPurpose.length
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={true}
    >
      <div className={classes.paper}>
        <Box flexDirection="column">
          <h2>Download or Preview Attachments</h2>
          <Typography>Due to security reasons, quick preview is disabled by default. 
            If you need to access the original evidence, please use Export button. 
            To enabled quick preview for the current session, please provide the reason below
          </Typography>
          <Box>
            <TextField
              inputRef={ref}
              fullWidth
              label="Reason to turn on Quick Preview"
              onChange={(e) =>
                previewStore.UpdatePreviewPurpose(e.target.value)
              }
            />
          </Box>
          <Box>
            <Button
              onClick={() => previewStore.TogglePreviewModal()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={isFormInValid()}
              onClick={() => previewStore.RequestPreview()}
            >
              Turn on preview
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default observer(PreviewOtpModal);
