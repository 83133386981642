import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { FieldArray } from "formik";
import { MediaFileControl, FileDisplay } from "components";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import { VerinoteGreyFeature } from "config/theme";
import { AttachmentDTO } from "services";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-evenly",
			overflow: "hidden",
			// backgroundColor: "red",
			// borderColor: VerinoteGreyFeature,
			// borderStyle: "solid",
		},
		gridList: {
			width: "auto",
			margin: "0px !important",
			maxHeight: 450,
			justifyContent: "space-evenly",
		},
		gridListlarge: {
			margin: "0px !important",
			width: "auto",
			justifyContent: "space-evenly",
		},
		title: {
			color: theme.palette.primary.light,
		},
		titleBar: {
			background:
				"linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
		},
	})
);

interface FileFieldArrayProps {
	files: AttachmentDTO[];
	editing: boolean;
	canEdit: boolean;
	save: () => void;
	large?: boolean;
	select?: (e: string) => void;
	selected?: string[];
	exporting?: boolean;
	preUpload?: (fileBlob: Blob, fileName: string) => Promise<void>;
}

export const FileArray = forwardRef(
	(
		{
			files,
			editing,
			canEdit,
			selected,
			select,
			save,
			large,
			exporting,
			preUpload,
		}: FileFieldArrayProps,
		ref
	) => {
		const mediaFileControlRef = useRef(null);
		const classes = useStyles();
		useImperativeHandle(ref, () => ({
			uploadFile() {
				return (mediaFileControlRef?.current as any)?.uploadFile();
			},
		}));
		return (
			<FieldArray
				name="attachments"
				render={(arrayHelpers) => (
					<>
						{editing && canEdit && (
							<MediaFileControl
								ref={mediaFileControlRef}
								submit={save}
								preUpload={preUpload}
								addFile={arrayHelpers.push}
							/>
						)}

						{files.length > 0 && (
							<div className={classes.root}>
								<GridList
									className={
										large
											? classes.gridListlarge
											: classes.gridList
									}
									cols={2.5}
								>
									{files &&
										files.map((file, index) => (
											<FileDisplay
												exporting={exporting}
												editing={editing}
												key={index}
												selected={selected?.includes(
													file.attachmentGuid?.toString() ||
														""
												)}
												file={file as AttachmentDTO}
												select={select}
											/>
										))}
								</GridList>
							</div>
						)}
					</>
				)}
			/>
		);
	}
);
