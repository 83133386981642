import {
	Box,
	Button,
	Checkbox,
	FormControl,
	Input,
	MenuItem,
	Select,
} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { SelectItem } from 'components';
import React from 'react';
import { AccessControlGroup, UserDTO } from '../../../services';
import { acgNameConverter } from '../../../utils/index';

interface UpdateUserFormProps {
	user: UserDTO;
	update: (user: UserDTO) => void;
	controlGroups: SelectItem[];
	handleShowLog: (id: string) => Promise<void>;
}
export const UpdateUserForm = (props: UpdateUserFormProps) => {
	const { user, controlGroups, handleShowLog } = props;
	const requested =
		AccessControlGroup[Number(user.requestedAccessControlGroup)];
	const actionRequired =
		user.accessControlGroup !== user.requestedAccessControlGroup;

	return (
		<TableRow>
			<TableCell>
				<Checkbox
					color="primary"
					checked={!user.disabled}
					onChange={(e) => {
						const u: UserDTO = {
							...user,
							disabled: !user.disabled,
						} as UserDTO;
						props.update(u);
					}}
				></Checkbox>
			</TableCell>
			<TableCell>{user.firstName}</TableCell>
			<TableCell>{user.lastName}</TableCell>
			<TableCell>{user.email}</TableCell>
			<TableCell>{user.workGroup}</TableCell>
			<TableCell>
				<Box>
					{actionRequired &&
						`${
							user.firstName
						} has requested to be ${acgNameConverter(requested)}`}
				</Box>
				<Box>
					{actionRequired &&
						`They are currently ${acgNameConverter(
							AccessControlGroup[Number(user.accessControlGroup)]
						)}`}{' '}
				</Box>

				{!actionRequired && (
					<FormControl>
						<Select
							input={<Input />}
							labelId="current-acg-label"
							name="CurrentACG"
							defaultValue={0}
							variant="outlined"
							value={user.accessControlGroup}
							onChange={(e) => {
								const group = Number(
									e.target.value
								) as AccessControlGroup;
								const u: UserDTO = {
									...user,
									accessControlGroup: group,
									requestedAccessControlGroup: group,
								} as UserDTO;
								props.update(u);
							}}
							displayEmpty
							color="secondary"
						>
							{controlGroups.map((i) => (
								<MenuItem
									value={i.value}
									key={acgNameConverter(i.value)}
								>
									{acgNameConverter(i.key)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				{actionRequired && (
					<>
						<Button
							onClick={(e) => {
								const u: UserDTO = {
									...user,
									accessControlGroup:
										user.requestedAccessControlGroup,
								} as UserDTO;
								props.update(u);
							}}
						>
							{' '}
							Approve{' '}
						</Button>
						<Button
							onClick={(e) => {
								const u: UserDTO = {
									...user,
									requestedAccessControlGroup:
										user.accessControlGroup,
								} as UserDTO;
								props.update(u);
							}}
						>
							{' '}
							Deny{' '}
						</Button>
					</>
				)}
			</TableCell>
			<TableCell>
				<Button onClick={() => handleShowLog(user.id)}>Change Log</Button>
			</TableCell>
		</TableRow>
	);
};
