import { TextField, Typography } from '@material-ui/core';
import theme from 'config/theme';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { ExportAuditDTO, SearchAuditDTO } from 'services';
import { useStore } from 'store';
import { GetDistanceStringFromNow } from 'utils';
import ExportLogRow from './ExportLogRow';

// interfaces
interface AuditDTO extends ExportAuditDTO, SearchAuditDTO {}
interface ExportControlsProps {
	exportAudits: AuditDTO[] | null;
	search?: boolean;
}
interface GroupedAudit {
	grouping: string;
	audits: AuditDTO[];
}

// components
const filterExports = (exportList: AuditDTO[], filterTerm: string) => {
	return exportList.filter((u) =>
		`${`${JSON.stringify(u)}`.toLowerCase()}`.includes(filterTerm)
	);
};

// utils functions
const groupExports = (auditList: AuditDTO[]): GroupedAudit[] => {
	const result = new Map<string, AuditDTO[]>();
	const now = new Date();

	auditList.forEach((e: any) => {
		const ts = GetDistanceStringFromNow(e.timestamp);
		const arr = result.get(ts);
		if (arr) {
			result.set(ts, [...arr, e]);
		} else {
			result.set(ts, [e]);
		}
	});

	const groups: GroupedAudit[] = [];
	result.forEach((audits, grouping) => groups.push({ grouping, audits }));
	return groups;
};

const Controls = ({ exportAudits, search }: ExportControlsProps) => {
	// hooks
	const { noteStore } = useStore();
	const { protectiveMarkings, managementMarkers } = noteStore;
	const [logFilter, setLogFilter] = useState('');

	const data = useMemo(() => {
		return exportAudits
			? groupExports(filterExports(exportAudits, logFilter.toLowerCase()))
			: null;
	}, [exportAudits, logFilter]);

	if (!exportAudits) {
		return <></>;
	}

	return (
		<>
			<TextField
				label="Filter"
				onChange={(e) => setLogFilter(e.target.value.toLowerCase())}
			></TextField>
			{data &&
				data.map((e) => {
					return (
						<div key={e.grouping}>
							<Typography style={{ marginTop: theme.spacing(2) }}>
								{e.grouping}
							</Typography>
							{e.audits.map((l) => (
								<ExportLogRow
									pm={protectiveMarkings}
									mm={managementMarkers}
									key={l.id}
									log={l}
									search={search}
								/>
							))}
						</div>
					);
				})}
		</>
	);
};
export const AuditViewControls = observer(Controls);
