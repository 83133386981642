import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProps, Form, Formik } from "formik";
import { Button, Typography, Grid, Box } from "@material-ui/core";
import { MuiTextInput, MuiSelect, FieldTitle } from "components";
import MuiPhoneNumber from "components/MuiPhoneInput";
import { AccessControlGroup } from "services/Verinote-api";
import { cleanNumber, EnumToSelect } from "utils";
import moment from "moment-timezone";
import { CreateUserInput } from "../store/userStore";
import { useStore } from "store";
import SignupSMSModal from "./modal/SignupSMSModal";
import Input from "react-phone-number-input";
import { getCountryCallingCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";
// @ts-ignore
import flags from "react-phone-number-input/flags";
import mapsConfigHelper from "services/maps-service/maps-config-helper";
import { useAuth } from "react-oidc-context";

const RenderForm = (props: {
	formikProps: FormikProps<CreateUserInput>;
	forProfile?: boolean;
}) => {
	const auth = useAuth();
	const {
		formikProps: { values, ...formikEvents },
		forProfile,
	} = props;

	const defaultCountry = mapsConfigHelper.getMapsCountry();
	const code = getCountryCallingCode(defaultCountry as any);

	const { otpStore } = useStore();

	const pressSubmit = (event: any) => {
		formikEvents.handleSubmit(event);
		formikEvents.setSubmitting(false);
	};

	const sendOtp = (phone: string) => {
		otpStore.SendSignupOtp(phone);
	};

	const timezones = moment.tz.names().map((t: string) => {
		return {
			key: t,
			value: t,
		};
	});

	const controlGroups = EnumToSelect<AccessControlGroup>(
		AccessControlGroup
	).filter((x) => x.value !== "7");

	const [showSMSModal, setShowSMSModal] = useState(false);

	if (!values) {
		return <></>;
	}

	if (!values.otp) {
		values.otp = "";
	}

	return (
		<div>
			<SignupSMSModal
				open={showSMSModal}
				closeModal={() => setShowSMSModal(false)}
			/>
			<Form>
				<div className="form-createProfile">
					{!forProfile ? (
						<>
							{!values.agency ||
							!values.position ||
							!values.organisationIdentifier ||
							!values.phone ||
							!values.workGroup ? (
								<Box py={2}>
									<Typography variant="body1">
										<span>
											Some of the required fields are
											missing on your Altia ID profile.
											Please make sure they are entered
											first
										</span>{" "}
										<a
											href={`${auth?.settings?.authority}/home`}
										>
											Altia ID
										</a>
									</Typography>
								</Box>
							) : null}
							<MuiTextInput
								id="organisation"
								value={`${values.agency || ""}`}
								default={``}
								formikEvents={{ ...formikEvents }}
								type="text"
								disabled={true}
								title="Organisation/Company Name"
								variant={"outlined" as "outlined"}
							/>
							<MuiTextInput
								id="position"
								value={`${values.position || ""}`}
								default={``}
								formikEvents={{ ...formikEvents }}
								type="text"
								disabled={true}
								title="Your Position"
								variant={"outlined" as "outlined"}
							/>
							<MuiTextInput
								id="workUnit"
								value={`${values.workGroup || ""}`}
								default={``}
								disabled={true}
								formikEvents={{ ...formikEvents }}
								type="text"
								title="Your Department/Work Unit"
								variant={"outlined" as "outlined"}
							/>
							<MuiTextInput
								id="employeeId"
								value={`${values.organisationIdentifier || ""}`}
								default={``}
								disabled={true}
								formikEvents={{ ...formikEvents }}
								type="text"
								title="Your Employee Number/Id"
								variant={"outlined" as "outlined"}
							/>
							<>
								<FieldTitle
									color="textPrimary"
									title="Mobile Number"
								/>

								<Input
									flags={flags}
									placeholder={`+${code} XXXX XXXX`}
									international
									disabled={true}
									value={`${values.phone || ""}`}
									onChange={(e) => {
										formikEvents.handleChange({
											target: {
												value: cleanNumber(e),
												id: "phone",
												name: "phone",
											},
										});
									}}
									inputComponent={MuiPhoneNumber}
								/>
								<Box pt={2}>
									<Button
										color="primary"
										variant="contained"
										size="medium"
										onClick={(e: any) => {
											sendOtp(values.phone);
											setShowSMSModal(true);
										}}
										disabled={values?.phone?.length < 1}
									>
										Send Verify Code
									</Button>
									<MuiTextInput
										id="otp"
										placeHolder="OTP sent to your phone"
										value={`${values.otp}`}
										default={``}
										formikEvents={{ ...formikEvents }}
										type="text"
										title="One Time Password"
										variant={"outlined" as "outlined"}
									/>
								</Box>
							</>
						</>
					) : null}

					<Box pt={4}>
						<MuiSelect
							id="requestedAccessControlGroup"
							canEdit={true}
							editing={true}
							value={`${values.requestedAccessControlGroup}`}
							default={controlGroups[0].value}
							formikEvents={{ ...formikEvents }}
							useAcgConverter={true}
							type="text"
							title="Requested Access Level?"
							items={controlGroups}
						/>
						<MuiSelect
							id="interfaceTimeZone"
							canEdit={true}
							editing={true}
							value={values.interfaceTimeZone || ""}
							default={``}
							formikEvents={{ ...formikEvents }}
							type="text"
							title="Interface TimeZone?"
							items={timezones}
						/>
					</Box>
					<Grid style={{ marginTop: "50px" }} container>
						<Typography align="center">
							<Button
								color="primary"
								variant="contained"
								size="large"
								onClick={(e: any) => {
									pressSubmit(e);
								}}
								disabled={
									!formikEvents.isValid ||
									formikEvents.isSubmitting
								}
							>
								Submit
							</Button>
						</Typography>
					</Grid>
				</div>
			</Form>
		</div>
	);
};

interface NewUserFormProps {
	handleSubmit: (e: CreateUserInput) => void;
	initialValues: any;
	forProfile?: boolean;
}

export const NewUserForm = (props: NewUserFormProps) => {
	const { handleSubmit, initialValues, forProfile } = props;
	return (
		<Formik
			validateOnMount
			initialValues={initialValues}
			enableReinitialize
			validationSchema={Yup.object().shape({})}
			onSubmit={handleSubmit}
			validateOnChange
		>
			{({ ...e }) => (
				<RenderForm formikProps={{ ...e }} forProfile={forProfile} />
			)}
		</Formik>
	);
};
