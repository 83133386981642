import { createMuiTheme } from "@material-ui/core/styles";

export const VerinoteBlue = "#0482f2";
export const VerinoteBlueDark = "#051C2C";
export const VerinoteBlueLight = "#02c3e6";
export const VerinoteGreyFeature = "#ececec";
export const VerinoteGreyToolbar = "#f1f1f1";
export const TRANSITION_TIME = 200;

const gradient = `linear-gradient(to right,${VerinoteBlueDark},${VerinoteBlueDark})`;

const Grey = "#d8deeb";
const White = "#FFF";
//const Black = '#000'
const text = "#111111";
//const placeHolderText = "#888598";
export const inputText = "#444444";
const background = "#f0f2f8";

export const classColors = {
  Grey: "#515151",
  Black: "#00000",
  Pink: "#e5b8b7",
  Red: "#ff0600",
  Blue: "#4f81bd"
};

export const theme = createMuiTheme({
         palette: {
           primary: {
             main: VerinoteBlueDark,
             light: VerinoteBlue
           },
           secondary: {
             main: Grey
           },
           background: {
             default: background
           },
           text: {
             primary: text
           }
         },
         overrides: {
           MuiAppBar: {
             root: {
               backgroundImage: gradient,
               zIndex: 1000
             }
           },
           MuiInputBase: {
             input: {
               color: `${inputText}!important`,
               opacity: "1!important",

             },
           },
           MuiAvatar: {
             root: {
               border: `2px solid ${White}`,
               fontSize: "12px"
             },
             circle: {
               backgroundColor: "transparent"
             }
           },
           MuiButton: {
             root: {
               fontFamily: `"Roboto","Open Sans",Arial,sans-serif`,
               transition: "all .2s ease-out"
             },
             contained: {
               border: "2px solid transparent",
               fontWeight: 600,
               fontSize: "1rem",
               padding: "0.5rem 1.5rem",
               borderRadius: "4px",
               minWidth: "8rem",
               cursor: "pointer",
               textTransform: "none",
               "&:hover": {
                 backgroundImage: "none",
                 backgroundColor: White,
                 border: `2px solid ${VerinoteBlueDark}`
               }
             },
             containedPrimary: {
               backgroundColor: White,
               backgroundImage: gradient,
               color: White,
               "&:hover": {
                 color: VerinoteBlueDark,
                 backgroundColor: White
               }
             },
             containedSecondary: {
               color: VerinoteBlueDark,
               backgroundColor: White,
               "&:hover": {
                 backgroundColor: White
               }
             }
           }
         }
       });

export default theme;
