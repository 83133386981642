import { observable, computed } from "mobx";
import { ConfigurationClient, MobileConfigViewModel } from "services";
import BaseStore from "./baseStore";
import { RootStore } from "./index";
import MapsConfigHelper from "../services/maps-service/maps-config-helper";

export class ConfigStore extends BaseStore {
	private _configurationClient: ConfigurationClient;

	private config: MobileConfigViewModel | undefined;
	private urlStr: string | undefined;

	@observable isAwaitingConfig: boolean = false;

	constructor(root: RootStore) {
		super(root);
		this._configurationClient = new ConfigurationClient(this.API_URL);
	}

	async fetchConfig() {
		this.isAwaitingConfig = true;
		return this.AuthorisedRequest(this._configurationClient, async () => {
			return this._configurationClient
				.get()
				.then((e: MobileConfigViewModel) => {
					this.config = e;
					this.urlStr = e.apiUri;
					console.log("ConfigVM:", e);

					var str = btoa(JSON.stringify(e));
					this.isAwaitingConfig = false;
					return str;
				})
				.catch((e) => {
					this._rootStore.submitExceptionToAppInsight(e);
					return null;
				});
		});
	}

	async fetchApiUri() {
		return this.AuthorisedRequest(this._configurationClient, async () => {
			return this._configurationClient
				.get()
				.then((e: MobileConfigViewModel) => {
					this.config = e;
					this.urlStr = e.apiUri;
					console.log("ApiUri:", this.urlStr);
					return this.urlStr;
				})
				.catch((e) => {
					this._rootStore.submitExceptionToAppInsight(e);
					return null;
				});
		});
	}

	async fetchMapsKey() {
		return this.AuthorisedRequest(this._configurationClient, () =>
			this._configurationClient.getMapsKey()
		);
	}

	@computed get authority(): string | null {
		if (this.config) {
			return this.config.authority;
		}
		return null;
	}
}

export default ConfigStore;
