/* eslint-disable no-console */
import { observable, computed, action, runInAction } from "mobx";
import {
	UserClient,
	CreateUserCommand,
	ICreateUserCommand,
	UserDTO,
	AccessControlGroup,
	UserSearchDTO,
	UserAuditLogDTO,
	UserAuditLogViewModel,
} from "services";
import { RootStore } from "store";
import BaseStore from "./baseStore";
import mapsService from "../services/maps-service";
import mapsConfigHelper from "services/maps-service/maps-config-helper";
import { formatMobileNumber } from "utils";

export interface CreateUserInput {
	interfaceTimeZone: string;
	requestedAccessControlGroup: string;
	phone: string;
	otp: string;
	organisation: string;
	agency?: string;
	position: string;
	workUnit: string;
	workGroup?: string;
	employeeId: string;
	organisationIdentifier?: string;
}

export class UserStore extends BaseStore {
	private _userClient: UserClient;
	@observable currentUser: UserDTO | undefined = undefined;
	@observable userSearchResult: UserSearchDTO[] | undefined = [];
	@observable userLogs: UserAuditLogDTO[] | undefined = [];

	constructor(root: RootStore) {
		super(root);
		this._userClient = new UserClient(this.API_URL);
	}

	@computed get userInitials(): string {
		if (this.currentUser) {
			const user = this.currentUser;
			if (user && user.firstName && user.lastName) {
				const initials = `${user.firstName[0].toLocaleUpperCase()}${user.lastName[0].toLocaleUpperCase()}`;
				return initials;
			}
		}
		return "";
	}

	@computed get canGlobalSearch(): boolean {
		return (
			!!this.currentUser &&
			(this.currentUser.accessControlGroup ===
				AccessControlGroup.Integrity ||
				this.currentUser.accessControlGroup ===
					AccessControlGroup.Intelligence ||
				this.currentUser.accessControlGroup ===
					AccessControlGroup.CEO_Delegate ||
				this.currentUser.accessControlGroup ===
					AccessControlGroup.OrganisationLawyer ||
				this.currentUser.accessControlGroup ===
					AccessControlGroup.Supervisor ||
				this.currentUser.accessControlGroup ===
					AccessControlGroup.Baseline)
		);
	}

	@computed get isAdmin(): boolean {
		const user = this.currentUser;
		return (
			user !== undefined &&
			user.accessControlGroup === AccessControlGroup.Admin
		);
	}

	@computed get isLawyer(): boolean {
		return (
			this.currentUser?.accessControlGroup ===
			AccessControlGroup.OrganisationLawyer
		);
	}

	@computed get userId(): string | undefined {
		return this.currentUser?.id;
	}

	@computed get isIntegrity(): boolean {
		const user = this.currentUser;
		return (
			user !== undefined &&
			user.accessControlGroup === AccessControlGroup.Integrity
		);
	}

	@computed get isValidUser(): boolean {
		return !!this.currentUser?.id && this.isEnabled;
	}

	@computed get userRole(): AccessControlGroup | undefined {
		return this.currentUser?.accessControlGroup;
	}

	@computed get isOrgLawyer(): boolean {
		const user = this.currentUser;
		return (
			user !== undefined &&
			user.accessControlGroup === AccessControlGroup.OrganisationLawyer
		);
	}

	@computed get isCEOorDelegate(): boolean {
		const user = this.currentUser;
		return (
			user !== undefined &&
			user.accessControlGroup === AccessControlGroup.CEO_Delegate
		);
	}

	@computed get isEnabled(): boolean {
		if (this.currentUser) {
			const user = this.currentUser;
			if (user && !user.disabled) {
				return true;
			}
		}
		return false;
	}

	@action showError(msg: string): void {
		this.error = msg;
	}

	@action async getCurrentUser() {
		const {
			authStore,
			userStore: { isValidUser },
		} = this._rootStore;

		if (authStore.bearerToken && !this.loading) {
			this.loading = true;
			this._userClient.setAuthToken(authStore.bearerToken);

			try {
				const user = await this._userClient.getUser();

				if (user.user) {
					if (user.user && (window as any).dataLayer) {
						(window as any).dataLayer.push({
							email: user.user.email,
							phone: user.user.phone,
							company: user.user.agency,
							user_id: user.user.id,
							name:
								user.user.firstName + " " + user.user.lastName,
						});

						(window as any).dataLayer.push({
							event: "user_loaded",
						});

						if ((window as any).Intercom) {
							console.log("move intercome to the left hand");
							(window as any).intercomSettings = {
								...(window as any).intercomSettings,
								alignment: "left",
								horizontal_padding: 20,
								vertical_padding: 20,
							};
						}

						if ((window as any).Intercom) {
							(window as any).Intercom("update");
						}
					}

					this._rootStore.noteStore.StartSession();

					user.user.phone = formatMobileNumber(user.user.phone);
					runInAction(() => (this.currentUser = user.user));

					// get all the app configs required once
					this._rootStore.noteStore.GetClassifications();
					this._rootStore.configStore
						.fetchMapsKey()
						.then((key) =>
							mapsService.initialise(
								key,
								mapsConfigHelper.getMapsCountry()
							)
						);
					// get last 5 declared locations once because this operation can add load on the server. we don't want
					// to call this every time we save note
					this._rootStore.noteStore.GetLastDeclaredLocations();

					if (this.canGlobalSearch) {
						this._rootStore.adminStore.GetWorkUnits();
					}
				}
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.currentUser = {} as UserDTO; // Add a blank user so we can track whether or not we've tried to get
			} finally {
				this.loading = false;
			}
		}
	}

	@action async createNewUser(input: CreateUserInput) {
		this.AuthorisedRequest(this._userClient, async () => {
			const data: ICreateUserCommand = {
				otp: input.otp,
				phone: input.phone,
				accessControlGroup: +input.requestedAccessControlGroup,
				interfaceTimeZone: input.interfaceTimeZone,
				organisation: input.organisation,
				position: input.position,
				workUnit: input.workUnit,
				employeeId: input.employeeId,
			};

			const command = new CreateUserCommand(data);
			this._userClient
				.create(command)
				.then((e) => {
					this.success = "Profile created successfully";
					this.getCurrentUser();
				})
				.catch((e) => {
					this._rootStore.submitExceptionToAppInsight(e);
					this.error =
						"Failed to create profile, make sure all fields are completed";
					console.log(e);
					console.log(data);
				});
		});
	}
	
	async getUserLogs(id: string) : Promise<UserAuditLogViewModel> {
		return this.AuthorisedRequest(this._userClient, async () => {
			return this._userClient.getLogs(id)
				.then((e) => {
					return e;
				})
				.catch((e) => {
					this._rootStore.submitExceptionToAppInsight(e);
					this.error =
						"Something went wrong, please refresh and try again";
					return null;
				});
		});
	}

	@action async searchUsers(query: string) {
		this.AuthorisedRequest(this._userClient, async () => {
			this._userClient
				.usersSearch(query)
				.then((e) => {
					this.userSearchResult = e.users;
				})
				.catch((e) => {
					this._rootStore.submitExceptionToAppInsight(e);
					this.error =
						"Something went wrong, please refresh and try again";
				});
		});
	}
}

export default UserStore;
