import { Fab, Paper, Tab, Tabs } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/styles";
import { NoteList } from "components/NoteList";
import ConfirmDialog from "components/modal/ConfirmDialog";
import classNames from "classnames";
import { theme, VerinoteBlueDark } from "config/theme";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { NoteDTO } from "services";
import { useStore } from "store";
import { EditNoteInput } from "store/noteStore";
import { reduceNotesToAttachments } from "utils";
import { ExportMode } from "../store/exportStore";
import { EditNoteForm } from "./EditNoteForm";
import { FileArray } from "./FileFieldArray";
import { Prompt, useHistory } from "react-router-dom";

const CONFIRM_MESSAGE =
	"Your entry has not been saved. Are you sure you want to cancel?";
const useStyles = makeStyles({
	main: {
		overflow: "hidden",
		display: "flex",
		paddingTop: "4px",
		animation: "fade-in 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
	},
	fab: {
		position: "absolute",
		bottom: theme.spacing(3),
		right: theme.spacing(2),
		transition: "all 0.2s ease",
	},
	attachmentList: {
		"&::-webkit-scrollbar": {
			width: "0.4em",
			transition: "all 0.2 ease",
		},
		"&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
			webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.2)",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: VerinoteBlueDark,
			outline: `1px solid white`,
		},
		width: "100%",
		flexShrink: 1,
		display: "inline-block",
		overflowY: "auto",
		overflowX: "hidden",
		paddingX: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginTop: "4px",
	},
	notesList: {
		overflowY: "hidden",
		width: "35%",
		flexShrink: 1,
		display: "inline-block",
		overflowX: "hidden",
		paddingX: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginTop: "4px",
	},
	editNote: {
		padding: theme.spacing(1),
		width: "65%",
		display: "inline-block",
		overflowX: "hidden",
	},
});

interface NoteViewProps {
	onActivateNote: (index: number) => void;
	onSelectNote: (id: number) => void;
	notes?: NoteDTO[];
	activeNote: NoteDTO | null;
	selected: string[];
	onSaveNote: (values: EditNoteInput) => void;
	loadMore?: () => void;
	newNote: boolean;
	hasMore: boolean;
	noteFormData: EditNoteInput;
	getAutoCompleteOptions: (value: string) => Promise<any[]>;
	canSelect: boolean;
	sessionDate: Moment | null;
	onCreateNote?: () => Promise<EditNoteInput>;
	onboarding?: JSX.Element;
}

const NoteViewComponent = (props: NoteViewProps) => {
	const classes = useStyles(theme);
	const {
		onActivateNote,
		onSelectNote,
		notes,
		activeNote,
		canSelect,
		selected,
		newNote,
		noteFormData,
		onSaveNote,
		getAutoCompleteOptions,
		hasMore,
		loadMore,
	} = props;

	const {
		exportStore,
		noteStore: { editing, setEditing },
		noteStore,
	} = useStore();
	const { exportMode } = exportStore;

	// Hooks
	const [confirmCancel, setConfirmCancel] = useState(false);
	const [newNoteFormData, setNewNoteFormData] = useState<EditNoteInput>(
		{} as EditNoteInput
	);

	useEffect(() => {
		setEditing(false);
	}, [setEditing]);

	// Event handlers
	const cancelNoteCreation = () => {
		setConfirmCancel(false);
		setEditing(false);
		noteStore.cancelNewNoteCreation();
	};

	const addNewNote = () => {
		if (editing) {
			setConfirmCancel(true);
			return;
		}

		if (props.onCreateNote) {
			props.onCreateNote().then((newBlankNote) => {
				setNewNoteFormData(newBlankNote);
				setEditing(true);
			});
		}
	};

	const disableTab =
		notes?.length === 0 || editing || exportStore.isExporting;

	return (
		<>
			<Prompt when={editing} message={CONFIRM_MESSAGE} />
			<ConfirmDialog
				open={confirmCancel}
				title="Are you sure?"
				content={CONFIRM_MESSAGE}
				okClick={cancelNoteCreation}
				cancelClick={() => setConfirmCancel(false)}
			></ConfirmDialog>
			<div className={classes.main}>
				<div
					key={`note-list`}
					className={classNames({
						[classes.notesList]: exportMode === ExportMode.Standard,
						[classes.attachmentList]:
							exportMode !== ExportMode.Standard,
					})}
				>
					<Tabs
						value={exportMode}
						onChange={() => exportStore.ToggleExportMode()}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
						aria-label="full width tabs example"
					>
						<Tab disabled={disableTab} label={"Notes"} />
						<Tab disabled={disableTab} label={"Attachments"} />
					</Tabs>
					{exportMode === ExportMode.Standard && (
						<NoteList
							scrollable={!editing}
							selected={selected}
							hasMore={hasMore}
							onLoadMoreNote={() => loadMore && loadMore()}
							active={activeNote}
							notes={notes}
							onSelectNote={onSelectNote}
							onActivateNote={onActivateNote}
							canActivate={!editing}
							canSelect={canSelect}
						/>
					)}
					{exportMode === ExportMode.Attachment && (
						<Formik
							key={JSON.stringify(notes)}
							onSubmit={(v) => console.log(v)}
							enableReinitialize
							initialValues={{
								files: reduceNotesToAttachments(notes),
							}}
							render={({ values }) => (
								<Form>
									<FileArray
										files={values.files ?? []}
										editing={false}
										canEdit={true}
										// attachments view can't update file
										save={() => {}}
										exporting={canSelect}
										selected={selected}
										select={(e) =>
											exportStore.ToggleNoteSelection(e)
										}
										large
									/>
								</Form>
							)}
						/>
					)}
				</div>

				{exportMode === ExportMode.Standard && (
					<div
						key={
							activeNote
								? `note-view-${activeNote.id}`
								: "empty-noteViewF"
						}
						className={classes.editNote}
					>
						{newNote ||
						(activeNote && notes && notes?.length > 0) ? (
							<Paper>
								<EditNoteForm
									editing={editing}
									setEditing={setEditing}
									getOptions={getAutoCompleteOptions}
									newNote={newNote}
									initialValues={
										newNote ? newNoteFormData : noteFormData
									}
									handleSubmit={onSaveNote}
								/>
							</Paper>
						) : (
							<>{props.onboarding}</>
						)}
					</div>
				)}
			</div>

			<Fab
				color={editing ? "secondary" : "primary"}
				variant="extended"
				aria-label={"Add"}
				size="small"
				className={classes.fab}
				onClick={addNewNote}
			>
				{editing ? <ClearIcon /> : <AddIcon />}
				{editing ? "Cancel" : "Add Note"}
			</Fab>
		</>
	);
};

export const NoteView = observer(NoteViewComponent);
