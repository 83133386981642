/* eslint-disable no-nested-ternary */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { Select, MenuItem, Input } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import { FieldTitle, InputProps } from 'components';
import { acgNameConverter } from '../utils/index';

export interface SelectItem {
	key: string;
	value: string;
}

interface SelectInputProps extends InputProps<string> {
	items: SelectItem[];
	editing?: boolean;
	canEdit?: boolean;
	otherItems?: string[];
	useAcgConverter?: boolean;
}

export const MuiSelect = (props: SelectInputProps): JSX.Element => {
	const selectItems = props.items;
	const useAcgConverter = props.useAcgConverter;

	const {
		id,
		value,
		title,
		formikEvents: { handleChange, handleBlur },
		canEdit,
		editing,
	} = props;
	const disabled = !editing || !canEdit;
	return (
		<>
			<InputLabel htmlFor={id}>
				<FieldTitle
					color={disabled ? 'textPrimary' : 'primary'}
					title={title}
				/>
			</InputLabel>
			<Select
				disabled={disabled}
				fullWidth
				variant="outlined"
				value={value}
				onChange={(e) => handleChange(e)}
				onBlur={(e) => handleBlur(e)}
				displayEmpty
				name={id}
				color="secondary"
				input={<Input id={`outlined-input-${id}`} />}
			>
				{selectItems.map((i) => (
					<MenuItem value={i.value} key={i.value}>
						{useAcgConverter ? acgNameConverter(i.key) : i.key}
					</MenuItem>
				))}
			</Select>
			<ErrorMessage
				name={id}
				render={(error) => (
					<Typography variant="subtitle2">{error}</Typography>
				)}
			/>
		</>
	);
};
