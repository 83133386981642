import { Container, Typography } from '@material-ui/core';
import { WelcomeLogo } from 'assets/logos';
import { NoteView } from 'components';
import { homeStyles } from 'config/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from 'store';
import { EditNoteInput } from '../../store/noteStore';
import DownloadFileModal from "../../components/DownloadFileModal";

const Home = () => {
	const { noteStore, exportStore } = useStore();
	const { selectedNotes, isExporting } = exportStore;
	const { activeNote, newNote } = noteStore;

	const handleActivateNote = (index: number) => {
		if (noteStore.activeIndex !== index) {
			noteStore.SetActiveIndex(index);
		}
	};

	const handleSelectNote = (id: number | undefined) => {
		if (id) {
			exportStore.ToggleNoteSelection(`${id}`);
		}
	};
	const handleCreateNote = async (): Promise<EditNoteInput> => {
		return await noteStore.CreateNote();
	}
	const classes = homeStyles();

	const Onboarding = () => {
		return (
			<>
				<Container className={classes.welcome}>
					<Typography variant="h5" align="center">
						Welcome To
					</Typography>
					<Typography variant="h1" align="center">
						<img src="/logoblack.png" width="300px"></img>
					</Typography>
					<Typography variant="body1" align="center">
						Create a new note by tapping the + button
					</Typography>
					<Typography variant="body1" align="center">
						Or select a date to view existing notes
					</Typography>
				</Container>
			</>
		);
	};

	return (
		<>
			<NoteView
				onCreateNote={handleCreateNote}
				sessionDate={noteStore.sessionDate}
				selected={selectedNotes}
				hasMore={false} // home page showing the list of notes in a day does not need pagination
				activeNote={noteStore.activeNote}
				notes={noteStore.notes}
				onSelectNote={handleSelectNote}
				onActivateNote={handleActivateNote}
				onSaveNote={(v) => noteStore.SaveNote(v)}
				noteFormData={noteStore.PopulateNoteForm(activeNote)}
				newNote={newNote}
				getAutoCompleteOptions={(v) => noteStore.GetAddress(v)}
				canSelect={isExporting}
				onboarding={<Onboarding />}
			/>
			<DownloadFileModal/>
		</>
	);
};

export default observer(Home);
