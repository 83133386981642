import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export interface ConfirmDialogProps {
	open: boolean;
	title: string;
	content: string;
	okClick: () => void;
	cancelClick: () => void;
	cancelLabel?: string;
	okLabel?: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	open,
	title,
	content,
	okClick,
	cancelClick,
	okLabel,
	cancelLabel,
}) => {
	return (
		<div style={{zIndex: 100}}>
			<Dialog
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>{content}</DialogContent>
				<DialogActions>
					<Button onClick={cancelClick} color="primary">
						{cancelLabel || "Cancel"}
					</Button>
					<Button onClick={okClick} color="primary">
						{okLabel || "Ok"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ConfirmDialog;
