import {
	Avatar,
	Badge,
	Box,
	Button,
	CssBaseline,
	IconButton,
	Snackbar,
	SnackbarContent,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HelpIcon from "@material-ui/icons/HelpOutlined";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import PhonelinkIcon from "@material-ui/icons/Phonelink";
import SettingsIcon from "@material-ui/icons/Settings";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import InfoIcon from '@material-ui/icons/Info';
import { LoadingAnimation } from "assets/animation";
import { AppBarLogo } from "assets/logos";
import classNames from "classnames";
import {
	canNavigateToRoute,
	getRouteByPath,
	hasRouteGuard,
	HomeRoute,
	RouteConfig,
} from "config/routes";
import { USER_IDLE, VERSION } from "config/SETTINGS";
import theme, {
	TRANSITION_TIME,
	VerinoteGreyFeature,
	VerinoteGreyToolbar,
} from "config/theme";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import { useStore } from "store";
import { RBAC_GLOBAL_SEARCH_NAMING, TOOLBAR_HEIGHT } from "utils";
import AppToolbar from "./AppToolbar";
import Dropdown from "./Dropdown";
import ExportModal from "./modal/ExportModal";
import OTPModal from "./modal/OtpModal";
import QRModal from "./modal/QRModal";
import SwitchTenantModal from "./modal/SwitchTenantModal";
import GlobalSearchModal from "./modal/GlobalSearchModal";
import ElectronService from "../services/electron.service";
import { useIdleTimer } from "react-idle-timer";
import { SearchControl } from "./SearchControl";
import { checkPermission } from "store/rbac";
import { useAuth } from "react-oidc-context";
import AboutModal from "./modal/AboutModal";
import PreviewOtpModal from "./modal/PreviewOtpModal";
import TranscribeModal from "./modal/TranscribeModal";
var debounce = require("debounce");

// stylesheets
const useStyles = makeStyles({
	appRoot: {
		display: "flex",
		flexDirection: "column",
		height: "100vh",
	},
	root: {
		overflowX: "hidden",
		overflowY: "auto",
		overflowWrap: "anywhere",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		flexBasis: "50vh",
	},
	dropdown: {
		width: 150,
		padding: theme.spacing(3),
	},
	actions: {
		marginLeft: "auto",
		display: "flex",
	},
	appBar: {
		position: "sticky",
		top: 0,
		animation:
			"slide-in-top 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
	},
	topToolbar: {
		backgroundColor: VerinoteGreyToolbar,
		padding: theme.spacing(1),
		borderBottom: `2px solid ${VerinoteGreyFeature}`,
		animation:
			"slide-in-top 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
		height: `${TOOLBAR_HEIGHT}px`,
	},
	routeTitle: {
		paddingTop: "3px",
	},
	bottomBar: {
		fontSize: "10px",
		position: "fixed",
		bottom: 0,
		height: theme.spacing(2),
		borderTopStyle: "solid",
		borderTopColor: VerinoteGreyFeature,
		borderTopWidth: "1px",
		width: "100vw",
		backgroundColor: VerinoteGreyToolbar,
	},
	appContent: {
		animation: "fade-in 300ms ease-out both",
		transition: "all 0.2s ease",
		flexGrow: 1,
		flexBasis: "50vh",
		overflow: "auto",
	},
	appContentLoading: {
		filter: "Opacity(60%)",
		transition: "all 0.2s ease",
		pointerEvents: "none",
	},
	appContentOut: {
		animation: "fade-out 200ms ease-out both",
	},
});

const AppLayout = ({ children }: any) => {
	const auth = useAuth();

	// Hooks
	const classes = useStyles();
	const history = useHistory();

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (!!params.get("code") && auth.isAuthenticated) {
			console.log("user signed in");
			history.push(`/`);
		}
	}, [auth.isAuthenticated, history]);

	const {
		userStore: {
			isValidUser,
			isAdmin,
			isIntegrity,
			isCEOorDelegate,
			currentUser,
			isEnabled,
			userInitials,
			loading,
			userSearchResult,
			canGlobalSearch,
		},
		userStore,
		authStore,
		noteStore,
		exportStore,
		applicationStore: { CurrentLoading },
		configStore,
		searchStore,
		adminStore: { AccessActionRequiredCount, workunits },
		otpStore: { isAwaitingOTP },
		previewStore,
		transcribeStore
	} = useStore();

	const [currentRoute, setCurrentRoute] = useState<RouteConfig>(HomeRoute);
	const [changingRoute, setChangingRoute] = useState(false);
	const [showQRModal, setShowQRModal] = useState(false);
	const [qrData, setQRData] = useState("");
	const [showVersion, setShowVersion] = useState(false);
	const [showAboutModal, setShowAboutModal] = useState(false);
	const [showSwitchTenantModal, setShowSwitchTenantModal] = useState(false);
	const { isAwaitingExportReason } = exportStore;
	const { isAwaitingPreviewReason } = previewStore;
	const { isOpenTranscriptModal } = transcribeStore;
	const isElectron = ElectronService.isElectron();

	const globalSearchRbacLabel = useMemo<string>(() => {
		const controlGroup = currentUser?.accessControlGroup ?? 0;
		return RBAC_GLOBAL_SEARCH_NAMING[controlGroup];
	}, [currentUser]);

	const userCanDoUserSearch = useMemo<boolean>(
		() =>
			checkPermission(
				currentUser?.accessControlGroup,
				"SearchSpecificUser"
			),
		[currentUser]
	);

	const userCanDoWorkgroupSearch = useMemo<boolean>(
		() =>
			checkPermission(
				currentUser?.accessControlGroup,
				"SearchByWorkgroup"
			),
		[currentUser]
	);

	const onSearchUsers = debounce((q: string) => {
		if ((q || "").length < 3) return;
		userStore.searchUsers(q);
	}, 500);

	const checkRoutePermission = (route: string) => {
		const [cr, errorMsg] = canNavigateToRoute({
			route,
			validUser: isValidUser,
			isAdmin,
			isIntegrity,
			isCEOorDelegate,
		});

		if (errorMsg) {
			userStore.showError(errorMsg);
			history.push(cr.path);
			return null;
		}
		return cr;
	};

	useEffect(() => {
		// this happens AFTER route change
		const currentRoute = checkRoutePermission(history.location.pathname);
		if (!currentRoute) return;

		// update current route
		setCurrentRoute(currentRoute as any);

		// reset state
		exportStore.CancelExport();
		noteStore.cancelNewNoteCreation();
	}, [history.location]);

	const idleTimedOut = () => {
		!isElectron && authStore.logOut();
	};

	// sign user out after 15 minutes of idle time if not Electron app
	useIdleTimer({
		timeout: 1000 * 60 * USER_IDLE,
		onIdle: idleTimedOut,
		debounce: 500,
	});

	useEffect(() => {
		if (hasRouteGuard(window.location.pathname)) {
			if (currentUser) {
				navigate(window.location.pathname);
			}
		} else {
			navigate(window.location.pathname);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValidUser, isAdmin, isIntegrity, isCEOorDelegate, currentUser]);

	// events handlers
	const navigate = (route: string) => {
		if (currentRoute.path !== route) {
			const cr = checkRoutePermission(route);
			if (!cr) return;

			// navigate
			window.setTimeout(() => {
				history.push(cr.path);
				setChangingRoute(false);
			}, TRANSITION_TIME);
		}
	};

	const goHome = () => {
		noteStore.StartSession();
		exportStore.CancelExport();
		searchStore.StopMonitoring();
		navigate("/");
	};

	async function openQRModal() {
		var str = await configStore.fetchApiUri();
		setQRData(str);
		setShowQRModal(true);
	}

	const switchTenant = (event: boolean) => {
		setShowSwitchTenantModal(false);
		if (isElectron && event) {
			ElectronService.switchTenant();
			window.close();
		}
	};

	const openSwitchTenantModal = () => {
		setShowSwitchTenantModal(true);
	};

	return (
		<div
			className={`${classes.appRoot} ${classNames({
				"appMain-exit": authStore.signingOut,
			})}`}
		>
			{isAwaitingOTP && <OTPModal />}
			{isAwaitingExportReason && <ExportModal />}
			{isAwaitingPreviewReason && <PreviewOtpModal />}
			{isOpenTranscriptModal && <TranscribeModal />}

			<GlobalSearchModal />

			<QRModal
				open={showQRModal}
				data={qrData}
				closeModal={() => setShowQRModal(false)}
			/>
			
			<AboutModal
				open={showAboutModal}
				closeModal={() => setShowAboutModal(false)}
			/>

			<SwitchTenantModal
				open={showSwitchTenantModal}
				onClose={(event: boolean) => switchTenant(event)}
			/>
			<AppBar className={classes.appBar} position="relative">
				<Toolbar>
					{/* logo */}
					<Box width="180px" display="flex">
						<div onClick={goHome}>
							<img src="/logowhite.png" width="180px"></img>
						</div>
					</Box>

					{/* search bar */}
					{isValidUser && (
						<Box
							flexGrow={1}
							display="flex"
							justifyContent="center"
						>
							<SearchControl
								protectiveMarkings={
									noteStore.protectiveMarkings
								}
								managementMarkers={noteStore.managementMarkers}
								isMonitoring={searchStore.isMonitoring}
								disabled={exportStore.isExporting}
								search={() =>
									searchStore.isRequestingGlobalSearch
										? searchStore.SearchNotesGlobally()
										: searchStore.SearchNotes()
								}
								userSearchResult={userSearchResult}
								searchInput={searchStore.searchInput}
								searchUsers={onSearchUsers}
								setSearchInput={(i) =>
									searchStore.SetSearchInput(i)
								}
								workunits={workunits}
								getLocations={(v) => noteStore.GetAddress(v)}
								canGlobalSearch={canGlobalSearch}
								canSearchByWorkgroup={userCanDoWorkgroupSearch}
								globalSearchLabel={globalSearchRbacLabel}
								canDoUserSearch={userCanDoUserSearch}
							/>
						</Box>
					)}
					<Box width="180px" display="flex" justifyContent="flex-end">
						{isValidUser && (
							<ValidUserButtons
								navigate={navigate}
								goHome={goHome}
								showAdmin={
									isAdmin || isIntegrity || isCEOorDelegate
								}
								badge={AccessActionRequiredCount}
							/>
						)}
						<Dropdown
							disabled={false}
							closeOnClick
							id="profile-dropdown"
							buttonIcon={
								<IconButton>
									{userInitials && (
										<Avatar className="avatar">
											{userInitials}
										</Avatar>
									)}
								</IconButton>
							}
						>
							<div className={classes.dropdown}>
								<Box display="flex" flexDirection="column" alignItems="flex-start">
									{isValidUser && (
										<>
											<Button
												onClick={() =>
													navigate("/profile")
												}
												startIcon={<PersonIcon />}
											>
												Profile
											</Button>
											<Button
												onClick={() => openQRModal()}
												startIcon={<PhonelinkIcon />}
											>
												QR Code
											</Button>
										</>
									)}
									<Button
										onClick={() => authStore.logOut()}
										startIcon={<ExitToAppIcon />}
									>
										Log Out
									</Button>

									<Button
										href="https://portal.altia-abm.com/hc/en-gb"
										target="_blank"
										startIcon={<HelpIcon />}
									>
										Support
									</Button>
									<Button
										onClick={() => setShowAboutModal(true)}
										startIcon={<InfoIcon />}
									>
										About
									</Button>
									{isElectron && (
										<Button
											onClick={() =>
												openSwitchTenantModal()
											}
											startIcon={<SwapHorizIcon />}
										>
											Switch Tenant
										</Button>
									)}
								</Box>
							</div>
						</Dropdown>
					</Box>
				</Toolbar>
			</AppBar>
			<CssBaseline />
			{authStore.loading || loading ? (
				<LoadingAnimation />
			) : (
				<div className={classes.root}>
					{isEnabled && currentUser && currentUser.id && (
						<AppToolbar
							changeRoute={changingRoute}
							currentRoute={currentRoute?.title}
						/>
					)}
					<div
						className={classNames({
							[classes.appContentOut]: changingRoute,
							[classes.appContent]: !changingRoute,
							[classes.appContentLoading]: CurrentLoading,
						})}
					>
						{children}
					</div>
				</div>
			)}

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				open={showVersion}
				onClose={() => setShowVersion(false)}
				autoHideDuration={5000}
			>
				<SnackbarContent message={`Capture Ver. 0.1.${VERSION}`} />
			</Snackbar>
		</div>
	);
};

export default withRouter(observer(AppLayout));

function ValidUserButtons({ navigate, showAdmin, badge, goHome }: any) {
	return (
		<>
			<IconButton color="inherit" onClick={goHome}>
				<HomeIcon />
			</IconButton>
			{showAdmin && (
				<IconButton color="inherit" onClick={() => navigate("/admin")}>
					{badge > 0 ? (
						<Badge color="error" badgeContent={badge}>
							<SettingsIcon />
						</Badge>
					) : (
						<SettingsIcon />
					)}
				</IconButton>
			)}
		</>
	);
}
