import { MobileConfigViewModel } from './Verinote-api';
import { MsalConfigModel } from '../models/msal-auth.model';

const MSAL_CONFIG_KEY = 'msal.config';

const b2cDefaultSetting = (mobileConfig: MobileConfigViewModel): Partial<MsalConfigModel> => {
	const tenantInstance = mobileConfig.tenantName && `https://${mobileConfig.tenantName}.b2clogin.com`;

	return {
		tenantInstance,
		scopes: [`https://${mobileConfig.tenantId}/api/user_impersonation`],
		authority: 'foo',
		// authority: {
		// 	...tenantInstance && mobileConfig.tenantId && mobileConfig.signInPolicy && {
		// 		signInPolicyAuthority: `${tenantInstance}/${mobileConfig.tenantId}/${mobileConfig.signInPolicy}`,
		// 	},
		// 	...tenantInstance && mobileConfig.tenantId && mobileConfig.passwordResetPolicy && {
		// 		resetPasswordAuthority: `${tenantInstance}/${mobileConfig.tenantId}/oauth2/v2.0/authorize?p=${mobileConfig.passwordResetPolicy}&client_id=${mobileConfig.appId}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`
		// 	},
		// },
		validateAuthority: false,
	};
};

const aadDefaultSetting = (mobileConfig: MobileConfigViewModel): Partial<MsalConfigModel> => {
	const tenantInstance = 'https://login.microsoft.com';

	return {
		tenantInstance,
		scopes: [`https://${mobileConfig.tenantName}.onmicrosoft.com/api/user_impersonation`],
		authority: `https://login.microsoftonline.com/${mobileConfig.tenantName}.onmicrosoft.com`,
		// authority: {
		// 	signInPolicyAuthority: 'https://login.microsoftonline.com/verinotedevid.onmicrosoft.com',
		// 	...tenantInstance && mobileConfig.tenantId && mobileConfig.passwordResetPolicy && {
		// 		resetPasswordAuthority: `${tenantInstance}/${mobileConfig.tenantId}/oauth2/v2.0/authorize?p=${mobileConfig.passwordResetPolicy}&client_id=${mobileConfig.appId}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`
		// 	},
		// },
		validateAuthority: false,
	};
};

class MsalConfigHelper {
	parseMobileConfigViewModel(config: MobileConfigViewModel): MsalConfigModel {
		return {
			appId: config.scope as string, // TODO: this won't work with B2C anymore
			redirectUri: window.location.origin,
			...config.idp === 'B2C' && b2cDefaultSetting(config),
			...config.idp === 'AAD' && aadDefaultSetting(config),
		} as MsalConfigModel;
	}

	saveMsalConfig(config: MsalConfigModel) {
		localStorage.setItem(MSAL_CONFIG_KEY, JSON.stringify(config));
	}

	getSavedMsalConfig(): MsalConfigModel {
		const savedConfig = localStorage.getItem(MSAL_CONFIG_KEY);

		if (!savedConfig) {
			throw new Error('MSAL config is missing');
		}

		return JSON.parse(savedConfig) as MsalConfigModel;
	}
}

export default new MsalConfigHelper();
