import React, { useState, useEffect } from 'react';
import { Badge, TextField } from '@material-ui/core';

import { theme } from 'config/theme';
import { UpdateUserForm } from './UpdateUserForm';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {AccessControlGroup, UserAuditLogDTO, UserDTO} from '../../../services';
import { EnumToSelect } from '../../../utils';
import {TableUserLog} from "./TableUserLog";
import {useStore} from "../../../store";

const controlGroups = EnumToSelect(AccessControlGroup);

interface UserControlsProps {
	AccessActionRequiredCount: number;
	users: UserDTO[];
	updateUser: (u: UserDTO) => void;
}

export const UserControls = (props: UserControlsProps) => {
	const { AccessActionRequiredCount, users } = props;
	const [filter, setFilter] = useState('');
	const [filteredUsers, setFilteredUsers] = useState<UserDTO[]>([]);
	const { userStore } = useStore();
	const [isOpen, setIsOpen] = useState(false);
	const [userLogs, setUserLogs] = useState<UserAuditLogDTO[]>([]);

	const closeModal = () => {
		setIsOpen(false);
	}

	const handleShowLog = async (id) => {
		const rs = await userStore.getUserLogs(id);
		setUserLogs(rs.userLogs);
		setIsOpen(true);
	}

	const createUserString = (user: UserDTO) => {
		const requested =
			AccessControlGroup[Number(user.requestedAccessControlGroup)];
		const current = AccessControlGroup[Number(user.accessControlGroup)];
		const str = `
            ${requested} 
            ${current} 
            ${user.agency} 
            ${user.firstName} 
            ${user.lastName} 
            ${user.organisationIdentifier}
            ${user.phone}
            ${user.position}
            ${user.email}
            ${user.workGroup}`.toLowerCase();
		return str;
	};

	useEffect(() => {
		setFilteredUsers(
			users
				.filter((u) => createUserString(u).includes(filter)) //TODO: Use a datatable
		);
	}, [users, filter]);

	return (
		<>
			<TextField
				label="Filter"
				onChange={(e) => setFilter(e.target.value.toLowerCase())}
			></TextField>
			<Table aria-label="user table">
				<TableHead>
					<TableRow>
						<TableCell align="justify">Enabled</TableCell>
						<TableCell align="justify">First Name</TableCell>
						<TableCell align="justify">Lastname</TableCell>
						<TableCell align="justify">Email</TableCell>
						<TableCell align="justify">WorkGroup</TableCell>
						<TableCell align="justify">
							<>
								{AccessActionRequiredCount > 0 ? (
									<Badge
										style={{
											paddingRight: theme.spacing(1),
										}}
										badgeContent={AccessActionRequiredCount}
										color="error"
									>
										Access Control Group
									</Badge>
								) : (
									'Access Control Group'
								)}
							</>
						</TableCell>
						<TableCell align="justify"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredUsers &&
						filteredUsers.map((u) => (
							<UpdateUserForm
								key={u.id}
								controlGroups={controlGroups}
								user={u}
								handleShowLog={handleShowLog}
								update={(user) => props.updateUser(user)}
							/>
						))}
				</TableBody>
			</Table>
			<TableUserLog data={userLogs} isOpen={isOpen} closeModal={closeModal} />
		</>
	);
};
